import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CardActions, Chip } from "@material-ui/core";
import { Card, CardContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { uiIsSceneEditorOpen } from "../../utils/redux/features/ui";
import { sceneEditingGet } from "../../utils/redux/features/scenes";

export default ({ breadcrumbs, onClose }) => {
  console.log(breadcrumbs);

  const scene = useSelector((state) => state.scenes.scene);
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth={false} open={true} onClose={onClose}>
      <DialogTitle>
        <div className="d-flex w-100 justify-content-between">
          {window.ac.scene ? (
            <Typography variant="h6">{`Breadcrumbs for ${window.ac.scene.title}`}</Typography>
          ) : (
            <Typography variant="h6">
              Load a scene to review the breadcrumbs here.
            </Typography>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        {breadcrumbs.map(({ bgLevel, glucose, state, action }, key) => {
          return (
            <div
              key={`breadcrumbsKey-${key}`}
              style={{
                maxHeight: 600,
                overflowY: "auto",
              }}
            >
              <Card className="m-2">
                <CardContent>
                  <Typography variant="h5">{`${state} - ${action}`}</Typography>
                  <Chip
                    variant="outline"
                    className="mr-2 mt-2"
                    style={{
                      background: (() => {
                        return "#d2e5ff";
                      })(),
                    }}
                    key={key}
                    label={`glucose: ${glucose}`}
                  />
                  <Chip
                    variant="outline"
                    className="mr-2 mt-2"
                    style={{
                      background: (() => {
                        return "#9ee2ff";
                      })(),
                    }}
                    key={key}
                    label={`bg-level: ${bgLevel}`}
                  />

                  <CardActions>
                    <Box className="mt-4 w-100 align-items-center justify-content-end d-flex">
                      <Button
                        variant="text"
                        // className="mr-4 r-0"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          // Open scene editor to the exact place where you need to edit.
                          dispatch(sceneEditingGet(scene.id));
                          setTimeout(() => {
                            dispatch(uiIsSceneEditorOpen(true));
                          }, 1000);
                        }}
                      >
                        Open In Editor
                      </Button>
                      {/* <AlertDialog
                        title="Remove User Action?"
                        content="Selecting Yes will remove this user action from the glucose state"
                        isOpen={!!isRemoveUserActionOpen}
                        onResponse={(yes) => {
                          if (yes) {
                            dispatch(
                              sceneDeleteUserAction(isRemoveUserActionOpen)
                            );
                          }
                          setIsRemoveUserActionOpen(false);
                        }}
                      /> */}
                    </Box>
                  </CardActions>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};
