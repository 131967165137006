import React from 'react';
import moment from 'moment';
// TODO - Remove App.css import 
//import './../../App.css';
import './Pump.css';
import AppController from './../../utils/AppController';
import EventManager from './../../utils/EventManager';

class PumpDate12HourSetup extends React.Component{
    state={
        state:0,
        date:new Date()    
    };
    componentDidMount=()=>{
        EventManager.listen("#button.pump.act",this.onAct);
        EventManager.listen("#button.pump.esc",this.onEcs);
        EventManager.listen("#button.pump.up",this.onUp);
        EventManager.listen("#button.pump.down",this.onDown);
        AppController.pumpState = AppController.pumpState_12HourSetup; 
        this.dateBeforeSave = this.state.date;
    }
    componentWillUnmount=()=>{
        EventManager.stopListening("#button.pump.act",this.onAct);
        EventManager.stopListening("#button.pump.esc",this.onEcs);
        EventManager.stopListening("#button.pump.up",this.onUp);
        EventManager.stopListening("#button.pump.down",this.onDown);
    }
    onAct=()=>{
        if(this.state.state===6){
            AppController.app.setState({date:this.state.date});
            this.props.onDone(); 
            return;
        }
        this.setState((pState)=>{
            return {
                state:pState.state+1
            }
        });
    }
    onEsc=()=>{
        this.props.onDone();
    }
    onUp=()=>{
        let date = new Date(this.state.date);
        if(this.state.state === 1){
            let hr = this.state.date.getHours()+1;
            date.setHours(hr);
        }else if(this.state.state === 2){
            let min = this.state.date.getMinutes()+1;
            date.setMinutes(min);
        }else if(this.state.state===3){
            let year = this.state.date.getFullYear()+1;
            date.setFullYear(year);
        }else if(this.state.state===4){
            let month = this.state.date.getMonth()+1;
            date.setMonth(month);
        }else if(this.state.state===5){
            let day = this.state.date.getDate()+1;
            date.setDate(day);
        }else if(this.state.state===6){
        }
        this.setState(()=>{
            return {
                date:date
            }
        });
    }
    onDown=()=>{
        let date = new Date(this.state.date);
        if(this.state.state === 1){
            let hr = this.state.date.getHours()-1;
            date.setHours(hr);
        }else if(this.state.state === 2){
            let min = this.state.date.getMinutes()-1;
            date.setMinutes(min);
        }else if(this.state.state===3){
            let year = this.state.date.getFullYear()-1;
            date.setFullYear(year);
        }else if(this.state.state===4){
            let month = this.state.date.getMonth()-1;
            date.setMonth(month);
        }else if(this.state.state===5){
            let day = this.state.date.getDate()-1;
            date.setDate(day);
        }else if(this.state.state===6){
        }
        this.setState(()=>{
            return {
                date:date
            }
        });
    }
    renderView=()=>{
        if(this.state.state===0){
            return (<React.Fragment>
                <div>TIME DATE SET</div>
                <div>{moment(this.state.date).format("h:mmA")}</div>
                <div>{moment(this.state.date).format("DDMMMYYYY")}</div>
                <div>Press ACT to Change</div>
            </React.Fragment>)
        }else if(this.state.state===1){
            return (<React.Fragment>
                <div>SET HOUR</div>
                <div>
                    <span className="blink">{moment(this.state.date).format("h")}</span>
                    <span>{moment(this.state.date).format(":mmA")}</span>
                </div>
            </React.Fragment>)
        }else if(this.state.state===2){
            return (<React.Fragment>
                <div>SET MINUTES</div>
                <div>
                    <span>{moment(this.state.date).format("h")}</span>
                    <span className="blink">{moment(this.state.date).format(":mm")}</span>
                    <span>{moment(this.state.date).format("A")}</span>
                </div>
            </React.Fragment>)
        }else if(this.state.state===3){
            return (<React.Fragment>
                <div>SET YEAR</div>
                <div>
                    <span className="blink">{moment(this.state.date).format("YYYY")}</span>
                </div>
            </React.Fragment>)
        }else if(this.state.state===4){
            return (<React.Fragment>
                <div>SET MONTH</div>
                <div>
                    <span className="blink">{moment(this.state.date).format("MM")}</span>
                </div>
            </React.Fragment>)
        }else if(this.state.state===5){
            return (<React.Fragment>
                <div>SET DAY</div>
                <div>
                    <span className="blink">{moment(this.state.date).format("DD")}</span>
                </div>
            </React.Fragment>)
        }else if(this.state.state===6){
            return (<React.Fragment>
                <div>TIME/DATE CORRECT?</div>
                <div>
                    <span>{moment(this.state.date).format("hh:mmA DMMMYYYY")}</span>
                    <div>No</div>
                    <div className="pump-menu-item-selected-12hoursetup">Yes</div>
                </div>
            </React.Fragment>)
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        );
    }
}
export default PumpDate12HourSetup;
