import React from 'react';
import './UtilView.css';
import ls from 'local-storage';
import em from './../utils/EventManager';
import ac from './../utils/AppController';
class UtilView extends React.Component{
    resetLocalStore=()=>{
        ls.clear();
        ac.createDataStore();
        // effectivly creates user with first scene unlocked
        em.fire("#reload.data");
    }
    unlockAllScenarios=()=>{
        let user = ac.getUser();
        let scenarios = ac.scenarios;
        for(let i = 0;i < scenarios.length;i++){
            let scene = scenarios[i];
            if(!user.scenes.hasOwnProperty(scene['Scene Name'])){
                ac.unlockScene(scene['Scene Name']);
            }
        }
        ac.app.forceUpdate();
    }
    renderSceneDetails=()=>{
        let scene = ac.scene;
        return (
            <div className="util-view-scene-details"> 
                <br/>
                <div>name - {scene.data["Scene Name"]}</div>
                <div>title - {scene.data["Scene Title"]}</div>
                <div>type - {scene.data["Scene Type"]}</div>
                <div>starting bg: {scene.data["Scene Starting BG"]}</div>
                <div>current bg: {ac.app.state.glucose}</div>
                <div>state - {scene.sceneState}</div>
                <hr/> 
                <h4>Processing Chunk</h4>
                <pre>{JSON.stringify(scene.chunk,null,2)}</pre>
            </div>
        )
    }
    render(){
        return (<div className="util-view">
            <h1>MiSugartime Utils</h1> 
            <div onClick={this.resetLocalStore} className="util-button"><div>Reset Local Data</div></div>
            <div onClick={this.unlockAllScenarios} className="util-button"><div>Unlock All Scenarios</div></div>
            <div onClick={ac.resetApp} className="util-button"><div>Reset App</div></div>
            <div style={{clear:'both'}}/>
            <br/>
            <hr/>
            <h4>Global State Object </h4>
            <pre>{JSON.stringify(ac.app.state,null,2)}</pre>
            <hr/>
            {ac.scene && this.renderSceneDetails()}
        </div>) 
    }
}
export default UtilView;

