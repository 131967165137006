import ls from "local-storage";
import _ from "lodash";
import moment from "moment";
import EventManager from "./EventManager";
import SceneController from "./SceneController";
import { getSumerianFrame } from "./stubs";
import { store } from "../utils/redux/configureStore";
import { sceneGet, sceneNullOut, scenesGet } from "./redux/features/scenes";
import { SCENE_TYPES } from "./constants";
import { toast } from "react-toastify";
import { TweenLite } from "gsap";
import AudioController from "./AudioController";
import { Storage } from "aws-amplify";
import { saveUserSession } from "./redux/features/userSlice";
import { v4 } from "uuid";
//import Track from './../audio/bensound-anewbeginning.mp3';

let AppController = {
  //track:new Audio(Track),
  lastScene: null,
  scenarios: [],
  settingsModel: {
    pump: {},
  },
  settings: {},
  breadcrumbs: [],
  pumpState_nav: 0,
  pumpState_12HourSetup: 1,
  pumpState_bolus: 2,
  pumpState: 0,
  quickTest: false,
  getSumerianFrame: getSumerianFrame,
  user: null,
  sessionId: null,
  init() {
    if (process.env.REACT_APP_QUICK_TEST) {
      AppController.quickTest = true;
    }
    window.ls = ls;
    this.settings = Object.assign({}, this.settings, this.settingsModel);
    this.waitingForInput = false;
    this.sceneEvents = [];
    this.scene = null;
    this.lastScene = null;
    this.isSpeaking = false;
    this.scene = null; //new SceneController();// for testing
    this.welcome = null;
    this.playingWelcome = true;
    this.data = null;
    this.speakQueue = [];

    let self = this;
    window.addEventListener("message", (e) => {
      if (
        e.data &&
        e.data.type &&
        e.data.type === "a1control" &&
        e.data.details
      ) {
        self.onSumerianMessage(e.data.details);
      }
    });

    EventManager.listen("#load-scene", _.bind(this.loadScene, this));
    EventManager.listen("#scenario.finished", this.onScenarioFinished);
    EventManager.listen("#state.date", this.onDateChanged);
    EventManager.listen("#state.glucose", this.onGlucoseChange);
    EventManager.listen("#state.tempBasalDuration", this.onTempBasalChange);
    EventManager.listen("#sumerian.ready", () => {
      let playedOnce = ls("played-once");
      ls("played-once", true);

      // Get / Load id of introduction scene.
      const scenes = store.getState().scenes.scenes;

      // check url params for startingScene
      const urlParams = new URLSearchParams(window.location.search);
      const startingScene = urlParams.get("startingScene");

      if (startingScene && (startingScene.match(/-/g) || []).length === 4) {
        console.log("valid starting scene", startingScene);
        store.dispatch(sceneGet(startingScene));
      } else {
        const scene = scenes.find(
          (n) =>
            n.info["Scene Type"] ===
            (playedOnce
              ? SCENE_TYPES.INTRODUCTION_RETURN
              : SCENE_TYPES.INTRODUCTION)
        );
        store.dispatch(sceneGet(scene.id));
      }

      // wait for store scene to become not null
      const timer = setInterval(() => {
        if (store.getState().scenes.scene) {
          clearInterval(timer);
          EventManager.fire("#load-scene", store.getState().scenes.scene.info);
        }
      }, 100);
    });
  },
  checkGlucoseAlerts() {
    // If glucose is higher or lower than the set threshold in the cgm, use a toast alert
    // Get high / low threshold from cgm state
    const {
      alertLowGlucose,
      alertLowGlucoseOn,
      alertHighGlucose,
      alertHighGlucoseOn,
    } = store.getState().cgm;

    const g = Math.round(AppController.app.state.glucose);
    if (alertLowGlucoseOn && g <= alertLowGlucose) {
      AudioController.play(AudioController.alert);
      toast.warn(`LOW GLUCOSE ALERT`);
      AppController.animateCGMSideBarButton();
    } else if (alertHighGlucoseOn && g >= alertHighGlucose) {
      AudioController.play(AudioController.alert);
      toast.warn(`HIGH GLUCOSE ALERT`);
      AppController.animateCGMSideBarButton();
    }
  },
  animateCGMSideBarButton() {
    const el = document.getElementById("cgm-button");
    TweenLite.to(el, 0.3, {
      scale: 1.3,
      yoyo: true,
      repeat: 30,
    });
  },
  get isAdmin() {
    return this.profile.roles.indexOf("admin") > -1;
  },
  get isEditor() {
    return this.profile.roles.indexOf("editor") > -1;
  },
  get profile() {
    let profile = _.get(this, "user.attributes.profile", {
      roles: [],
    });
    _.isString(profile) && (profile = JSON.parse(profile));
    profile.roles = profile.roles || [];
    return profile;
  },
  // sets scene pump controller to start a basal delivery
  onTempBasalChange(e) {
    if (AppController.scene) {
      AppController.scene.pump.setBasalPercent(
        AppController.app.state.tempBasalPcent
      );
      AppController.scene.pump.setBasalDuration(
        AppController.app.state.tempBasalPcent / 60
      ); // <-- in hours
      AppController.scene.pump.activateTempBasal();
    }
  },
  onGlucoseChange(e) {
    let glucose = e.detail.data;
    let data = AppController.app.state.sensorData;
    let date = AppController.app.state.date;
    let formatted = moment(date).format("h:mm");
    data[formatted] = glucose;
    AppController.app.setState({
      sensorData: data,
    });
  },
  onDateChanged(e) {
    let date = e.detail.data;
    let hours = ((date.getHours() + 11) % 12) + 1;
    let mins = date.getMinutes();
    mins = (parseInt(mins, 10) < 10 ? "0" : "") + mins;
    EventManager.fire("#clock." + hours + "." + mins);
  },
  warn(message) {
    console.log("TODO: add snackbar warning for app");
    console.warn(message);
  },
  error(message) {
    console.log("TODO: add snackbar error for app");
    console.error(message);
  },
  replayScene() {
    // Get / Load id of introduction scene.
    const scenes = store.getState().scenes.scenes;
    const scene = scenes.find(
      (n) => n.info["Scene Title"] === this.lastScene.title
    );
    store.dispatch(sceneGet(scene.id));

    // wait for store scene to become not null
    const timer = setInterval(() => {
      if (store.getState().scenes.scene) {
        clearInterval(timer);
        EventManager.fire("#load-scene", store.getState().scenes.scene.info);
      }
    }, 100);
  },
  loadScene(e) {
    EventManager.fire("#buttons.none");
    EventManager.fire("#chat.hide");
    this.issueCommand("#hide.Pump");
    this.sessionId = v4();
    this.unloadScene();
    this.scene = new SceneController(e.detail.data);
    this.lastScene = { ...this.scene };
    // hide scene select button
    AppController.app.setState({
      scenesButtonVisible: false,
    });
  },
  unloadScene() {
    // Null scene from redux
    store.dispatch(sceneNullOut());

    // if (!this.scene) {
    //   return;
    // }

    // setTimeout(() => {
    EventManager.fire("#speak.stop");
    this.isSpeaking = false;
    this.issueCommand("#hide.Pump");
    EventManager.fire("#chat.hide");
    this.speakQueue = [];
    if (this.scene) {
      this.scene.unload();
      this.scene = null;
      delete this.scene;
    }
    // }, 100);
  },
  resetApp() {
    AppController.unloadScene();
    EventManager.fire("#reload.data");
    AppController.app.setState(AppController.appDefaultState);
  },
  onSumerianMessage(details) {
    if (!details.action) {
      return;
    }
    if (details.action === "sumerian-ready") {
      this.issueCommand("#hide.Pump");
      this.issueCommand("#clock.9.16");
      EventManager.fire("#sumerian.ready");
    } else if (details.action === "speech-finished") {
      // close chat bubble?
      EventManager.fire("#chat.hide");
      // TODO - fix me
      if (this.speakQueue.length) {
        this.onSpeakQueueShifted(this.speakQueue.shift());
      } else {
        this.isSpeaking = false;
        EventManager.fire("#speak.finished");
      }
    } else if (details.action.toLowerCase().search(/screen:/) > -1) {
      // open / close a screen. If none is the second param a
      // #screen.none event will be fired to signal all open views to close
      let screen = details.action.split(":")[1].toLowerCase();
      EventManager.fire("#screen." + screen);
    } else if (details.action.search(/buttons:/) > -1) {
      // #button mark
      let args = details.action.split(":");
      args.shift(); // remove #buttons from list
      // always add meter to the list
      args.push("meter");
      if (args[0] === "all") {
        args.push(
          ...[
            "ketones",
            "eat",
            "sleep",
            "wait",
            "exercise",
            "check-site",
            "pump",
          ]
        );
      } else if (args[0] === "none") {
        args = [];
      }
      AppController.app.setState({
        actionButtons: args,
      });
    } else if (details.action.search(/button.Act/) > -1) {
      // Buttons from pump
      EventManager.fire("#button.pump.act");
    } else if (details.action.search(/button.Esc/) > -1) {
      EventManager.fire("#button.pump.esc");
    } else if (details.action.search(/button.Up/) > -1) {
      EventManager.fire("#button.pump.up");
    } else if (details.action.search(/button.Down/) > -1) {
      EventManager.fire("#button.pump.down");
    } else if (details.action.search(/action:/) > -1) {
      let _args = details.action.split(":");
      _args.shift(); // remove #action from list
      this.scene.waitingForAciton = _args[0];
    }
  },

  // Check if user has played before
  // one of 2 things happen before the first scenario loads.
  // 1. The welcome scene plays
  // 2. The welcome back scene plays
  // loadDataFromJSON(callback, _path) {
  //   // _path is for testing
  //   let path = _path || "data/a1.json";
  //   axios
  //     .get(path)
  //     .then((res) => {
  //       AppController.scenarios = [];
  //       AppController.data = res.data.imported;
  //       AppController.parseData();
  //       // for testing
  //       callback && callback(true);
  //     })
  //     .catch((e) => {
  //       console.warn(e);
  //       // for testing
  //       callback && callback(false);
  //     });
  // },
  parseData() {
    console.log("app data:", AppController.data);

    // obj to array for react views
    let arrayOfObj = _.values(AppController.data);

    // get other scenes not part of the welcome scenarios.
    let tutorials = arrayOfObj.filter((v, i, arr) => {
      return (
        v.hasOwnProperty("Scene Name") &&
        v["Scene Type"].search(/tutorial/gi) > -1
      );
    });
    AppController.scenarios = [];
    AppController.scenarios.push(...tutorials);

    let scenes = arrayOfObj.filter((v, i, arr) => {
      return (
        v.hasOwnProperty("Scene Name") &&
        (v["Scene Type"].toLowerCase() === "general" ||
          v["Scene Type"].toLowerCase() === "occlusion")
      );
    });
    AppController.scenarios.push(...scenes);

    // Filter scenarios that are flagged as active=false
    AppController.scenarios = AppController.scenarios.filter(
      (n) => n.active !== false
    );
    // console.log(
    //   "AppController.scenarios.length",
    //   AppController.scenarios.length
    // );

    EventManager.fire("#scenarios.loaded", this.scenarios);
    _.defer(() => {
      EventManager.fire("#terms.loaded", this.data.terms);
    });

    // check if user has unlocked the initial scene
    const user = AppController.getUser();
    if (!user.scenes.hasOwnProperty(this.scenarios[0]["Scene Name"])) {
      // unlock first scene
      AppController.unlockScene(this.scenarios[0]["Scene Name"], user);
    }
  },
  translate(host = true, x, y, z) {
    let cmd =
      "translate:" + (host ? "host" : "avatar") + ":" + x + ":" + y + ":" + z;
    let f = document.getElementById("sumerian-scene");
    f.contentWindow.postMessage(
      {
        type: "a1control",
        details: {
          command: cmd,
        },
      },
      "*"
    );
  },
  issueCommand(cmd) {
    // remove hash from command
    console.log("cmd", cmd);
    cmd = _.replace(cmd, "#", "");
    // replace . with :
    cmd = _.replace(cmd, /\./g, ":");
    this.getSumerianFrame().contentWindow.postMessage(
      {
        type: "a1control",
        details: {
          command: cmd,
        },
      },
      "*"
    );
  },
  speak(_dialog) {
    // Separate dialogs and filter out any empty dialog.
    this.speakQueue = _dialog.split("\n").filter((n) => n);
    this.onSpeakQueueShifted(this.speakQueue.shift());
  },
  // getSumerianFrame() {
  // if (!this.sumerianIframe) {
  //   this.sumerianIframe = document.getElementById("sumerian-scene");
  // }
  // return this.sumerianIframe;
  // },
  onSpeakQueueShifted(_dialog) {
    // parse dialog for commands / tags
    let { dialog, isHost } = this.parseDialog(_dialog);

    // skip polly audio and just play the dialog and simulate a convo
    // if (this.quickTest) {
    //   EventManager.fire("#chat.show", this.sterilizeDialog(dialog));
    //   let command = "speak:" + (isHost ? "host" : "avatar");
    //   this.getSumerianFrame().contentWindow.postMessage(
    //     {
    //       type: "a1control",
    //       details: {
    //         dialog: dialog,
    //         command: command,
    //       },
    //     },
    //     "*"
    //   );
    //   EventManager.fire("#speak.started");
    //   this.isSpeaking = true;
    //   // simulate sumerian callback
    //   setTimeout(() => {
    //     AppController.onSumerianMessage({
    //       action: "speech-finished",
    //     });
    //   }, 100);
    //   return true;
    // }

    // display in bottom chat bar
    EventManager.fire("#chat.show", this.sterilizeDialog(dialog));
    let command = "speak:" + (isHost ? "host" : "avatar");
    this.getSumerianFrame().contentWindow.postMessage(
      {
        type: "a1control",
        details: {
          dialog: dialog,
          command: command,
        },
      },
      "*"
    );
    EventManager.fire("#speak.started");
    this.isSpeaking = true;
    // TODO: - add quick stop and go to the next dialog chunk
    return true;
  },
  parseDialog(dialog) {
    // host or avatar
    let isHost =
      dialog.search(/avatar:/gi) === -1 && dialog.search(/#avatar/gi) === -1;
    dialog = _.replace(dialog, /host:/gi, "");
    dialog = _.replace(dialog, /#host/gi, "");
    dialog = _.replace(dialog, /avatar:/gi, "");
    dialog = _.replace(dialog, /#avatar/gi, "");

    let commands = dialog.split(" ").filter((word) => {
      return word.search(/#/) > -1;
    });

    commands.forEach((cmd) => {
      if (cmd.search(/#wait\./gi) > -1) {
        let duration = cmd.split(".")[1];
        let tag = '<break time="' + duration + 'ms"/>';
        dialog = _.replace(dialog, cmd, tag);
        return;
      }

      // #bg
      if (cmd.search(/#bg/) > -1) {
        // get current blood glucose from scene
        //let glucose = AppController.scene.glucose;
        dialog = _.replace(
          dialog,
          cmd,
          Math.round(AppController.app.state.glucose)
        );
        return;
      }

      if (cmd.search(/#cam/) > -1) {
        //EventManager.fire("#fade");
      }

      if (cmd.search(/#rotate/) > -1) {
        //EventManager.fire("#fade");
      }

      let mark = cmd;

      // console.log("cmd", cmd);

      mark = _.replace(mark, /#/, "");

      // Image tags need to retain their characters
      if (cmd.search(/#image/) > -1) {
        const url = cmd.substring("#image.url.".length);
        mark = `image:url:${url}`;
      } else {
        mark = _.replace(mark, /\./g, ":");
      }
      mark = '<mark name="' + mark + '"/>';

      // console.log("sending mark: ", mark);
      dialog = _.replace(dialog, cmd, mark);
    });

    return {
      dialog: dialog,
      isHost: isHost,
    };
  },
  sterilizeDialog(d) {
    return d.replace(/<\/?[^>]+(>|$)/g, "");
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  async putBreadCrumbs() {
    // Check Scene type before putting. Not all scenes need to submit crumbs. Like intros.
    const scene = AppController.scene;
    const key = `crumbs.json`;
    const url = await Storage.put(
      key,
      JSON.stringify(AppController.breadcrumbs),
      {
        level: "private",
        contentType: "application/json",
      }
    );
    console.log("file path", url);

    // Save breadcrumbs to dynamo on user.sessions[]
    const _scene = { ...scene.data };
    delete _scene.states;
    const session = {
      createdAt: new Date().toISOString(),
      scene: _scene,
      // appState: AppController.app.state,
      breadcrumbs: this.breadcrumbs,
      id: this.sessionId,
    };
    store.dispatch(saveUserSession(session));
    return url;
  },
  exitSceneEarly(dialog) {
    // close pump if open
    if (AppController.app.state.pumpOpen) {
      AppController.app.setState({
        pumpOpen: false,
      });
    }
    AppController.breadcrumbs.forEach((crumb) => {
      console.log(crumb);
    });

    // Reset in preparation for next scene.
    AppController.unloadScene();
    if (dialog) {
      setTimeout(() => {
        window.modelController.say(dialog);
      }, 300);
    }
  },
  onScenarioFinished() {
    // close pump if open
    if (AppController.app.state.pumpOpen) {
      AppController.app.setState({
        pumpOpen: false,
      });
    }
    AppController.breadcrumbs.forEach((crumb) => {
      console.log(crumb);
    });

    // If this scene is an introduction scene type don't show the results view.
    if (
      AppController.lastScene.data["Scene Type"] === SCENE_TYPES.INTRODUCTION ||
      AppController.lastScene.data["Scene Type"] ===
        SCENE_TYPES.INTRODUCTION_RETURN
    ) {
      // load select scene panel
      EventManager.fire("#screen.scenes");
    } else {
      AppController.putBreadCrumbs();
      AppController.loadResultsView();
    }

    // Reset in preparation for next scene.
    AppController.unloadScene();
  },
  loadResultsView() {
    debugger;
    let user = AppController.getUser();
    let onNext = null;
    let _scene = AppController.scene;
    let sceneName = _scene.data["Scene Name"];
    // TODO: create scene entity on user.scenes if none

    // check if user object has scene, if not, create it for high score tracking
    if (!user.scenes[sceneName]) {
      const scene = { points: 0 };
      user.scenes[sceneName] = scene;
      this.saveUser(user);

      // const user = JSON.parse(ls(userKey)) || {
      //   key: userKey,
      //   scenes: {},
      // };
    }

    // let _scene = AppController.scene || {
    //   data: {
    //     "Scene Title": "Test Scene",
    //     "Scene Name": "Foo Name",
    //   },
    // };

    let points = AppController.app.state.score;
    let sceneCount = AppController.scenarios.length;
    //let names = AppController.scenarios.map((scene)=>scene['Scene Name']);
    if (points > 0) {
      // check if points are greater than stored users scenes previous points, if so, update
      if (points > user.scenes[sceneName].points) {
        user.scenes[sceneName].points = points;
        EventManager.fire("#alert", "New High Score!");
        AppController.saveUser(user);
      }
    }
    // check if saved points is > 0
    if (user.scenes[sceneName].points > 0) {
      // show next arrow
      // get next scene
      // check if there is a next scene by getting the index of this scene and comparing it to the total scene count
      let thisSceneIndex = Object.keys(user.scenes).indexOf(sceneName);
      if (thisSceneIndex < sceneCount - 1) {
        let nextScene = AppController.scenarios[thisSceneIndex + 1];
        onNext = () => {
          EventManager.fire("#load-scene", nextScene);
        };

        //unlockScene(sceneName,_user){
        // call unlock next scene
        AppController.unlockScene(nextScene["Scene Name"], user);
      }
    }
    AppController.app.setState({
      resultsView: {
        visible: true,
        points: points,
        title: _scene.data["Scene Title"],
        onNext: onNext,
      },
    });
  },
  getUser() {
    // The user is now a cognito user. The user value in this file is set in App.js in the constructor.
    // user.username is the local storage key now used. But should be migrated to storage
    // TODO: migrate user data to storage

    let userKey = this.user.username; // AppController.getUrlParam("user") || "default-user";
    const user = JSON.parse(ls(userKey)) || {
      // key: userKey,
      scenes: {},
    };
    return user;
  },
  saveUser(obj) {
    let userKey = this.user.username; // AppController.getUrlParam("user") || "default-user";
    ls(userKey, JSON.stringify(obj));
    return obj;
  },
  getUrlParam(parameter) {
    if (window.location.href.indexOf(parameter) > -1) {
      return AppController.getUrlVars()[parameter];
    }
    return null;
  },
  getUrlVars() {
    let vars = {};
    window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  },
  unlockScene(sceneName, _user) {
    let sceneCount = AppController.scenarios.length;
    // prevents other code blocks from overwriting user obj
    let user = _user || AppController.getUser();
    if (!user.scenes.hasOwnProperty(sceneName)) {
      user.scenes[sceneName] = {
        points: 0,
      };
      //if(Object.keys(user.scenes).length>0)
      if (Object.keys(user.scenes).length < sceneCount) {
        EventManager.fire("#alert", "New Scene Unlocked!");
        AppController.app.forceUpdate();
      }
      AppController.saveUser(user);
    } else {
      console.warn(sceneName, " already unlocked");
    }
  },
};
AppController.init();
window.appController = AppController;
export default AppController;
