import { Animation, CubicEase, EasingFunction } from "@babylonjs/core";

const FRAMES_PER_SECOND = 60;
const SPEED_RATIO = 1.4;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

export default class ModelController {
  camera = null;
  scene = null;
  constructor(scene) {
    this.scene = scene;
    this.camera = scene.activeCamera;
    window.camera = this;
  }
  wideView() {
    this.moveActiveCamera({
      alpha: 1.483,
      beta: 1.57,
      radius: 2.6, // zoom
      target: {
        x: 0,
        y: 1,
        z: 0,
      },
    });
  }
  lookAtHost() {
    this.moveActiveCamera({
      alpha: 1.483,
      beta: 1.57,
      radius: 1,
      target: {
        x: 0.6,
        y: 1.2,
        z: 0,
      },
    });
  }
  lookAtPatient() {
    this.moveActiveCamera({
      alpha: 1.483,
      beta: 1.57,
      radius: 1,
      target: {
        x: -0.4,
        y: 1.2,
        z: 0,
      },
    });
  }
  lookAtPatientLow() {
    this.moveActiveCamera({
      alpha: 1.483,
      beta: 1.57,
      radius: 2.6, // zoom
      target: {
        x: -0.4,
        y: 0.3,
        z: 0,
      },
    });
  }
  createAnimation = ({ property, from, to }) => {
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

    const animation = Animation.CreateAnimation(
      property,
      Animation.ANIMATIONTYPE_FLOAT,
      FRAMES_PER_SECOND,
      ease
    );
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  };
  moveActiveCamera = ({ radius, alpha, beta, target }) => {
    const camera = this.scene.activeCamera;

    camera.animations = [
      this.createAnimation({
        property: "radius",
        from: camera.radius,
        to: radius,
      }),
      this.createAnimation({
        property: "beta",
        from: camera.beta,
        to: beta,
      }),
      this.createAnimation({
        property: "alpha",
        from: camera.alpha,
        to: alpha,
      }),
      this.createAnimation({
        property: "target.x",
        from: camera.target.x,
        to: target.x,
      }),
      this.createAnimation({
        property: "target.y",
        from: camera.target.y,
        to: target.y,
      }),
      this.createAnimation({
        property: "target.z",
        from: camera.target.z,
        to: target.z,
      }),
    ];

    this.scene.beginAnimation(
      camera,
      FROM_FRAME,
      TO_FRAME,
      LOOP_MODE,
      SPEED_RATIO
    );
  };
}
