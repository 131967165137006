import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SCENE_TYPES } from "../../utils/constants";
import { sceneCreate } from "../../utils/redux/features/scenes";

export const SceneCreateDialog = ({ onClose }) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    "Scene Name": "",
    "Scene Title": "",
    "Scene Description": "",
    "Scene Starting BG": "",
    "Scene Type": "",
    "Scene Starting Time": "12:00",
    "Scene Difficulty": "Introduction",
    "Scene Points": "3",
    published: false,
    active: true,
    states: [],
  });

  const onSave = () => {
    if (!form["Scene Name"]) {
      toast.warn("Scene Title must not be empty");
      return;
    }
    dispatch(sceneCreate(form));
    onClose();
  };

  return (
    <Dialog maxWidth={false} open={true} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        <TextField
          autoFocus
          margin="dense"
          label="Scene Title"
          type="text"
          fullWidth
          variant="standard"
          value={form["Scene Title"]}
          onChange={(e) =>
            setForm({
              ...form,
              ...{
                "Scene Title": e.target.value,
                "Scene Name": e.target.value,
              },
            })
          }
        />
        <FormControl variant="standard" sx={{ m: 0, minWidth: 220 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Scene Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={form["Scene Type"]}
            onChange={(e) => {
              setForm({ ...form, ...{ "Scene Type": e.target.value } });
            }}
            label="Scene Type"
          >
            {Object.values(SCENE_TYPES).map((sceneType, key) => {
              return (
                <MenuItem key={key} value={sceneType}>
                  <Typography textTransform="capitalize">
                    {sceneType}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Scene Description"
          type="text"
          fullWidth
          variant="standard"
          value={form["Scene Description"]}
          onChange={(e) =>
            setForm({ ...form, ...{ "Scene Description": e.target.value } })
          }
        />
        <TextField
          margin="dense"
          label="Scene Starting BG"
          type="text"
          fullWidth
          variant="standard"
          value={form["Scene Starting BG"]}
          onChange={(e) =>
            setForm({ ...form, ...{ "Scene Starting BG": e.target.value } })
          }
        />
        <TextField
          margin="dense"
          label="Scene Starting Time"
          type="text"
          fullWidth
          variant="standard"
          value={form["Scene Starting Time"]}
          onChange={(e) =>
            setForm({ ...form, ...{ "Scene Starting Time": e.target.value } })
          }
        />
        <TextField
          margin="dense"
          label="Scene Difficulty"
          type="text"
          fullWidth
          variant="standard"
          value={form["Scene Difficulty"]}
          onChange={(e) =>
            setForm({ ...form, ...{ "Scene Difficulty": e.target.value } })
          }
        />
        <DialogActions>
          <div>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onSave}>Save</Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
