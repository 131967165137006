import React, { useEffect, useRef, useState } from "react";
import localStorage from "local-storage";
// import TweenMax, { Expo, Sine } from "gsap";
import EventManager from "./../utils/EventManager";
import AppController from "./../utils/AppController";
import closeButtonSrc from "./../images/close-button.svg";
import SceneSelectMenuItem from "./SceneSelectMenuItem";
import "./SceneSelect.css";
import { useDispatch, useSelector } from "react-redux";
import {
  sceneCreate,
  sceneDelete,
  sceneEditingGet,
  sceneEditingNullOut,
  sceneGet,
  scenesGet,
} from "../utils/redux/features/scenes";
import {
  Button,
  TextField,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { CircularProgress, Dialog, DialogActions, Stack } from "@mui/material";
import AlertDialog from "./AlertDialog";
import { SceneEditor } from "./sceneEditor/SceneEditor";
import { SceneCreateDialog } from "./sceneEditor/SceneCreateDialog";
import { SCENE_TYPES, playSilentAudio } from "../utils/constants";
import { get } from "lodash";
import SceneSelectMenuItemV2 from "./SceneSelectMenuItemV2";
import { motion } from "framer-motion";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default () => {
  const scenes = useSelector((state) => state.scenes.scenes);
  const scene = useSelector((state) => state.scenes.scene);
  const user = useSelector((s) => s.userSlice.user);
  const editingSceneData = useSelector((state) => state.scenes.editingScene);
  // const editingSceneLoading = useSelector(
  //   (state) => state.scenes.editingSceneLoading
  // );
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [sceneDeleteID, setSceneDeleteID] = useState(null);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [inputBoxOpen, setInputBoxOpen] = useState(false);
  const [jsonToPaste, setJsonToPaste] = useState("");
  const [isSceneCreateDialogOpen, setIsSceneCreateDialogOpen] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const ref = useRef();
  const dispatch = useDispatch();

  const close = () => {
    // TweenMax.to(ref.current, 0.1, {
    //   y: 640,
    //   ease: Expo.easeIn,
    //   onComplete: () => {
    // toggles visible prop
    let newState = Object.assign({}, AppController.app.state.sceneSelect, {
      visible: false,
    });
    AppController.app.setState({ sceneSelect: newState });
    // },
    // });
  };

  const onDeleteScene = (id) => {
    setSceneDeleteID(id);
    setIsDeleteAlertOpen(true);
  };

  const onDeleteConfirmation = (confirm) => {
    if (confirm) {
      dispatch(sceneDelete(sceneDeleteID));
    } else {
      console.log("not deleting scene.");
    }
    setIsDeleteAlertOpen(false);
  };

  const onCloseButton = () => {
    AppController.app.state.scenesButtonVisible = true;
    close();
  };

  if (scene !== null && !sceneLoaded) {
    setSceneLoaded(true);

    EventManager.fire("#load-scene", scene);
    close();
  }

  const createListItems = () => {
    // Sort scenes by user.profile.sceneConfig if it exists
    const sceneConfig = get(
      user.profile,
      "sceneConfig",
      scenes.map((n) => ({ id: n.id, visible: true }))
    );

    // Sort list based on sceneConfig.
    const _scenes = scenes
      .map((n) => {
        const sortIndex = sceneConfig.findIndex((nn) => nn.id === n.id);
        if (sortIndex > -1) {
          const configEntity = sceneConfig[sortIndex];
          const visible = get(configEntity, "visible", true);
          return { ...n, sortIndex, visible };
        }
        return { ...n, sortIndex, visible: true };
      })
      .sort((a, b) => {
        return a.sortIndex - b.sortIndex;
      });

    const _scenes2 = _scenes
      .filter((n) => n.info.active !== false)
      .filter((n) => {
        if (AppController.isAdmin) return true;

        const scene = n.info;
        return !(
          scene["Scene Type"] === SCENE_TYPES.INTRODUCTION ||
          scene["Scene Type"] === SCENE_TYPES.INTRODUCTION_RETURN
        );
      });
    // TAB 1
    const tutorialScenes = _scenes2.filter(
      (n) => n.info["Scene Type"] === SCENE_TYPES.TUTORIAL
    );
    const regularScenes = _scenes2.filter(
      (n) => n.info["Scene Type"] !== SCENE_TYPES.TUTORIAL
    );

    // Get score data from local storage
    const scoreData = AppController.getUser();

    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Scenarios" {...a11yProps(0)} />
            <Tab label="Tutorials" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {regularScenes.map((_scene, key) => {
            const scene = _scene.info;
            const locked = false; // !user.scenes.hasOwnProperty(item["Scene Name"]);
            let points = 0;

            if (!locked) {
              // Get score from local storage
              if (scoreData.scenes[scene["Scene Name"]]) {
                points = scoreData.scenes[scene["Scene Name"]]["points"];
              }
            }

            return (
              <motion.div
                transition={{ delay: 0.1 * key, ease: "easeOut" }}
                initial={{ opacity: 0, y: -10, scale: 1.2 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                key={key}
              >
                <SceneSelectMenuItemV2
                  user={user}
                  locked={locked}
                  points={points}
                  onPlayButtonClick={function (e) {
                    // playSilentAudio();
                    if (AppController.isSpeaking) return;
                    dispatch(sceneGet(e.id));
                  }}
                  index={key}
                  key={key}
                  data={_scene}
                  onDeleteScene={onDeleteScene}
                  onEditScene={(id) => {
                    console.log("scene id", id);
                    dispatch(sceneEditingGet(id));
                  }}
                />
              </motion.div>
            );
          })}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          {tutorialScenes.map((_scene, key) => {
            const scene = _scene.info;
            const locked = false; // !user.scenes.hasOwnProperty(item["Scene Name"]);
            let points = 0;

            if (!locked) {
              // Get score from local storage
              if (scoreData.scenes[scene["Scene Name"]]) {
                points = scoreData.scenes[scene["Scene Name"]]["points"];
              }
            }

            return (
              <motion.div
                transition={{ delay: 0.1 * key, ease: "easeOut" }}
                initial={{ opacity: 0, y: -10, scale: 1.2 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                key={key}
              >
                <SceneSelectMenuItemV2
                  user={user}
                  locked={locked}
                  points={points}
                  onPlayButtonClick={function (e) {
                    // playSilentAudio()
                    if (AppController.isSpeaking) return;
                    dispatch(sceneGet(e.id));
                  }}
                  index={key}
                  key={key}
                  data={_scene}
                  onDeleteScene={onDeleteScene}
                  onEditScene={(id) => {
                    console.log("scene id", id);
                    dispatch(sceneEditingGet(id));
                  }}
                />
              </motion.div>
            );
          })}
        </CustomTabPanel>
      </Box>
    );
  };

  const createNewScene = () => {
    // Open create new scene dialog
    // setSceneEditorData(null);
    // setIsSceneEditorVisible(true);
    // TODO - open scene creation dialog
    setIsSceneCreateDialogOpen(true);
  };

  return (
    <Dialog
      open
      fullWidth
      style={{ overflow: "hidden" }}
      onClose={() => {
        onCloseButton();
      }}
    >
      <DialogTitle style={{ color: "white", backgroundColor: "#ff4848" }}>
        Scenario Select
      </DialogTitle>
      <DialogContent>
        <div
          className="d-flex flex-row w-100 flex-wrap justify-content-center"
          style={{ gap: 10 }}
        >
          {createListItems()}
        </div>
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          title="Really Delete Scene?"
          content={`You're about to delete ${sceneDeleteID}, are you sure?`}
          onResponse={onDeleteConfirmation}
        />
        {editingSceneData && (
          <Box
            className="w-100 h-100 position-absolute justify-content-center align-items-center"
            style={{
              backgroundColor: "rgb(0 0 0 / 78%)",
              top: 0,
              left: 0,
            }}
            sx={{ display: "flex" }}
          >
            <CircularProgress />
          </Box>
        )}
        {editingSceneData && (
          <SceneEditor
            open={true}
            onCancelSceneEditor={() => dispatch(sceneEditingNullOut())}
            data={editingSceneData}
          />
        )}
        {isSceneCreateDialogOpen && (
          <SceneCreateDialog
            onClose={() => {
              setIsSceneCreateDialogOpen(false);
            }}
          />
        )}
      </DialogContent>

      <DialogActions style={{ color: "white", backgroundColor: "#ff4848" }}>
        {AppController.isAdmin && (
          <Button style={{ color: "white" }} onClick={() => createNewScene()}>
            Create New Scene
          </Button>
        )}
        <Button
          style={{ color: "white" }}
          onClick={() => {
            onCloseButton();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

{
  /* <div
        ref={ref}
        id="sceneSelect-view"
        className="d-flex flex-column h-auto align-self-center m-auto jumbotron"
      >
        <div id="scene-select-view-title">Scenario Select</div>
        <img
          alt="close"
          onClick={onCloseButton}
          src={closeButtonSrc}
          id="sceneSelect-view-close-button"
        />
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          title="Really Delete Scene?"
          content={`You're about to delete ${sceneDeleteID}, are you sure?`}
          onResponse={onDeleteConfirmation}
        />
        {editingSceneData && (
          <Box
            className="w-100 h-100 position-absolute justify-content-center align-items-center"
            style={{
              backgroundColor: "rgb(0 0 0 / 78%)",
              top: 0,
              left: 0,
            }}
            sx={{ display: "flex" }}
          >
            <CircularProgress />
          </Box>
        )}
        {editingSceneData && (
          <SceneEditor
            open={true}
            onCancelSceneEditor={() => dispatch(sceneEditingNullOut())}
            data={editingSceneData}
          />
        )}
        {isSceneCreateDialogOpen && (
          <SceneCreateDialog
            onClose={() => {
              setIsSceneCreateDialogOpen(false);
            }}
          />
        )}
      </div> */
}
