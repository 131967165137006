import React from "react";
import meterImg from "../images/meter.png";
import closeImg from "../images/closeBtn.png";
import AppController from "../utils/AppController";
import Draggable from "react-draggable";

const styles = {
  background: {
    position: "absolute",
    color: "#ffffff",
    fontSize: 68,
    textAlign: "center",
    top: 110,
    width: 266,
    fontFamily: "Montserrat,sans-serif",
  },
  button: {
    position: "absolute",
    left: 209,
    top: 20,
    cursor: "pointer",
  },
};

export const Meter = () => {
  return (
    <Draggable>
      <div
        style={{
          position: "absolute",
          top: "10%",
          right: "5%",
        }}
      >
        <img style={{ pointerEvents: "none" }} src={meterImg} />
        <img
          src={closeImg}
          style={styles.button}
          onClick={() => {
            AppController.app.setState({
              meterOpen: false,
            });
          }}
        />
        <div style={styles.background} className="reader-text">
          {Math.round(AppController.app.state.glucose)}
        </div>
      </div>
    </Draggable>
  );
};
