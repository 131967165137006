import React from 'react';
import AppController from './../../utils/AppController';
import EventManager from './../../utils/EventManager';
export default class PumpBasalSetup extends React.Component{
    state={
        state:0,
        basalUnits:AppController.app.state.basalUnits
    };
    componentDidMount=()=>{
        EventManager.listen("#button.pump.act",this.onAct);
        EventManager.listen("#button.pump.esc",this.onEcs);
        EventManager.listen("#button.pump.up",this.onUp);
        EventManager.listen("#button.pump.down",this.onDown);
        AppController.pumpState = AppController.pumpState_12HourSetup; 
    }
    componentWillUnmount=()=>{
        EventManager.stopListening("#button.pump.act",this.onAct);
        EventManager.stopListening("#button.pump.esc",this.onEcs);
        EventManager.stopListening("#button.pump.up",this.onUp);
        EventManager.stopListening("#button.pump.down",this.onDown);
    }
    onAct=()=>{
        AppController.app.state.basalUnits = parseFloat(this.state.basalUnits.toFixed(3));
        AppController.app.forceUpdate();
        this.props.onDone(); 
    }
    onEsc=()=>{
        this.props.onDone();
    }
    onUp=()=>{
        this.setState((pState)=>{
            return {
               basalUnits:pState.basalUnits+0.025
            }
        });
    }
    onDown=()=>{
        if(this.state.basalUnits===0)return;
        this.setState((pState)=>{
            return {
               basalUnits:pState.basalUnits-0.025 
            }
        });
    }
    renderView=()=>{
        if(this.state.state===0){
            return (<React.Fragment>
                <div>SET BASAL RATE</div>
                <span className="blink pump-big-font">{this.state.basalUnits.toFixed(3)}</span><span>U/hr</span>
            </React.Fragment>)
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        );
    }
}
