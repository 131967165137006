/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "scenerioApi",
            "endpoint": "https://2b6s35fmh1.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:813f114a-26a4-4f7c-acd0-f8dbbdbabe17",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_W45l1GQGX",
    "aws_user_pools_web_client_id": "2c9ovforgtjhv8nqeqldo6jqak",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "a1breadcrumbs141342-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "a1scenesDB-dev",
            "region": "us-east-2"
        },
        {
            "tableName": "a1usersDB-dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
