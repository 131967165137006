import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DialogContentText } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { sceneAddUserAction } from "../../utils/redux/features/scenes";
import { Box } from "@mui/material";
import { USER_ACTIONS } from "../../utils/constants";

export default ({ isOpen, onClose, stateName, gcLevel }) => {
  const [userAction, setUserAction] = useState("");
  const dispatch = useDispatch();

  const onSave = () => {
    if (!userAction.length) {
      toast.warn("new state name must not be empty");
      return;
    }
    dispatch(sceneAddUserAction({ stateName, gcLevel, action: userAction }));
    toast.success(`new user action ${userAction} created!`);
    onClose();
  };
  return (
    <Dialog maxWidth={false} open={isOpen} onClose={onClose}>
      <DialogTitle>Create User Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This function creates a new user action.
        </DialogContentText>
        {/* <TextField
          margin="dense"
          label="State Name"
          type="text"
          fullWidth
          variant="standard"
          value={userAction}
          onChange={(e) => setUserAction(e.target.value)}
        /> */}

        <Box className="d-flex justify-content-between">
          {/* <Typography variant="h5">{details["User Events"]}</Typography> */}
          <FormControl variant="standard" sx={{ m: 0, minWidth: 220 }}>
            <InputLabel id="demo-simple-select-standard-label">
              User Actions
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={userAction}
              onChange={(e) => setUserAction(e.target.value)}
              label="User Actions"
            >
              {/* {sceneStates.map(() => {
                return (
                  <MenuItem key={action} value={stateName}>
                    {stateName}
                  </MenuItem>
                );
              })} */}
              {Object.entries(USER_ACTIONS).map(([prettyName, key]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {prettyName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <DialogActions>
          <div>
            <Button onClick={onSave}>Save</Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
