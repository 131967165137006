import React, { useState } from "react";
import "./SceneSelectMenuItem.css";
import starImgSrc from "./../images/big-star.png";
import starEmptyImgSrc from "./../images/big-empty-star.png";
import lockIcon from "./../images/lock-icon.svg";
import em from "./../utils/EventManager";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AppController from "../utils/AppController";
import { Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClapperboard,
  faEdit,
  faLock,
  faPencil,
  faPlay,
  faPlayCircle,
  faStar,
  faStarHalfStroke,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Button, Typography } from "@material-ui/core";
import { toast } from "react-toastify";

const iconList = [
  require("../images/clock.png"),
  require("../images/host.png"),
  require("../images/guy.png"),
  require("../images/pump.png"),
  require("../images/phone.png"),
];

export default (props) => {
  const { user, data } = props;
  const scene = data.info;
  const { profile } = user;
  const randomIndex = Math.ceil(Math.random() * iconList.length - 1);
  const [randomIcon, _] = useState(iconList[randomIndex]);

  const onClickedItem = (data) => {
    if (props.locked) {
      em.fire("#alert", props.data.info["Scene Title"] + " is locked");
      return;
    }
    props.onPlayButtonClick(props.data);
  };

  const renderStars = () => {
    const classes = { ...props };
    let items = [];
    for (let i = 0; i < 3; i++) {
      let unlocked = classes.points > i;
      if (unlocked) {
        items.push(<FontAwesomeIcon color="yellow" key={i} icon={faStar} />);
      } else {
        items.push(<FontAwesomeIcon color="gray" key={i} icon={faStar} />);
      }
    }
    return <div className="d-flex align-items-center">{items}</div>;
  };
  // const locked = props.locked;
  // const visible = data.visible;
  const locked = !data.visible;
  // console.log("visible", visible);

  return (
    <Paper
      onClick={() => {
        if (!locked) {
          onClickedItem();
        } else {
          toast.warn("this scenario is locked");
        }
      }}
      className="d-flex w-100 align-items-center justify-content-between p-4"
      style={{ gap: 10, backgroundColor: locked ? "darkgrey" : "aliceblue" }}
    >
      <div className="d-flex">
        <Avatar style={{ scale: "1.8" }} variant="rounded" src={randomIcon} />
      </div>
      <div className="d-flex flex-column">
        <div
          className="d-flex align-items-center"
          style={{ gap: 10, width: 200 }}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faClapperboard} />
          </div>
          <div className="d-flex">
            <Typography>{scene["Scene Title"]}</Typography>
          </div>
        </div>
        <div className="d-flex" style={{ gap: 10 }}>
          <Button style={{ backgroundColor: "chartreuse" }} variant="contained">
            Play
          </Button>
          {/* {locked ? (
            <FontAwesomeIcon icon={faLock} />
          ) : (
            // <>
            //   <div
            //     className="d-flex align-items-center"
            //     style={{ cursor: "pointer" }}
            //   >
            //     <FontAwesomeIcon
            //       icon={faPlayCircle}
            //       onClick={(e) => {
            //         // e.stopPropagation();
            //         // props.onEditScene(props.data);
            //         console.log("TODO: play scene");
            //       }}
            //     />
            //   </div>
            //   <div className="d-flex">
            //     <Typography>Play</Typography>
            //   </div>
            // </>
          )} */}
        </div>
      </div>

      <div className="d-flex flex-column align-items-center">
        <div className="d-flex">
          <Typography>Progress</Typography>
        </div>
        <div className="d-flex">{renderStars()}</div>
      </div>

      {AppController.isAdmin && (
        <div
          className="d-flex align-items-center"
          style={{ gap: 20, cursor: "pointer" }}
        >
          <FontAwesomeIcon
            icon={faPencil}
            onClick={(e) => {
              e.stopPropagation();
              props.onEditScene(props.data.id);
            }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={(e) => {
              e.stopPropagation();
              props.onDeleteScene(props.data.id);
            }}
          />
        </div>
      )}
    </Paper>
  );
};
