import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionActions, Button, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppController from "../utils/AppController";
import { glossaryDelete, glossaryGet } from "../utils/redux/features/glossary";
import {
  uiIsAddEditGlossaryOpen,
  uiIsGlossaryOpen,
} from "../utils/redux/features/ui";
import closeButtonSrc from "./../images/close-button.svg";
import "./Terms.css";

export default () => {
  const glossary = useSelector((state) => state.glossary.glossary);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(glossaryGet());
  }, []);

  const createList = () => {
    let data = glossary;
    let items = [];

    for (let item of data) {
      items.push(
        <Accordion
          key={item.title}
          onChange={(_, expanded) => {
            if (expanded) {
              window.modelController.stopSpeech();
              window.modelController.say(item.Definition);
            } else {
              window.modelController.stopSpeech();
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <div className="d-flex justify-content-between w-100">
              <Typography variant="h6">{item.title}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-4">{item.Definition}</div>
          </AccordionDetails>
          {AppController.isAdmin && (
            <AccordionActions>
              <Button
                onClick={() => {
                  dispatch(uiIsAddEditGlossaryOpen(item));
                }}
              >
                Edit
              </Button>
              <Button
                onClick={() => {
                  dispatch(glossaryDelete(item.title));
                }}
              >
                Delete
              </Button>
            </AccordionActions>
          )}
          {/* {AppController.isAdmin ? <AdminGlossaryImage /> : <GlossaryImage />} */}
        </Accordion>
      );
    }
    return items;
  };
  return (
    <div className="terms-view">
      <div className={"terms-view-title"}>Glossary</div>
      <img
        className="terms-view-close-button"
        alt="close"
        onClick={() => {
          dispatch(uiIsGlossaryOpen(false));
        }}
        src={closeButtonSrc}
      />
      {AppController.isAdmin && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <Button
            onClick={() => {
              dispatch(uiIsAddEditGlossaryOpen("new"));
            }}
          >
            Add New
          </Button>
        </Stack>
      )}
      <div className="terms-view-item-container">{createList()};</div>
    </div>
  );
};
