import { createAction } from "@reduxjs/toolkit";

const initState = {
  isSceneSelectOpen: null,
  isSceneEditorOpen: null,
  isDialogEditorOpen: null,
  isGlossaryOpen: null,
  isCgmOpen: null,
  isMeterOpen: null,
  isPumpOpen: null,
  isAddEditGlossaryOpen: false,
  images: [],
  isIOSModalOpen: false,
  isAdminControlsOpen: false,
};

export const uiIsSceneSelectOpen = createAction("ui/sceneSelect/open");
export const uiIsSceneEditorOpen = createAction("ui/sceneEditor/open");
export const uiIsDialogEditorOpen = createAction("ui/dialogEditor/open");
export const uiIsGlossaryEditorOpen = createAction("ui/glossaryEditor/open");
export const uiIsGlossaryOpen = createAction("ui/glossary/open");
export const uiIsCgmOpen = createAction("ui/cgm/open");
export const uiIsMeterOpen = createAction("ui/meter/open");
export const uiIsPumpOpen = createAction("ui/pump/open");
export const uiAddImage = createAction("ui/add/image");
export const uiRemoveImage = createAction("ui/remove/image");
export const uiIsIOSModalOpen = createAction("ui/is/ios/modal/open");
export const uiIsAdminControlsOpen = createAction("ui/isAdminControlsOpen");
export const uiIsAddEditGlossaryOpen = createAction(
  "ui/isAddEditGlossary/open"
);

export default function reducer(state = initState, action) {
  switch (action.type) {
    case uiIsSceneSelectOpen.type:
      return { ...state, isSceneSelectOpen: action.payload };
    case uiIsSceneEditorOpen.type:
      return { ...state, isSceneEditorOpen: action.payload };
    case uiIsDialogEditorOpen.type:
      return { ...state, isDialogEditorOpen: action.payload };
    case uiIsGlossaryOpen.type:
      return { ...state, isGlossaryOpen: action.payload };
    case uiIsCgmOpen.type:
      return { ...state, isCgmOpen: action.payload };
    case uiIsMeterOpen.type:
      return { ...state, isMeterOpen: action.payload };
    case uiIsPumpOpen.type:
      return { ...state, isPumpOpen: action.payload };
    case uiIsAddEditGlossaryOpen.type:
      return { ...state, isAddEditGlossaryOpen: action.payload };
    case uiAddImage.type: {
      const images = [...state.images];
      images.push(action.payload);
      return { ...state, images };
    }
    case uiRemoveImage.type: {
      const images = [...state.images].filter((url) => url !== action.payload);
      return { ...state, images };
    }
    case uiIsIOSModalOpen.type:
      return { ...state, isIOSModalOpen: action.payload };
    case uiIsAdminControlsOpen.type:
      return { ...state, isAdminControlsOpen: action.payload };
  }
  return state;
}
