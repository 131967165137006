import { API_NAME } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

const initState = {
  user: null,
};

export const userGet = createAsyncThunk("user/get", async (_, thunkApi) => {
  let res = null;
  try {
    res = await API.get(API_NAME, "/user");
  } catch (err) {
    console.error(err);
  }
  return res.body;
});

export const userPost = createAsyncThunk(
  "user/post",
  async (payload, thunkApi) => {
    let res = null;
    try {
      res = await API.post(API_NAME, "/user", { payload });
    } catch (err) {
      console.error(err);
    }
    return res.body;
  }
);

export default function reducer(state = initState, action) {
  switch (action.type) {
    case userGet.fulfilled.type:
      return { ...state, user: action.payload };
    case userPost.fulfilled.type:
      return { ...state, user: action.payload };
  }
  return state;
}
