import React from 'react';
import AppController from './../../utils/AppController';
import EventManager from './../../utils/EventManager';
class PumpSetActiveInsTime extends React.Component{
    state={
        state:0,
        active:AppController.app.state.activeInsulinTime
    };
    componentDidMount=()=>{
        EventManager.listen("#button.pump.act",this.onAct);
        EventManager.listen("#button.pump.esc",this.onEcs);
        EventManager.listen("#button.pump.up",this.onUp);
        EventManager.listen("#button.pump.down",this.onDown);
        AppController.pumpState = AppController.pumpState_12HourSetup; 
    }
    componentWillUnmount=()=>{
        EventManager.stopListening("#button.pump.act",this.onAct);
        EventManager.stopListening("#button.pump.esc",this.onEcs);
        EventManager.stopListening("#button.pump.up",this.onUp);
        EventManager.stopListening("#button.pump.down",this.onDown);
    }
    onAct=()=>{
        AppController.app.state.activeInsulinTime = this.state.active;
        AppController.app.forceUpdate();
        this.props.onDone(); 
    }
    onEsc=()=>{
        this.props.onDone();
    }
    onUp=()=>{
        this.setState((pState)=>{
            return {
               active:pState.active+1 
            }
        });
    }
    onDown=()=>{
        if(this.state.active===0)return;
        this.setState((pState)=>{
            return {
               active:pState.active-1 
            }
        });
    }
    renderView=()=>{
        if(this.state.state===0){
            return (<React.Fragment>
                <span className="blink pump-big-font">{this.state.active}</span>
                <span><sub>hr</sub></span>
            </React.Fragment>)
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        );
    }
}
export default PumpSetActiveInsTime;
