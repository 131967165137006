import React, { useEffect } from "react";
import "./Pump.css";
import AppController from "./../../utils/AppController";
import PumpLCD from "./PumpLCD";
import pumpImg from "./pump.png";
import EventManager from "../../utils/EventManager";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClose, faX } from "@fortawesome/free-solid-svg-icons";
import { is_iOS } from "../../utils/constants";

export const Pump = () => {
  useEffect(() => {
    AppController.issueCommand("#show.Pump");
    AppController.issueCommand("#cam.host");
    AppController.issueCommand("#rotate.avatar.350");
    return () => {
      AppController.issueCommand("#hide.Pump");
      AppController.issueCommand("#cam.wide");
    };
  }, []);
  const onButton = (e) => {
    switch (e.target.id) {
      case "button-esc":
        EventManager.fire("#button.pump.esc");
        break;
      case "button-act":
        EventManager.fire("#button.pump.act");
        break;
      case "button-up":
        EventManager.fire("#button.pump.up");
        break;
      case "button-down":
        EventManager.fire("#button.pump.down");
        break;
    }
  };

  useEffect(() => {
    const handleTouchStart = (e) => {
      const buttonId = e.target.id;
      if (buttonId.includes("button-")) {
        onButton(e);
      }
    };

    document.addEventListener("touchstart", handleTouchStart);
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <Draggable
      // axis="x"
      // handle=".handle"
      defaultPosition={{
        x: 300,
        y: window.innerHeight - 350,
      }}
      // position={null}
      // grid={[25, 25]}
      // scale={0.5}
      // onStart={this.handleStart}
      // onDrag={this.handleDrag}
      // onStop={this.handleStop}
    >
      <div id="pump-view" className="">
        <FontAwesomeIcon
          className="position-absolute"
          style={{
            top: 10,
            right: 20,
            color: "white",
          }}
          icon={faClose}
          cursor={"pointer"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            AppController.app.setState({ pumpOpen: false });
          }}
        />
        {/* <div
          className="position-absolute"
          onClick={() => {
            AppController.issueCommand("#hide.Pump");
          }}
          style={{ cursor: "pointer", right: 30, top: 10 }}
        >
          <FontAwesomeIcon icon={faCircleXmark} scale="2x" color="white" />
        </div> */}
        <img style={{ pointerEvents: "none" }} src={pumpImg} />
        <PumpLCD />
        <button onClick={(e) => onButton(e)} id="button-esc">
          ESC
        </button>
        <button onClick={(e) => onButton(e)} id="button-act">
          ACT
        </button>
        <button onClick={(e) => onButton(e)} id="button-up">
          UP
        </button>
        <button onClick={(e) => onButton(e)} id="button-down">
          DOWN
        </button>
      </div>
    </Draggable>
  );
};
