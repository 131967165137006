import React, { memo } from "react";
import * as R from "ramda";
import { Handle } from "react-flow-renderer";
import Node, { contentStyle as style } from "./Node";

const isValidInput = (connection, type) => {
  //return true;
  console.log("ovalid:", connection, ":", type);
  return R.last(R.split("__", connection.source)) === type;
};
const isValidOutput = (connection, type) => {
  //return true;
  console.log("ivalid:", connection, ":", type);
  return R.last(R.split("__", connection.target)) === type;
};

const FunctionNode = ({ data, selected }) => {
  return (
    <Node
      label={data.name}
      selected={selected}
      color={"Lavender"}
      content={
        <>
          <div style={style.io}>
            {"Start"}
            <Handle
              type="target"
              position="left"
              id="i-data"
              style={{ ...style.handle, ...style.left }}
            />
          </div>
          <div style={style.contentHeader}>{"Very High"}</div>
          {data["Very High"].map((veryhigh) => (
            <div
              key={"o-" + veryhigh.type}
              style={{ ...style.io, ...style.textRight }}
            >
              {veryhigh.type}
              {console.log(
                "o-" + veryhigh.label + "_VeryHigh__" + veryhigh.type
              )}
              <Handle
                type="source"
                position="right"
                id={"o-" + veryhigh.label + "_VeryHigh__" + veryhigh.type}
                // id={"o-Very High__" + veryhigh.label}
                style={{ ...style.handle, ...style.right }}
                isValidConnection={(connection) =>
                  isValidInput(connection, veryhigh.type)
                }
              />
            </div>
          ))}
          <div style={style.contentHeader}>{"High"}</div>
          {data["High"].map((high) => (
            <div
              key={"o-" + high.type}
              style={{ ...style.io, ...style.textRight }}
            >
              {high.type}
              <Handle
                type="source"
                position="right"
                id={"o-" + high.label + "_High__" + high.type}
                // id={"o-High__" + high.label}
                style={{ ...style.handle, ...style.right }}
                isValidConnection={(connection) =>
                  isValidOutput(connection, high.type)
                }
              />
            </div>
          ))}
          <div style={style.contentHeader}>{"Nominal"}</div>
          {data["Nominal"].map((nominal) => (
            <div
              key={"o-" + nominal.type}
              style={{ ...style.io, ...style.textRight }}
            >
              {nominal.type}
              <Handle
                type="source"
                position="right"
                id={"o-" + nominal.label + "_Nominal__" + nominal.type}
                // id={"o-Nominal__" + nominal.label}
                style={{ ...style.handle, ...style.right }}
                isValidConnection={(connection) =>
                  isValidOutput(connection, nominal.type)
                }
              />
            </div>
          ))}
          <div style={style.contentHeader}>{"Low"}</div>
          {data["Low"].map((low) => (
            <div
              key={"o-" + low.type}
              style={{ ...style.io, ...style.textRight }}
            >
              {low.type}
              <Handle
                type="source"
                position="right"
                id={"o-" + low.label + "_Low__" + low.type}
                // id={"o-Low__" + low.label}
                style={{ ...style.handle, ...style.right }}
                isValidConnection={(connection) =>
                  isValidOutput(connection, low.type)
                }
              />
            </div>
          ))}
          <div style={style.contentHeader}>{"Very Low"}</div>
          {data["Very Low"].map((verylow) => (
            <div
              key={"o-" + verylow.type}
              style={{ ...style.io, ...style.textRight }}
            >
              {verylow.type}
              <Handle
                type="source"
                position="right"
                id={"o-" + verylow.label + "_VeryLow__" + verylow.type}
                style={{ ...style.handle, ...style.right }}
                isValidConnection={(connection) =>
                  isValidOutput(connection, verylow.type)
                }
              />
            </div>
          ))}
        </>
      }
    />
  );
};

export default memo(FunctionNode);
