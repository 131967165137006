import {
  Box,
  DialogContentText,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { glossaryPut } from "../../utils/redux/features/glossary";
import { uiIsAddEditGlossaryOpen } from "../../utils/redux/features/ui";

export default () => {
  const dispatch = useDispatch();
  const itemDetails = useSelector((state) => state.ui.isAddEditGlossaryOpen);
  const isNew = itemDetails === "new";
  const [originalTitle] = useState(itemDetails.title || "");
  const [title, setTitle] = useState(itemDetails.title || "");
  const [description, setDescription] = useState(itemDetails.Definition || "");

  const onSave = () => {
    if (!title || !description) {
      toast.warn("value must not be empty");
      return;
    }

    dispatch(glossaryPut({ originalTitle, title, Definition: description }));
    setTimeout(() => {
      dispatch(uiIsAddEditGlossaryOpen(false));
    }, 100);
    if (isNew) {
      toast.success("new term added");
    } else {
      toast.success("term updated");
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => dispatch(uiIsAddEditGlossaryOpen(false))}
    >
      <DialogTitle>{isNew ? "Add New Term" : "Edit Term"}</DialogTitle>
      <DialogContent style={{ width: 440 }}>
        <TextField
          margin="dense"
          type="text"
          placeholder="Title"
          fullWidth
          variant="standard"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <DialogContentText>
          <TextareaAutosize
            aria-label="Definition"
            placeholder="Definition"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border-0 w-100"
            minRows={10}
          />
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => onSave()}>Save</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
