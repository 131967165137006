import React from "react";
import ReactDOM from "react-dom";
import "./NutritionFacts.css";
import TweenMax, { Expo } from "gsap";
export default class NutritionFacts extends React.Component {
  componentDidMount = () => {
    this.el = ReactDOM.findDOMNode(this);
    TweenMax.to(this.el, 0, { opacity: 0 });
    TweenMax.to(this.el, 0.6, { opacity: 1, ease: Expo.easeOut });
  };
  render() {
    const facts = { ...this.props.data };
    return (
      <React.Fragment>
        <div className="nutrition-facts">
          <div className="nutrition-facts-inner">
            <div className="nutrition-facts-title">Nutrition Facts:</div>
            <div className="nutrition-facts-item-name">{facts.title}</div>
            <br />
            <div className="Serving-Size-1-piece">
              Serving Size 1 piece ({facts.grams}g)
            </div>
            <div className="nutrition-facts-hr-1" />
            <div className="nutrition-facts-text-style-1">
              Amount Per Serving
            </div>
            <div
              className="nutrition-facts-text-style-2"
              style={{ float: "left" }}
            >
              Calories{" "}
              <span className="Calories-from-Fat-50">{facts.calories}</span>
            </div>
            <div className="Calories-from-Fat-50">
              Calories from Fat {facts.caloriesFromFat}
            </div>
            <div className="nutrition-facts-hr-1" style={{ marginTop: 6 }} />
            <div style={{ float: "right" }}>% Daily value</div>
            <div className="nutrition-facts-hr-2" style={{ clear: "both" }} />
            <div
              className="nutrition-facts-text-style-2"
              style={{ float: "left" }}
            >
              Total Carbohydrate {facts.totalCarbs}g
            </div>
            <div className="Calories-from-Fat-50">%{facts.totalCarbsPcent}</div>
            <div className="nutrition-facts-hr-2" style={{ clear: "both" }} />
            <div
              className="nutrition-facts-text-style-2"
              style={{ float: "left" }}
            >
              Dietary Fiber {facts.fiber}g
            </div>
            <div className="Calories-from-Fat-50">%{facts.fiberPcent}</div>
            <div className="nutrition-facts-hr-2" style={{ clear: "both" }} />
            <div
              className="nutrition-facts-text-style-2"
              style={{ float: "left" }}
            >
              Sugars {facts.sugar}g
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
