import { Typography } from "@material-ui/core";
import { Edit, KeyboardArrowLeft } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "chartkick/chart.js";
import gsap from "gsap";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LineChart } from "react-chartkick";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import circleMeterImg from "../../images/circleMeter.png";
import iphoneImg from "../../images/iphone.png";
import {
  COLOR_FAILURE,
  COLOR_NOMINAL,
  COLOR_WARNING,
  GLUCOSE_LEVEL_HIGH,
  GLUCOSE_LEVEL_LOW,
  GLUCOSE_LEVEL_SUPER_HIGH,
  GLUCOSE_LEVEL_SUPER_LOW,
  GLUCOSE_LEVEL_VERY_HIGH,
  GLUCOSE_LEVEL_VERY_LOW,
} from "../../utils/constants";
import CGMSubViewSetHigh from "./CGMSubViewSetHigh";
import CGMSubViewSetLow from "./CGMSubViewSetLow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClose, faX } from "@fortawesome/free-solid-svg-icons";
import AppController from "../../utils/AppController";

const styles = {
  container: {
    height: 700,
    width: 350,
    position: "absolute",
    zIndex: 0,
    left: 100,
    top: 200,
  },
  frame: { pointerEvents: "none", width: 350 },
  circleMeter: {
    layer1: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 150,
      left: 99,
    },
    glucose: {
      zIndex: 1,
    },
    mesurment: {
      zIndex: 1,
    },
    innerCircle: {
      width: 79,
      height: 79,
      top: 35,
      position: "absolute",
      borderRadius: "50%",
    },
  },
};

const CircleMeter = ({ glucose }) => {
  const ref = useRef();
  // const ratio = 180 / 240;
  let rotation = 0; // glucose * ratio;
  // rotation = clamp(rotation, 90, -90);
  let color = COLOR_NOMINAL;

  if (glucose >= GLUCOSE_LEVEL_HIGH) {
    color = COLOR_WARNING;
    rotation = -45;
  }
  if (glucose >= GLUCOSE_LEVEL_VERY_HIGH) {
    color = COLOR_FAILURE;
    rotation = -70;
  }
  if (glucose >= GLUCOSE_LEVEL_SUPER_HIGH) {
    color = COLOR_FAILURE;
    rotation = -90;
  }
  if (glucose <= GLUCOSE_LEVEL_LOW) {
    color = COLOR_WARNING;
    rotation = 45;
  }
  if (glucose <= GLUCOSE_LEVEL_VERY_LOW) {
    color = COLOR_FAILURE;
    rotation = 70;
  }
  if (glucose <= GLUCOSE_LEVEL_SUPER_LOW) {
    color = COLOR_FAILURE;
    rotation = 90;
  }

  useEffect(() => {
    gsap.to(ref.current, { rotation, duration: 1 });
  }, [glucose]);

  return (
    <div style={styles.circleMeter.layer1}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
        }}
      >
        <Typography style={styles.circleMeter.glucose} variant="h4">
          {glucose}
        </Typography>
        <Typography style={styles.circleMeter.mesurment} variant="caption">
          mg/dL
        </Typography>
      </div>
      <img ref={ref} src={circleMeterImg} />
      <div
        style={{ ...styles.circleMeter.innerCircle, background: color }}
      ></div>
    </div>
  );
};

export default ({ glucose }) => {
  // Clamp glucose value
  glucose = Math.min(glucose, GLUCOSE_LEVEL_SUPER_HIGH);
  glucose = Math.max(glucose, GLUCOSE_LEVEL_SUPER_LOW);
  glucose = Math.round(glucose);

  const [dexData, setDexData] = useState({});
  const [isAlertViewOpen, setIsAlertViewOpen] = useState(false);
  const [AlertSubView, setAlertSubView] = useState(false);
  const cgm = useSelector((state) => state.cgm);
  const [highColor, setHighColor] = useState({ top: 43, height: 150 });
  const [nominalColor, setNominalColor] = useState({ top: 43, height: 150 });
  const [lowColor, setLowColor] = useState({ top: 43, height: 150 });
  const highGlucose = useSelector((state) => state.cgm.alertHighGlucose);
  const lowGlucose = useSelector((state) => state.cgm.alertLowGlucose);

  useEffect(() => {
    const data = {
      [moment().subtract(2, "hours").format("LT")]: glucose - 10,
      [moment().subtract(1, "hours").format("LT")]: glucose - 40,
      [moment().format("LT")]: glucose,
    };
    setDexData(data);

    // Update the 3 colored boxes that overlay the canvas
    // Total glucose range
    const glucoseRange = 360; // Does not equate to pixels
    const graphHeight = 150; // height of graph in pixels
    const graphTop = 43;
    const ratio = graphHeight / glucoseRange; // 150 is the total height of the graph area
    // get height of bottom color
    const heightValueInPixels = graphHeight - highGlucose * ratio;
    setHighColor({ top: graphTop, height: heightValueInPixels });

    // get nominal range
    const nominalHeightValueInPixels =
      graphHeight - (highGlucose - lowGlucose) * ratio;
    setNominalColor({
      top: graphTop + heightValueInPixels,
      height: nominalHeightValueInPixels,
    });

    // get low range top / height
    const LowHeightValueInPixels = graphHeight - lowGlucose * ratio;
    setLowColor({
      top: graphTop + heightValueInPixels + nominalHeightValueInPixels,
      height: 60, // LowHeightValueInPixels,
    });
  }, []);

  const makeAlertList = () => {
    return [
      ["Urgen Low", "55 mg/dL"],
      ["Urgen Low Soon", "on"],
      ["Low", `${cgm.alertLowGlucose} mg/dL`, <CGMSubViewSetLow />],
      ["High", `${cgm.alertHighGlucose} mg/dL`, <CGMSubViewSetHigh />],
      ["Rise Rate", "on"],
      ["Fall Rate", "on"],
      ["Signal Loss", "off"],
    ].map(([title, value, subView], key) => {
      return (
        <div
          key={key}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (subView) {
              setAlertSubView(subView);
            }
          }}
        >
          <Typography
            style={{
              ...styles.circleMeter.glucose,
              color: "#404040",
              fontSize: "1.2em",
            }}
            variant="h6"
          >
            {title}
          </Typography>
          <Typography
            style={{ ...styles.circleMeter.mesurment, color: "#8F8F91" }}
            variant="caption"
          >
            {value}
          </Typography>
          <hr />
        </div>
      );
    });
  };

  return (
    <Draggable>
      <div className="d-flex" style={styles.container}>
        <FontAwesomeIcon
          size="2x"
          className="position-absolute"
          style={{
            top: 10,
            right: 20,
            color: "white",
          }}
          icon={faClose}
          cursor={"pointer"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            AppController.app.setState({ cgmOpen: false });
          }}
        />
        <div className="d-flex" style={{ width: 350 }}>
          <img src={iphoneImg} style={styles.frame} />
          <CircleMeter glucose={glucose} />
          <div
            style={{
              position: "absolute",
              bottom: 43,
              padding: 33,
              width: "100%",
            }}
          >
            <LineChart
              min={40}
              max={400}
              stacked={true}
              colors={["#201A1C"]}
              data={dexData}
              height={189}
              discrete={true}
              // xtitle="Time"
              // ytitle="glucose"
              curve={true}
              // legend={true}
              // prefix="$"
              // suffix="-"
              precision={1}
              // round={2}
              // zeros={true}
              library={{ backgroundColor: "#00ff00" }}
            />
            <div
              style={{
                top: highColor.top,
                height: highColor.height,
                left: 67,
                width: 223,
                opacity: 0.2,
                backgroundColor: "rgb(253 227 0)",
                position: "absolute",
              }}
            />
            <div
              style={{
                top: nominalColor.top,
                height: nominalColor.height,
                left: 67,
                width: 223,
                opacity: 0.2,
                backgroundColor: "rgb(119 113 113)",
                position: "absolute",
              }}
            />
            <div
              style={{
                top: lowColor.top,
                height: lowColor.height,
                left: 67,
                width: 223,
                opacity: 0.2,
                backgroundColor: "rgb(232 138 148)",
                position: "absolute",
              }}
            />
          </div>
          <div
            onClick={() => {
              setIsAlertViewOpen(true);
            }}
            style={{
              display: "flex",
              position: "absolute",
              bottom: 31,
              alignItems: "center",
              width: "100%",
              paddingLeft: 45,
              paddingRight: 45,
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MenuBookIcon />
              <div style={{ paddingLeft: 12 }}>Events</div>
            </div>
            <div
              style={{
                marginLeft: 100,
              }}
            >
              <KeyboardArrowRightIcon />
            </div>
          </div>
          {isAlertViewOpen && (
            <div
              style={{
                background: "white",
                borderRadius: 20,
                position: "absolute",
                width: 302,
                height: 620,
                top: 48,
                left: 26,
                zIndex: 1,
              }}
            >
              {/* Back Button */}
              <div
                onClick={() => {
                  setIsAlertViewOpen(false);
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  top: 20,
                  left: 4,
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <div style={{}}>
                    <KeyboardArrowLeft />
                  </div>
                  <Typography
                    style={styles.circleMeter.glucose}
                    variant="button"
                  >
                    back
                  </Typography>
                </div>
              </div>

              <div style={{ padding: 22, display: "felx" }}>
                {makeAlertList()}
              </div>
            </div>
          )}
          {AlertSubView && (
            <div
              style={{
                background: "white",
                borderRadius: 20,
                position: "absolute",
                width: 302,
                height: 620,
                top: 48,
                left: 26,
                zIndex: 1,
              }}
            >
              {/* Back Button */}
              <div
                onClick={() => {
                  setAlertSubView(false);
                }}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  top: 20,
                  left: 4,
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <div style={{}}>
                    <KeyboardArrowLeft />
                  </div>
                  <Typography
                    style={styles.circleMeter.glucose}
                    variant="button"
                  >
                    back
                  </Typography>
                </div>
              </div>
              <div style={{ padding: 22, display: "felx" }}>{AlertSubView}</div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};
