import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Button, Card, CardContent } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const MailToDialog = (props) => {
  const {
    createdAt,
    email: userEmail,
    email_verified,
    id,
    lastActivity,
    sessions,
    updatedAt,
    onClose,
  } = props;

  // const profile = props.profile ? JSON.parse(props.profile) : null;
  // const roles =
  //   profile && profile.roles && profile.roles.length ? profile.roles : null;
  // console.log(props);

  const [scenesToAdd, setScenesToAdd] = useState([]);
  // const [mailto, setMailto] = useState("");
  const [email, setEmail] = useState(userEmail);
  const [subject, setSubject] = useState("A1 Control Update");
  const [body, setBody] = useState("Hello, I'm here to help!");
  const scenes = useSelector((state) => state.scenes.scenes);

  const renderMailTo = () => {
    let _body = body;
    let params = subject || _body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;

    // append scene data to body
    if (scenes.length) {
      _body += `
    ${window.location.origin}?ref=email&startingScene=${
        scenesToAdd.length ? scenesToAdd[0] : "0"
      }`;
    }
    if (_body)
      params += `${subject ? "&" : ""}body=${encodeURIComponent(_body)}`;
    return (
      <Button
        onClick={(e) => {
          window.location = `mailto:${email}${params}`;
          e.preventDefault();
        }}
      >
        Send
      </Button>
    );
  };

  return (
    <Dialog fullWidth open onClose={onClose} disableEnforceFocus>
      <DialogTitle>
        <FontAwesomeIcon icon={faMailBulk} className="mr-2" />
        Send Email
      </DialogTitle>
      <DialogContent>
        {/* TODO show scenes to add to body link */}
        <Card>
          <CardContent>
            <Typography className="mb-2" variant="h6">
              Revise User email
            </Typography>
            <TextField
              fullWidth
              label="User Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography className="mb-2" variant="h6">
              Subject
            </Typography>
            <TextField
              fullWidth
              label="User Email"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <Typography className="mb-2" variant="h6">
              Body
            </Typography>
            <TextField
              fullWidth
              label="User Email"
              variant="outlined"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </CardContent>
          {/* <CardHeader title="Select scenes that the user will see" /> */}
          <CardContent>
            <Typography variant="h6">
              Select Starting Scene For User (optional)
            </Typography>
            <div className="d-flex align-items-start flex-column">
              {scenes.map((scene, key) => {
                const isChecked = scenesToAdd.indexOf(scene.id) > -1;
                return (
                  <FormControlLabel
                    style={{ padding: 0, margin: 0 }}
                    key={key}
                    label={scene.info["Scene Title"]}
                    checked={isChecked}
                    control={
                      <Checkbox
                        // inputProps={{ "aria-label": "foo" }}
                        title={scene.info["Scene Title"]}
                        // checked={isChecked}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const arr = [...scenesToAdd];
                          if (checked) {
                            // add to array
                            arr.push(scene.id);
                          } else {
                            // remove from array
                            const index = arr.findIndex((n) => n === scene.id);
                            arr.splice(index, 1);
                          }
                          setScenesToAdd(arr);
                        }}
                      />
                    }
                  />
                );
              })}
            </div>
          </CardContent>
          {/* <CardActions>
            <Button
              onClick={() => {
                // console.log(scenes.map((n) => n.id));
                setScenesToAdd(scenes.map((n) => n.id));
              }}
            >
              Select All
            </Button>
            <Button
              onClick={() => {
                setScenesToAdd([]);
              }}
            >
              Select None
            </Button>
          </CardActions> */}
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {renderMailTo()}
          {/* <Mailto
            email={email}
            subject={subject}
            body={body}
            scenes={scenesToAdd}
          /> */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Mailto = (props) => {
  let { email, subject = "", body = "", scenes, children } = props;
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;

  // append scene data to body
  if (scenes.length) {
    // =${scenes.join(",")}`;
    body += `</br><div>
    <a href="${window.location.origin}?ref=email&scenes=${scenes.join(
      ","
    )}"></a>
    </div>`;
  }
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
  // console.log(params);
  // return <a href={`mailto:${email}${params}`}>{children}</a>;
  return (
    <Button
      onClick={(e) => {
        window.location.href = `mailto:${email}${params}`;
        e.preventDefault();
      }}
    >
      Send
    </Button>
    // <Link
    //   to="#"
    //   onClick={(e) => {
    //     window.location.href = `mailto:${email}${params}`;
    //     e.preventDefault();
    //   }}
    // >
    //   Send
    // </Link>
  );
};

export default MailToDialog;
