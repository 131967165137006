import { faCog, faFilm } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { scenesGet } from "../../utils/redux/features/scenes";
import { updateUserProfile } from "../../utils/redux/features/userSlice";
import { SCENE_TYPES } from "../../utils/constants";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const UserConfig = (props) => {
  const {
    // createdAt,
    // email_verified,
    id: userId,
    // lastActivity,
    // sessions,
    // updatedAt,
    email,
    onClose,
    profile,
  } = props;

  const dispatch = useDispatch();
  const scenes = useSelector((state) => state.scenes.scenes);
  const [warnOnExit, setWarnOnExit] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(scenesGet());
  }, []);

  useEffect(() => {
    const sceneConfig = get(
      profile,
      "sceneConfig",
      scenes.map((n) => {
        return {
          id: n.id,
          visible: true,
        };
      })
    ).filter((x) => {
      // Filter out intro scenes by id
      const scene = scenes.find((n) => n.id === x.id);
      return !(
        scene.info["Scene Type"] === SCENE_TYPES.INTRODUCTION_RETURN ||
        scene.info["Scene Type"] === SCENE_TYPES.INTRODUCTION
      );
    });
    console.log("initial scene config", sceneConfig);
    setList(sceneConfig);
  }, [scenes]);

  return (
    <Dialog
      fullWidth
      open
      onClose={() => {
        setWarnOnExit(true);
      }}
      disableEnforceFocus
    >
      <DialogTitle>
        <FontAwesomeIcon icon={faCog} className="mr-2" />
        User Config
      </DialogTitle>

      <DialogContent>
        {/* TODO show scenes to add to body link */}
        <Card>
          <CardContent>
            <Typography className="mb-2" variant="h6">
              <div className="d-flex align-items-center justify-content-between">
                <div>Scene Experience</div>
                <sub>{email}</sub>
              </div>
            </Typography>
            <div className="d-flex align-items-center flex-column w-100">
              <ReactSortable
                list={list.map((n) => ({ ...n, chosen: true }))}
                setList={setList}
                className="d-flex w-100 flex-column"
                style={{ gap: 4 }}
              >
                {list.map((n, key) => {
                  const { id, visible = true } = n;
                  const scene = scenes.find((s) => s.id === id);
                  return (
                    <Paper
                      key={id}
                      className="d-flex p-2 align-items-center justify-content-between"
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: 4 }}
                      >
                        <FontAwesomeIcon icon={faFilm} />
                        <div className="d-flex">
                          {scene.info["Scene Title"]}
                        </div>
                      </div>
                      <FormControlLabel
                        style={{ padding: 0, margin: 0 }}
                        label="Visible"
                        checked={visible}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const arr = list.map((n) => {
                            if (n.id === id) {
                              return { ...n, visible: checked };
                            }
                            return n;
                          });
                          setList(arr);
                        }}
                        control={<Checkbox title="Is this scenario visible" />}
                      />
                    </Paper>
                  );
                })}
              </ReactSortable>
            </div>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              onClick={() => {
                dispatch(
                  updateUserProfile({
                    id: userId,
                    key: "sceneConfig",
                    value: list,
                  })
                );
              }}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
      <Dialog open={warnOnExit} onClose={() => setWarnOnExit(null)}>
        <DialogTitle>Exit without saving?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setWarnOnExit(null);
              onClose();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setWarnOnExit(null);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(
                updateUserProfile({
                  id: userId,
                  key: "sceneConfig",
                  value: list,
                })
              );
              setWarnOnExit(null);
              onClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default UserConfig;
