import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { sceneCreateState } from "../../utils/redux/features/scenes";

export default ({ isOpen, onClose }) => {
  const [stateName, setStateName] = useState("");
  const dispatch = useDispatch();
  const onSave = () => {
    if (!stateName.length) {
      toast.warn("new state name must not be empty");
      return;
    }
    dispatch(sceneCreateState(stateName));
    onClose();
    toast.success(`new state ${stateName} created!`);
  };
  return (
    <Dialog maxWidth={false} open={isOpen} onClose={onClose}>
      <DialogTitle>Create State</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This function allows you to create a new state. You only need to
          provide the state name. You'll be able to edit this state later.
        </DialogContentText>
        <TextField
          margin="dense"
          label="State Name"
          type="text"
          fullWidth
          variant="standard"
          value={stateName}
          onChange={(e) => setStateName(e.target.value)}
        />
        <DialogActions>
          <div>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <Button onClick={onSave}>Save</Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
