import { Amplify } from "@aws-amplify/core";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  faBook,
  faClapperboard,
  faFile,
  faHandPointUp,
  faHeartPulse,
  faMobileScreen,
  faPager,
  faPlay,
  faPumpMedical,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { Button, Typography } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import "typeface-roboto";
import "./App.css";
import awsconfig from "./aws-exports";
import CGMButtonSrc from "./images/cgmBtn.png";
import PumpBtn from "./images/pumpBtn.png";
import ScenesButtonSrc from "./images/scenarios-button.png";
import TermsButtonSrc from "./images/terms-button.png";
import AppController from "./utils/AppController";
import EventManager from "./utils/EventManager";
import ModelController from "./utils/ModelController";
import Snackbar from "./utils/Snackbar";
import { playSilentAudio } from "./utils/constants";
import { store } from "./utils/redux/configureStore";
import { scenesGet } from "./utils/redux/features/scenes";
import {
  uiIsAdminControlsOpen,
  uiIsGlossaryOpen,
  uiIsIOSModalOpen,
  uiIsSceneEditorOpen,
} from "./utils/redux/features/ui";
import AdminControls from "./views/AdminControls";
import ChatBubble from "./views/ChatBubble";
import { Clock } from "./views/Clock";
import ImageViewer from "./views/ImageViewer";
import LoadingView from "./views/LoadingView";
import { Meter } from "./views/Meter";
import NutritionFacts from "./views/NutritionFacts";
import QuickFader from "./views/QuickFader";
import ResultsView from "./views/ResultsView";
import SceneFlowView from "./views/SceneFlow/SceneFlowView";
import SceneSelect from "./views/SceneSelect";
import Terms from "./views/Terms";
import TimeIncrementFader from "./views/TimeIncrementFader";
import { TitleBanner } from "./views/TitleBanner";
import UtilView from "./views/UtilView";
import AddEditGlossaryTerm from "./views/admin/AddEditGlossaryTerm";
import Cgm from "./views/cgm/cgm";
import { Pump } from "./views/pump/Pump";
import { SceneEditor } from "./views/sceneEditor/SceneEditor";
import {
  getOrCreateUser,
  saveUserSession,
} from "./utils/redux/features/userSlice";
import Hoverable from "./Hoverable";
import { motion } from "framer-motion";

Amplify.configure(awsconfig);

window.ac = AppController;
window.em = EventManager;
class App extends Component {
  state = {
    // isAdmin: true,
    isFlowRendererOpen: false,
    time: "8:00",
    sceneSelect: {
      visible: false,
    },
    resultsView: {
      visible: false,
      title: "MiSugartime",
      points: 3,
      hasNextScene: false,
    },
    nutritionFacts: {
      visible: false,
      title: "Big Mac",
      grams: 216,
      calories: 590,
      caloriesFromFat: 306,
      totalCarbs: 47,
      totalCarbsPcent: 16,
      fiber: 3,
      fiberPcent: 12,
      sugar: 8,
    },
    titleBannerVisible: true,
    scenesButtonVisible: true,
    pumpViewPath: [],
    pumpButtonsAllowed: "all",
    pumpOpen: false,
    adminPanelOpen: false,
    basalUnits: 0.5, // basal units per hour to be delived
    utilViewOpen: false,
    foodBarOpen: false,
    sleepBarOpen: false,
    waitBarOpen: false,
    exerciseBarOpen: false,
    meterOpen: false,
    cgmOpen: false,
    actionButtons: [],
    targetBg: {
      low: 70,
      high: 120,
    },
    sensitivity: 50,
    carbRatio: 15,
    sensorData: {},
    glucose: 80,
    score: 2, // remove this in favor of resultsView.points
    activeInsulinTime: 4, // check java code to see if it's hrs or mins unit
    date: new Date(),
    tempBasalDuration: 60,
    tempBasalPcent: 100,
  };
  constructor(props) {
    const { user, signOut } = props;
    AppController.user = user;
    AppController.signOut = signOut;
    window.signOut = signOut;
    // console.log("user", user);
    // TODO - check if logged in user is an admin or Eileen.
    // TODO - look for null on profile
    // const roles = user.attributes.roles.split(",").map((n) => n.trim());

    super(props);
    AppController.app = this;
    AppController.appDefaultState = { ...this.state };
  }
  componentDidMount() {
    // Get or create dynamo user
    store.dispatch(getOrCreateUser(this.props.user));
    store.dispatch(scenesGet());
    // load babylon
    this.modelController = window.modelController = new ModelController(() => {
      // EventManager.fire("#sumerian.ready");
      EventManager.fire("#babylon.loaded");
    });

    window.onkeyup = (e) => {
      let key = e.keyCode ? e.keyCode : e.which;
      // console.log(key);
      if (key === 13) {
        // console.log("removed ")
        // this.setState((prevState) => {
        //   return {
        //     utilViewOpen: !prevState.utilViewOpen,
        //   };
        // });
      } else if (key === 80) {
        // p key for toggeling pump
        // this.setState({ pumpOpen: !this.state.pumpOpen });
      } else if (key === 65) {
        // a key for API
      } else if (key === 66) {
        // b key for API
      } else if (key === 67) {
        // c key for API
      } else if (key === 68) {
        // d key for API
      }
    };
  }
  onFoodBarItemOver = (data) => {
    this.setState({ nutritionFacts: data });
    this.forceUpdate();
  };
  onFoodBarItemOut = () => {
    this.setState({ nutritionFacts: { visible: false } });
    this.forceUpdate();
  };
  renderFoodBar = () => {
    //let food = ['Cheese Stick',1,'Jelly Beans',16,'Big Mac',44,'Snickers',32];
    let items = [];
    let food = [
      {
        title: "Cheese Stick",
        visible: true,
        grams: 28,
        calories: 80,
        caloriesFromFat: 50,
        totalCarbs: 0.1,
        totalCarbsPcent: 0,
        fiber: 0,
        fiberPcent: 0,
        sugar: 0,
      },
      {
        title: "Jelly Beans",
        visible: true,
        grams: 10,
        calories: 36,
        caloriesFromFat: 0,
        totalCarbs: 9.5,
        totalCarbsPcent: 3,
        fiber: 0,
        fiberPcent: 0,
        sugar: 7.4,
      },
      {
        title: "Big Mac",
        visible: true,
        grams: 216,
        calories: 590,
        caloriesFromFat: 306,
        totalCarbs: 47,
        totalCarbsPcent: 16,
        fiber: 3,
        fiberPcent: 12,
        sugar: 8,
      },
      {
        title: "Snickers",
        visible: true,
        grams: 57,
        calories: 271,
        caloriesFromFat: 122,
        totalCarbs: 35,
        totalCarbsPcent: 12,
        fiber: 1,
        fiberPcent: 5,
        sugar: 29,
      },
    ];
    for (let i = 0; i < food.length; i++) {
      let item = food[i];
      items.push(
        <div
          key={item.title}
          onMouseOut={() => {
            this.onFoodBarItemOut();
          }}
          onMouseOver={() => {
            this.onFoodBarItemOver(item);
          }}
          onClick={() => EventManager.fire("#action.eat", item.grams)}
          className="action-button"
        >
          <div>{item.title}</div>
        </div>
      );
    }
    return <div className="food-bar">{items}</div>;
  };
  renderSleepBar = () => {
    let mins = [15, 60, 8 * 60];
    let items = [];
    let i = 0;
    for (; i < mins.length; i++) {
      let sleepTime = mins[i];
      let postfix = sleepTime < 60 ? "mins" : sleepTime === 60 ? "hr" : "hrs";
      let sleepTimeInMins = sleepTime;
      if (sleepTime > 59) {
        sleepTime = sleepTime / 60;
      }
      sleepTime = sleepTime.toString() + " " + postfix;
      items.push(
        <div
          key={sleepTime}
          onClick={() => EventManager.fire("#action.sleep", sleepTimeInMins)}
          className="action-button"
        >
          <div>{sleepTime}</div>
        </div>
      );
    }
    return <div className="food-bar">{items}</div>;
  };
  renderWaitBar = () => {
    let mins = [15, 30, 60, 4 * 60];
    let items = [];
    let i = 0;
    for (; i < mins.length; i++) {
      let waitTime = mins[i];
      let postfix = waitTime < 60 ? "mins" : waitTime === 60 ? "hr" : "hrs";
      let waitTimeInMins = waitTime;
      if (waitTime > 59) {
        waitTime = waitTime / 60;
      }
      waitTime = waitTime.toString() + " " + postfix;
      items.push(
        <div
          key={waitTime}
          onClick={() => EventManager.fire("#action.wait", waitTimeInMins)}
          className="action-button"
        >
          <div>{waitTime}</div>
        </div>
      );
    }
    return <div className="food-bar">{items}</div>;
  };
  renderExerciseBar = () => {
    let mins = ["Light", "Medium", "Heavy"];
    let times = [5, 15, 30];
    let items = [];
    let i = 0;
    for (; i < mins.length; i++) {
      let exerciseTime = mins[i];
      let time = times[i];
      items.push(
        <div
          key={exerciseTime}
          onClick={() => EventManager.fire("#action.exercise", time)}
          className="action-button"
        >
          <div>{exerciseTime}</div>
        </div>
      );
    }
    return <div className="food-bar">{items}</div>;
  };
  renderActionBar = () => {
    let btns = this.state.actionButtons;
    let items = [];
    let i = 0;
    for (; i < btns.length; i++) {
      let btn = btns[i];
      let evt = "#action." + btn;
      items.push(
        <motion.div
          transition={{ delay: 0.1 * i, ease: "easeOut" }}
          initial={{ opacity: 0, y: -200, scale: 1.4 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          key={i}
        >
          <button
            style={{ width: 200 }}
            onClick={() => EventManager.fire(evt)}
            className="btn"
          >
            <i className="animation"></i>
            {AppController.capitalizeFirstLetter(btn)}
            <i className="animation"></i>{" "}
          </button>
        </motion.div>
      );
    }
    return <div className="action-bar">{items}</div>;
  };
  componentDidUpdate = (prevProps, prevState) => {
    for (let k in prevState) {
      //console.log("did " + k + ": " + prevState[k] + " : " +this.state[k]);
      if (prevState[k] !== this.state[k]) {
        EventManager.fire("#state." + k, this.state[k]);
      }
    }
  };
  renderPumpButtons = () => {
    return (
      <React.Fragment>
        <div style={{ position: "absolute", left: "50%" }}>
          <div className="food-bar" style={{ width: 130 * 4, marginTop: -100 }}>
            <div
              onClick={() => EventManager.fire("#button.pump.up")}
              className="action-button"
            >
              <div>up</div>
            </div>
            <div
              onClick={() => EventManager.fire("#button.pump.down")}
              className="action-button"
            >
              <div>down</div>
            </div>
            <div
              onClick={() => EventManager.fire("#button.pump.act")}
              className="action-button"
            >
              <div>act</div>
            </div>
            <div
              onClick={() => EventManager.fire("#button.pump.esc")}
              className="action-button"
            >
              <div>esc</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <div id="sumerian-app" /> */}
        <div id="app-title">MiSugartime</div>
        <div id="left-side-bar" className="d-flex flex-column">
          {/* <IconButton
            onClick={() => EventManager.fire("#screen.help")}
            id="help-button"
            color="primary"
          >
            <img alt="alt" src={HelpButtonSrc} />
          </IconButton> */}
          {/* <Hoverable message="Gossary"> */}
          <motion.div
            title="glossary"
            className="circled d-flex align-items-center flex-column"
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            whileTap={{ scale: 0.8 }}
            onClick={() => {
              EventManager.fire("#screen.terms");
              store.dispatch(uiIsGlossaryOpen(!this.props.ui.isGlossaryOpen));
            }}
          >
            <FontAwesomeIcon
              icon={faBook}
              // id="terms-button"
              className="side-bar-icon"
            />
            <Typography color="white" fontSize="0.5rem">
              glossary
            </Typography>
          </motion.div>
          {/* </Hoverable> */}
          {/* <Hoverable message="Scenarios"> */}
          <motion.div
            title="scenarios"
            className="circled d-flex align-items-center flex-column"
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            whileTap={{ scale: 0.8 }}
            onClick={() => EventManager.fire("#screen.scenes")}
          >
            <FontAwesomeIcon
              icon={faClapperboard}
              // id="terms-button"
              className="side-bar-icon"
            />
            <Typography color="white" fontSize="0.5rem">
              scenarios
            </Typography>
          </motion.div>
          {/* </Hoverable> */}
          {/* )} */}
          {/* <Hoverable message="CGM"> */}
          <motion.div
            title="cgm"
            className="circled d-flex align-items-center flex-column"
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            whileTap={{ scale: 0.8 }}
            onClick={() => this.setState({ cgmOpen: !this.state.cgmOpen })}
          >
            <FontAwesomeIcon
              icon={faMobileScreen}
              // id="terms-button"
              className="side-bar-icon"
            />
            <Typography color="white" fontSize="0.5rem">
              cgm
            </Typography>
          </motion.div>
          {/* </Hoverable> */}
          {/* <Hoverable message="Pump"> */}
          <motion.div
            title="pump"
            className="circled d-flex align-items-center flex-column"
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            whileTap={{ scale: 0.8 }}
            onClick={() => this.setState({ pumpOpen: !this.state.pumpOpen })}
          >
            <FontAwesomeIcon
              icon={faPager}
              // id="terms-button"
              className="side-bar-icon"
            />
            <Typography color="white" fontSize="0.5rem">
              pump
            </Typography>
          </motion.div>
          {/* </Hoverable> */}
          {AppController.isAdmin && (
            // <Hoverable message="Admin">
            <motion.div
              title="admin"
              className="circled d-flex align-items-center flex-column"
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2, ease: "easeOut" },
              }}
              whileTap={{ scale: 0.8 }}
              onClick={() => {
                store.dispatch(
                  uiIsAdminControlsOpen(!this.props.ui.isAdminControlsOpen)
                );
              }}
            >
              <FontAwesomeIcon
                icon={faUserShield}
                // id="terms-button"
                className="side-bar-icon"
              />
              <Typography color="white" fontSize="0.5rem">
                admin
              </Typography>
            </motion.div>
            // </Hoverable>
          )}

          {/* <AdminPanelSettings
            onClick={() =>
              this.setState({ adminPanelOpen: !this.state.adminPanelOpen })
            }
            id="adminPanel-button"
            color="primary"
          ></AdminPanelSettings> */}
        </div>
        <Clock time={this.state.time} />
        {AppController.quickTest &&
          this.state.pumpOpen &&
          this.renderPumpButtons()}

        {/* Scene Editor */}

        {this.props.ui.isSceneEditorOpen && (
          <SceneEditor
            open={true}
            onCancelSceneEditor={() => this.props.uiIsSceneEditorOpen(false)} // store.dispatch(sceneEditingNullOut())}
            data={this.props.editingScene}
          />
        )}
        <ChatBubble />
        {this.state.actionButtons.length > 0 && this.renderActionBar()}
        {this.state.foodBarOpen && this.renderFoodBar()}
        {this.state.sleepBarOpen && this.renderSleepBar()}
        {this.state.waitBarOpen && this.renderWaitBar()}
        {this.state.exerciseBarOpen && this.renderExerciseBar()}
        {this.state.pumpOpen && <Pump />}
        {this.state.sceneSelect.visible && (
          <SceneSelect data={this.state.sceneSelect} />
        )}
        {this.state.meterOpen && <Meter />}
        {this.state.cgmOpen && <Cgm glucose={this.state.glucose} />}
        {this.state.titleBannerVisible && AppController.scene && (
          <TitleBanner title={AppController.scene.data["Scene Title"]} />
        )}

        {this.props.ui.isGlossaryOpen && <Terms />}
        {this.props.ui.isAddEditGlossaryOpen && <AddEditGlossaryTerm />}

        {this.props.ui.images.length > 0 && (
          <ImageViewer images={this.props.ui.images} />
        )}

        {this.state.nutritionFacts.visible && (
          <NutritionFacts data={this.state.nutritionFacts} />
        )}

        <TimeIncrementFader />
        <QuickFader />
        {this.state.resultsView.visible && (
          <ResultsView data={this.state.resultsView} />
        )}
        <Snackbar />
        <div>
          {AppController.isAdmin && this.props.ui.isAdminControlsOpen && (
            <AdminControls />
          )}
        </div>
        <LoadingView />
        {this.state.utilViewOpen && <UtilView />}
        {/* {this.state.adminPanelOpen && <AdminPanel />} */}
        {!!this.state.isFlowRendererOpen && (
          <div>
            <SceneFlowView scene={this.state.isFlowRendererOpen} />
            <Button
              style={{ zIndex: 2, color: "white" }}
              onClick={() => {
                AppController.app.setState({ isFlowRendererOpen: null });
              }}
            >
              Close
            </Button>
          </div>
        )}
        {this.props.ui.isIOSModalOpen && (
          // Display full screen div
          <div
            className="w-100 h-100 align-items-center justify-content-center d-flex"
            onClick={() => {
              // playSilentAudio();
              const { isHost, message, host, patient } =
                window.__modelControllerParams;
              if (!isHost) {
                patient.TextToSpeechFeature.play(message);
              } else {
                host.TextToSpeechFeature.play(message);
              }
              store.dispatch(uiIsIOSModalOpen(false));
            }}
          >
            <div
              className="w-100 h-100 position-absolute"
              style={{ backgroundColor: "#00000075", top: 0, left: 0 }}
            />
            <FontAwesomeIcon
              style={{ color: "white" }}
              size="4x"
              className="d-flex position-absolute"
              icon={faHandPointUp}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { ui: state.ui, editingScene: state.scenes.editingScene };
};
const mapDispatchToProps = () => {
  return { uiIsSceneEditorOpen };
};
export default withAuthenticator(
  connect(mapStateToProps, mapDispatchToProps())(App)
);
