import React from 'react';
import './ChatBubble.css';
import TweenMax from 'gsap';
import ReactDOM from 'react-dom';
import EventManager from './../utils/EventManager';
export default class ChatBubble extends React.Component{
	state = {
		dialog:''
	}
	componentDidMount(){
		this.el = ReactDOM.findDOMNode(this);
		TweenMax.to(this.el,0,{opacity:0});
		EventManager.listen("#chat.show",this.onChatEvent);
        EventManager.listen("#chat.hide",this.onHideChat);
	}
	onChatEvent=(e)=>{
		TweenMax.to(this.el,0.5,{opacity:1});
		this.setState(()=>{
			return {
				dialog:e.detail.data
			}
		});
	}
    onHideChat=()=>{
		this.setState(()=>{
			return {
				dialog:''
			}
		});
        TweenMax.to(this.el,0.5,{opacity:0});			
    }
	render(){
		return (
			<div className='speech-bubble'>
				{<p>{this.state.dialog}</p>}
			</div>
		);
	}
}
