import { Engine } from "@babylonjs/core/Engines/engine";
import { Color3, Vector3, Angle } from "@babylonjs/core/Maths/math";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { HemisphericLight } from "@babylonjs/core";
import * as BABYLON from "@babylonjs/core";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import "@babylonjs/core/Helpers/sceneHelpers";
import "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent";
import { Color4 } from "@babylonjs/core/Maths/math.color";

/**
 * This function is the entry point for running a demo. It handles all Babylon
 * engine setup and instantiates a Babylon scene by calling the user-defined
 * `createScene` function argument.
 *
 * @param {function} createScene A function that returns a Babylon scene object
 */
async function loadDemo(createScene) {
  const canvas = document.getElementById("renderCanvas");
  const engine = new Engine(canvas, true, undefined, true);
  const scene = await createScene(canvas);
  scene.render();
  engine.runRenderLoop(() => scene.render());
  window.addEventListener("resize", () => engine.resize());

  // Reveal the loaded scene.
  // document.getElementById("mainScreen").classList.remove("loading");
}

/**
 * Creates a base scene by adding environment elements, lights, and a camera
 * to the provided scene object.
 *
 * @param {BABYLON.Scene} scene An empty scene object to which elements will be
 * added.
 * @returns {object} An object with the shape:
 ```
 {
   scene: BABYLON.Scene,
   shadowGenerator: BABYLON.ShadowGenerator
 }
 */
function setupSceneEnvironment(scene) {
  // Create a simple environment.
  const environmentHelper = scene.createDefaultEnvironment({
    createSkybox: false,
    groundOpacity: 0,
    groundShadowLevel: 0.0,
  });
  // environmentHelper.ground.dispose();
  // environmentHelper.setMainColor(Color3.FromHexString("#00ff0000"));

  scene.environmentIntensity = 1.2;

  // transparent background
  // scene.clearColor = Color4.FromArray([0, 0, 0, 0]);

  const shadowLight = new DirectionalLight(
    "shadowLight",
    new Vector3(0.8, -2, -1),
    scene
  );
  shadowLight.diffuse = new Color3(1, 0.9, 0.62);
  shadowLight.intensity = 1;

  const keyLight = new DirectionalLight(
    "keyLight",
    new Vector3(0.3, -1, -2),
    scene
  );
  keyLight.diffuse = new Color3(1, 0.9, 0.65);
  keyLight.intensity = 0.5;

  var light = new HemisphericLight("Hemi0", new Vector3(-1, 1, 0), scene);
  // light0.diffuse = new Color3(1, 1, 1);
  // light0.specular = new Color3(1, 1, 1);
  // light0.groundColor = new Color3(1, 1, 1);
  light.diffuse = new BABYLON.Color3(1, 1, 1);
  light.specular = new BABYLON.Color3(0.72, 0.69, 0.69);
  light.groundColor = new BABYLON.Color3(1, 1, 1);

  // Add a camera.
  const cameraRotation = Angle.FromDegrees(90).radians();
  const cameraPitch = Angle.FromDegrees(90).radians();
  const camera = new ArcRotateCamera(
    "camera",
    cameraRotation,
    cameraPitch,
    2.6,
    new Vector3(0, 1.0, 0)
  );
  camera.wheelDeltaPercentage = 0.01;
  camera.minZ = 0.01;

  // Initialize user control of camera.
  const canvas = scene.getEngine().getRenderingCanvas();
  // camera.attachControl(canvas, true);
  camera.attachControl(canvas, true, true);

  // const shadowGenerator = new ShadowGenerator(2048, shadowLight);
  // shadowGenerator.useBlurExponentialShadowMap = true;
  // shadowGenerator.blurKernel = 8;
  // scene.meshes.forEach((mesh) => {
  //   shadowGenerator.addShadowCaster(mesh);
  // });
  return { scene };
}

export default {
  loadDemo,
  setupSceneEnvironment,
};
