import { API } from "aws-amplify";
import { API_NAME } from "../../constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { set } from "lodash";

export const getUsers = createAsyncThunk("get/users", async () => {
  const res = await API.get(API_NAME, "/users");
  return res;
});

export const getOrCreateUser = createAsyncThunk(
  "get/user",
  async (cognitoUser) => {
    const { attributes } = cognitoUser;
    const res = await API.post(API_NAME, "/users", {
      body: { ...attributes },
    });
    console.log(res);
    return res.Item;
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/update/profile",
  async ({ key, value, id = null }, thunkApi) => {
    if (!id) {
      id = thunkApi.getState().userSlice.user.id;
    }
    toast.info("updating user profile");
    await API.put(API_NAME, "/users/update/profile", {
      body: { id, key, value },
    });
    return { id, key, value };
  }
);

export const saveUserSession = createAsyncThunk(
  "user/session/save",
  async (session, thunkApi) => {
    const id = thunkApi.getState().userSlice.user.id;
    const res = await API.put(API_NAME, "/users/session/save", {
      body: { id, session },
    });
    console.log(res);
    return res;
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    users: [],
    user: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getOrCreateUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const { id, key, value } = action.payload;
      if (id === state.user.id) {
        set(state.user.profile, key, value);
      } else {
        state.users = state.users.map((user) => {
          if (user.id === id) {
            set(user.profile, key, value);
          }
          return user;
        });
      }
    });
  },
});

export default userSlice.reducer;
