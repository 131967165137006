import React, { useState } from "react";
import "./SceneSelectMenuItem.css";
import starImgSrc from "./../images/big-star.png";
import starEmptyImgSrc from "./../images/big-empty-star.png";
import lockIcon from "./../images/lock-icon.svg";
import em from "../utils/EventManager";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AppController from "../utils/AppController";
import { Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClapperboard,
  faEdit,
  faLock,
  faPencil,
  faPlay,
  faPlayCircle,
  faStar,
  faStarHalfStroke,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Button, Typography } from "@material-ui/core";
import { toast } from "react-toastify";

const iconList = [
  require("../images/clock.png"),
  require("../images/host.png"),
  require("../images/guy.png"),
  require("../images/pump.png"),
  require("../images/phone.png"),
];

export default (props) => {
  const { user, data } = props;
  const scene = data.info;
  const { profile } = user;
  const randomIndex = Math.ceil(Math.random() * iconList.length - 1);
  const [randomIcon, _] = useState(iconList[randomIndex]);

  const sceneDescription = scene["Scene Description"];
  const sceneTitle = scene["Scene Title"];

  const onClickedItem = (data) => {
    if (props.locked) {
      em.fire("#alert", sceneTitle + " is locked");
      return;
    }
    props.onPlayButtonClick(props.data);
  };

  const renderStars = () => {
    const classes = { ...props };
    let items = [];
    for (let i = 0; i < 3; i++) {
      let unlocked = classes.points > i;
      if (unlocked) {
        items.push(<FontAwesomeIcon color="#94fe52" key={i} icon={faStar} />);
      } else {
        items.push(<FontAwesomeIcon color="gray" key={i} icon={faStar} />);
      }
    }
    return <div className="d-flex align-items-center">{items}</div>;
  };
  // const locked = props.locked;
  // const visible = data.visible;
  const locked = !data.visible;
  // console.log("visible", visible);

  return (
    <div
      className="scene-item-card"
      onClick={() => {
        if (!locked) {
          onClickedItem();
        } else {
          toast.warn("this scenario is locked");
        }
      }}
    >
      <div className="scene-item-bg">
        <div
          className="d-flex flex-column w-100 justify-content-between h-100"
          style={{ zIndex: 2, padding: 28 }}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ gap: 10 }}
          >
            <div className="d-flex align-items-center" style={{ gap: 10 }}>
              <div className="d-flex">
                <FontAwesomeIcon icon={faClapperboard} size="2x" />
              </div>
              <div className="d-flex">
                <Typography component="span" variant="h6">
                  {sceneTitle}
                </Typography>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex">
                  <Typography component="span">Progress</Typography>
                </div>
                <div className="d-flex">{renderStars()}</div>
              </div>
            </div>
          </div>
          <p style={{ padding: 16, width: "80%" }}>{sceneDescription}</p>
          <div className="d-flex justify-content-between align-items-center">
            {AppController.isAdmin && (
              <div
                className="d-flex align-items-center"
                style={{ gap: 20, cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onEditScene(props.data.id);
                  }}
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onDeleteScene(props.data.id);
                  }}
                />
              </div>
            )}
            <div className="d-flex" style={{ gap: 10 }}>
              <Button
                style={{ backgroundColor: "chartreuse" }}
                variant="contained"
              >
                Play
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="scene-item-blob"></div>
    </div>
  );
};
