import React from "react";
import "./Pump.css";
import AppController from "./../../utils/AppController";
import EventManager from "./../../utils/EventManager";
import moment from "moment";

class PumpBolus extends React.Component {
  state = {
    state: 0,
    bg: 80,
    grams: 0,
    units: 0,
  };
  componentDidMount = () => {
    const bg = Math.round(AppController.app.state.glucose);
    let state = Object.assign({}, this.state, {
      bg: bg,
    });
    this.setState(state);
    EventManager.listen("#button.pump.act", this.onAct);
    EventManager.listen("#button.pump.esc", this.onEcs);
    EventManager.listen("#button.pump.up", this.onUp);
    EventManager.listen("#button.pump.down", this.onDown);
    AppController.pumpState = AppController.pumpState_bolus;
    this.stateBeforeSave = Object.assign({}, this.state);
  };
  componentWillUnmount = () => {
    EventManager.stopListening("#button.pump.act", this.onAct);
    EventManager.stopListening("#button.pump.esc", this.onEcs);
    EventManager.stopListening("#button.pump.up", this.onUp);
    EventManager.stopListening("#button.pump.down", this.onDown);
  };
  onAct = () => {
    if (this.state.state === 1) {
      // access pump controller and calc units to deliver
      let pump = AppController.scene.pump;
      pump.setBolasBG(this.state.bg);
      pump.setGramsCarbs(this.state.grams);

      const units = pump.getBolusUnits();

      // added by roger 4/7/19
      pump.setBolusUnits(units);

      let state = Object.assign({}, this.state, {
        units: units,
      });
      this.setState(state);
    } else if (this.state.state === 4) {
      this.props.onDone(this.state);
      EventManager.fire("#action.bolus");
      return;
    }
    this.setState((pState) => {
      return {
        state: pState.state + 1,
      };
    });
  };
  onEsc = () => {
    this.props.onDone(null);
  };
  onUp = () => {
    if (this.state.state === 0) {
      this.setState((prevState) => {
        return {
          bg: prevState.bg + 1,
        };
      });
    } else if (this.state.state === 1) {
      this.setState((prevState) => {
        return {
          grams: prevState.grams + 1,
        };
      });
    }
  };
  onDown = () => {
    if (this.state.state === 0) {
      this.setState((prevState) => {
        return {
          bg: prevState.bg - 1,
        };
      });
    } else if (this.state.state === 1) {
      this.setState((prevState) => {
        return {
          grams: prevState.grams - 1,
        };
      });
    }
  };
  renderView = () => {
    if (this.state.state === 0) {
      return (
        <React.Fragment>
          <div>ENTER BG</div>
          <div>
            <span className={"blink"}>{this.state.bg}</span>
            <span>mg/dL</span>
          </div>
        </React.Fragment>
      );
    } else if (this.state.state === 1) {
      return (
        <React.Fragment>
          <div>ENTER FOOD</div>
          <div>
            <span className={"blink"}>{this.state.grams}</span>
            <span>grams</span>
          </div>
        </React.Fragment>
      );
    } else if (this.state.state === 2) {
      return (
        <React.Fragment>
          <div>ESTIMATE DETAILS</div>
          <div>Est total: {this.state.units}U</div>
          <div>Food intake: {this.state.grams} gr</div>
          <div>BG: {this.state.bg}</div>
        </React.Fragment>
      );
    } else if (this.state.state === 3) {
      return (
        <React.Fragment>
          <div>Estimate</div>
          <div>
            {this.state.units}
            <sup>u</sup>
          </div>
        </React.Fragment>
      );
    } else if (this.state.state === 4) {
      return (
        <React.Fragment>
          <div>BOLUS DELIVERY</div>
          <div>{moment(new Date()).format("h:mmA")}</div>
          <div>
            Set: {this.state.units}
            <sup>u</sup>
          </div>
          <div>
            {this.state.units}
            <sup>u</sup>
          </div>
        </React.Fragment>
      );
    }
  };
  render() {
    return <React.Fragment>{this.renderView()}</React.Fragment>;
  }
}
export default PumpBolus;
