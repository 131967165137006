import React, { useEffect, useRef } from "react";
import "./../App.css";
import TweenLite, { Sine } from "gsap";
import AppController from "./../utils/AppController";

export const TitleBanner = ({ title }) => {
  const ref = useRef();
  useEffect(() => {
    TweenLite.from(ref.current, 0, { alpha: 0 });
    TweenLite.from(ref.current, 1, {
      alpha: 1,
      ease: Sine.easeOut,
      onComplete: () => {
        TweenLite.to(ref.current, 3, {
          delay: 2,
          alpha: 0,
          onComplete: () => {
            AppController.app.setState({ titleBannerVisible: false });
          },
        });
      },
    });
  }, [ref]);
  return (
    <div style={{ pointerEvents: "none" }} ref={ref} className="title-banner">
      {title}
    </div>
  );
};
