import AppController from "./AppController";
import { clampAndRoundGlucose } from "./common";
class PumpController {
  isOcclusion = false;
  exercising = false;
  //basalRates=[];
  ICR = 0; //insulin carb ratio
  ISEN = 0; //insulin Sensitivity
  lowGluc = 0; //low glucose level
  highGluc = 0; //high glucose level
  AIT = 0; //active insulin time
  INHL = 0; //insulin half life / how fast the ins goes though system
  //temp basal settings
  tempB = false;
  tempBasalPercent = 0;
  tempBasalDuration = 0;
  //Bolas Settings
  bolasBG = 0;
  gramsCarbs = 0;
  manualBolusUnits = 0;
  //end Bolas Settings
  //Blood Glucose settings
  bloodGlucose = 0;
  activeInsulin = 0; //insulin currently in blood
  addedInsulin = 0; //insulin from bolas that hasn't been added to blood, is added in 2 things, half at 15 mins, half at 30 mins
  foodgrams = 0;
  timeSinceBolas = 0;
  trueCarbFactor = 0;
  trueInsulinFactor = 0;
  bloodSugarCarbRatio = 0;
  //end blood glucose settings
  insulinDelay = 30;
  insulinActivate = 1;
  suspended = false;

  constructor() {
    //this.activateTempBasal();
    //this.tempBasalPercent=AppController.app.state.basalUnits;
    //this.tempBasalDuration = 24;
    this.ICR = AppController.app.state.carbRatio; //carbRatio;
    this.ISEN = AppController.app.state.sensitivity; //sensitive;
    this.lowGluc = AppController.app.state.targetBg.low; //lowGC;
    this.highGluc = AppController.app.state.targetBg.high; //highGC;

    // Active insulin is the bolus insulin that has already been delivered
    // to your body, but has not yet been used. The pump considers your active
    // insulin setting in determining any active insulin still in your body from your prior boluses2-8 hours	6 hours	1 hour

    // allowed range 2-8 hours
    // default value 6hrs for the minimed 530g
    // increments 1hr
    this.AIT = AppController.app.state.activeInsulinTime; // active insuline time

    this.tempB = false;
    this.INHL = (this.AIT - 0.5) / 5;
    this.suspended = false;
    this.bloodSugarCarbRatio = this.ISEN / this.ICR;
    this.bloodGlucose = AppController.app.state.glucose;

    // how much of insulin is effective
    // TODO - find a true insulin formula and compare it to this one.
    this.trueInsulinFactor =
      (((this.ISEN * 1) / this.INHL) * Math.log10(2.0)) /
      (1 - Math.pow(2.0, -this.AIT * this.INHL));

    // The carbohydrate factor of a food is the percent of the food's total weight that is carbohydrate
    this.trueCarbFactor =
      (this.bloodSugarCarbRatio * 2.5 * Math.log10(2)) /
      (1 - Math.pow(2.0, -2.5));
  }
  setBasalPercent(a) {
    this.tempBasalPercent = a;
  }
  //setBasalDuration( hours){
  //this.tempBasalDuration = hours;
  //}
  setBasalDuration(minutes) {
    this.tempBasalDuration = minutes;
  }
  activateTempBasal() {
    this.tempB = true;
  }
  deactivateTempBasal() {
    this.tempB = false;
  }
  //end temp basal settings
  // only used for wizzard
  setBolasBG(bG) {
    this.bolasBG = bG;
  }
  setGramsCarbs(gc) {
    this.gramsCarbs = gc;
  }
  setBolusUnits(bU) {
    this.manualBolusUnits = bU;
  }
  eatFood(grams) {
    this.foodgrams += grams;
  }
  setBloodGlucose(bg) {
    this.bloodGlucose = bg;
  }
  vomit() {
    this.foodgrams = 0;
  }
  exercise() {
    this.exercising = true;
  }
  passTime(minutes) {
    if (this.isOcclusion) {
      this.bloodGlucose += Math.random() * 100;
      AppController.app.setState({
        glucose: clampAndRoundGlucose(this.bloodGlucose),
      });
      return;
    }
    //recalculate blood glucose, active insulin and foodGrams
    //does not include basal rate at this moment.
    console.log("============== passTime ===========================");
    console.log("minutes: ", minutes);
    console.log("bloodGlucose: ", this.bloodGlucose);
    console.log("activeInsulin: ", this.activeInsulin);
    console.log("INHL: ", this.INHL);
    console.log("trueCarbFactor: ", this.trueCarbFactor);
    console.log("foodgrams: ", this.foodgrams);
    console.log("gramsCarbs: ", this.gramsCarbs);
    console.log("---------------------------------------------------");

    // this appears to apply active insulin currently in the blood
    this.bloodGlucose =
      this.bloodGlucose -
      (this.trueInsulinFactor *
        (this.activeInsulin *
          ((1 - Math.pow(2.0, minutes / -60.0 / this.INHL)) /
            (Math.log10(2) / this.INHL))) -
        this.trueCarbFactor *
          ((this.foodgrams * (1 - Math.pow(2.0, (minutes / -60.0) * 2.5))) /
            (Math.log10(2) * 2.5))); //does not include basal rate
    this.foodgrams = this.foodgrams * Math.pow(2.0, minutes / -60.0 / 0.4);
    this.activeInsulin =
      this.activeInsulin * Math.pow(2.0, minutes / -60.0 / this.INHL);

    // console.log("bloodGlucose*: ",this.bloodGlucose)
    // console.log("activeInsulin*: ",this.activeInsulin)
    // console.log("foodgrams*: ",this.foodgrams)

    if (!this.suspended) {
      // console.log("suspended...tempB(",this.tempB,")..........................")
      if (this.tempB) {
        this.bloodGlucose -= (minutes / 6.0) * this.tempBasalPercent;
        this.tempBasalDuration -= minutes; // minutes/60.0;
        if (this.tempBasalDuration <= 0) {
          this.tempB = false;
        }
      } else {
        this.bloodGlucose -= minutes / 6.0;
      }
    }
    if (this.exercising) {
      // console.log("exercising.............................")
      this.bloodGlucose -= minutes / 6.0;
    }

    console.log("---------------------------------------------------");
    console.log("bloodGlucose**: ", this.bloodGlucose);
    console.log("timeSinceBolas: ", this.timeSinceBolas);
    console.log("insulinActivate: ", this.insulinActivate);
    console.log("insulinDelay: ", this.insulinDelay);
    console.log("addedInsulin: ", this.addedInsulin);

    if (this.timeSinceBolas < this.insulinActivate + this.insulinDelay) {
      //if added insulin hasn't taken complete effect yet
      // insulin delay is the amount of time it takes the insulin to take affect in the body
      let activeDelay = this.insulinDelay - this.timeSinceBolas;
      if (activeDelay < 0) {
        activeDelay = 0;
      }
      let nonDelay = minutes - activeDelay;
      let amountAdded = (minutes - activeDelay) / this.insulinActivate;
      if (amountAdded > 1) {
        amountAdded = 1;
      }

      let insulinToAdd = amountAdded * this.addedInsulin;

      // console.log("---------------------------------------------------")
      // console.log("activeDelay**: ",activeDelay)
      // console.log("nonDelay**: ",nonDelay)
      // console.log("amountAdded**: ",amountAdded)
      // console.log("insulinToAdd**: ",insulinToAdd)
      // console.log("bloodGlucose**: ",this.bloodGlucose)
      // console.log("trueInsulinFactor**: ",this.trueInsulinFactor)
      // console.log("INHL**: ",this.INHL)
      // console.log("activeInsulin**: ",this.activeInsulin)

      this.addedInsulin = (1 - amountAdded) * this.addedInsulin;
      // RMS this.bloodGlucose = this.bloodGlucose +(this.trueInsulinFactor*((insulinToAdd)*((1-Math.pow(2.0,(nonDelay/2)/(-60.0))/this.INHL))/(Math.log10(2)/this.INHL)));
      this.bloodGlucose =
        this.bloodGlucose +
        (insulinToAdd * (1 - Math.pow(2.0, nonDelay / 2 / -60.0) / this.INHL)) /
          (Math.log10(2) / this.INHL);
      this.activeInsulin =
        this.activeInsulin +
        insulinToAdd * Math.pow(2.0, nonDelay / 2 / -60.0 / this.INHL);
    }
    console.log("####BG", this.bloodGlucose);
    this.timeSinceBolas += minutes; //increase the amount of time since the last bolus, since time passed.
    this.exercising = false;

    // console.log("---------------------------------------------------")
    // console.log("bloodGlucose***: ",this.bloodGlucose)
    // console.log("addedInsulin***: ",this.addedInsulin)
    // console.log("activeInsulin***: ",this.activeInsulin)
    // console.log("timeSinceBolas***: ",this.timeSinceBolas)
    // console.log("===================================================")

    //AppController.app.state.glucose = this.bloodGlucose;
    AppController.app.setState({
      glucose: clampAndRoundGlucose(this.bloodGlucose),
    });
  }
  manualBolas(duration) {
    //use manual bolas settings to effect active insulin
    // FIXME
    this.manualBolusUnits = this.getBolusUnits();
    console.log("manualBolasUnits:", this.manualBolusUnits);
    this.addedInsulin += this.manualBolusUnits;
    this.timeSinceBolas = 0;
    this.passTime(duration || 60);
    console.log("MANUAL BOLUS: ", this.addedInsulin);
  }
  getBolusUnits() {
    //bolas wiz settings
    let total = 0;
    if (this.bolasBG - this.highGluc > 0) {
      total += (this.bolasBG - this.highGluc) / this.ISEN;
    }

    if (this.gramsCarbs > 0) {
      total += this.gramsCarbs / this.ICR;
    }

    let rounded20 = Math.round(total * 20);
    total = rounded20 / 20;

    return total;
  }
  bolasWiz() {
    let total = this.getBolusUnits();
    this.addedInsulin += total;
    this.timeSinceBolas = 0;
    // console.log(" BOLUS WIZ: ",this.addedInsulin)
  }
  suspend() {
    this.suspended = !this.suspended;
  }
  isSuspended() {
    return this.suspended;
  }
}
export default PumpController;
