import React, { useEffect, useState } from "react";
import * as R from "ramda";
import ReactFlow, {
  removeElements,
  addEdge,
  isEdge,
  // Background,
  // Controls,
  // MiniMap,
} from "react-flow-renderer";

import SourceNode from "./CustomFlowNodes/SourceNode";
import DataNode from "./CustomFlowNodes/DataNode";
import FunctionNode from "./CustomFlowNodes/FunctionNode";
import ValueNode from "./CustomFlowNodes/ValueNode";

const nodeTypes = {
  sourceNode: SourceNode,
  dataNode: DataNode,
  functionNode: FunctionNode,
  valueNode: ValueNode,
};

const SceneFlowView = ({ scene }) => {
  const A1ScenerioData = scene.info;

  const [elements, setElements] = useState([]);

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onConnect = (params) => {
    const allEdges = R.filter(isEdge, elements);
    const matchingEdges = R.filter(
      (edge) => edge.target === params.target,
      allEdges
    );
    setElements((els) => removeElements(matchingEdges, els));
    setElements((els) => addEdge(params, els));
  };

  const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();

  const onNodeContextMenu = (event, node) => {
    event.preventDefault();
  };

  const getScenerioID = (name) => {
    let id =
      A1ScenerioData.states.findIndex((item) => item["State Name"] === name) +
      1;
    if (id === 0) id = 999;
    return id;
  };

  useEffect(() => {
    // Source state and link to initial state
    const newElements = [
      {
        id: "0",
        type: "sourceNode",
        data: { name: A1ScenerioData["Scene Title"] },
        // data: { name: A1ScenerioData["states"][0]["State Name"] },
        position: { x: 0, y: 0 },
      },
    ];

    // Get initial state id
    let nId = getScenerioID("Initial");
    console.log("ID (init)", nId);

    // Put the initial Link
    newElements.push({
      id: "e1-2",
      source: "0__o__data",
      target: nId + "__i__data",
      animated: false,
    });

    for (let i = 0; i < A1ScenerioData["states"].length; i++) {
      if (A1ScenerioData.states[i]) {
        const gcLevs = [
          "Super High",
          "Very High",
          "High",
          "Nominal",
          "Low",
          "Very Low",
          "Super Low",
        ];
        let node_name = A1ScenerioData.states[i]["State Name"];
        // let node_inputs = [];
        // let node_outputs = [];
        let node_data = { name: node_name };

        for (let j = 0; j < gcLevs.length; j++) {
          let gcLev = gcLevs[j];
          node_data[gcLev] = [];
          // let node_outputs = [];
          if (A1ScenerioData.states[i].gcLevels[gcLev]) {
            for (
              let k = 0;
              k < A1ScenerioData.states[i].gcLevels[gcLev].length;
              k++
            ) {
              let newOutputLabel =
                A1ScenerioData.states[i].gcLevels[gcLev][k]["User Events"];
              let newOutput = { label: node_name, type: newOutputLabel };
              node_data[gcLev].push(newOutput);

              // Add Linkages
              if (A1ScenerioData.states[i].gcLevels[gcLev][k]["NextState"]) {
                console.log(
                  " NEXT STATE -> ",
                  A1ScenerioData.states[i].gcLevels[gcLev][k]["NextState"]
                );
                let id = getScenerioID(
                  A1ScenerioData.states[i].gcLevels[gcLev][k]["NextState"]
                );
                newElements.push({
                  id: "e" + j + "-" + k,
                  target: id + "__i__data",
                  source:
                    i +
                    1 +
                    "__o-" +
                    node_name +
                    "_" +
                    gcLev +
                    "__" +
                    newOutputLabel, //OkStateCheckedKetones_High__OnInit",
                  animated: true,
                });
              }
            }
          }
        }
        let node = {
          id: (i + 1).toString(),
          type: "functionNode",
          data: node_data,
          position: { x: 250 * (i + 1), y: 80 },
        };
        newElements.push(node);
      }
    }

    // Put an END State
    newElements.push({
      id: "999",
      type: "dataNode",
      data: { name: "END" },
      // data: { name: A1ScenerioData["states"][0]["State Name"] },
      position: { x: 0, y: 150 },
    });

    // Get initial state id
    nId = getScenerioID("Initial");

    setElements(newElements);
  }, [A1ScenerioData, setElements]);

  return (
    <ReactFlow
      style={{
        left: 0,
        top: 0,
        position: "absolute",
        backgroundColor: "#333333de",
      }}
      elements={elements}
      elementsSelectable={true}
      selectNodesOnDrag={true}
      nodeTypes={nodeTypes}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onLoad={onLoad}
      onNodeContextMenu={onNodeContextMenu}
    >
      {/* <Background variant="lines" gap={24} size={1} /> */}
      {/* <Controls /> */}
      {/* <MiniMap
        nodeColor={(node) => {
          switch (node.type) {
            case "valueNode":
              return "LightGreen";
            case "dataNode":
              return "LightBlue";
            case "functionNode":
              return "Lavender";
            case "sourceNode":
              return "Gold";
            default:
              return "#eee";
          }
        }}
      /> */}
    </ReactFlow>
  );
};

export default SceneFlowView;
