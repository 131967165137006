import AppController from "./AppController";

// export const parseMarkName = (ctx, markName) => {
export const parseMarkName = (isHost, markName) => {
  const ctx = {
    host: window.modelController.host,
    avatar: window.modelController.patient,
  };
  // A markName looks like camera:cut:hostclose or anim:idle:loop
  // Check for avatar anim change
  // sumerian.SystemBus.emit("anim-idle");
  // Triggers animation on avatar only (hosts only have gesture)

  console.log("parsing mark: ", markName);
  // Ignore this for now.
  // if (markName.search("anim:") > -1) {
  //   let cmd = markName.split(":");
  //   cmd.shift();
  //   let layer = findLayerByName(
  //     ctx.avatar.animationComponent,
  //     "Malcolm Animation Layer"
  //   );
  //   if (layer) {
  //     console.log("Playing anim sequence: ", cmd);
  //     try {
  //       let f = () => {
  //         let stateId = getAnimsId(layer, cmd.shift());
  //         layer.transitionTo(
  //           stateId,
  //           ctx.world.time,
  //           () => {
  //             if (cmd.length) f();
  //           },
  //           true
  //         );
  //       };
  //       f();
  //     } catch (e) {
  //       console.log("parseMarkName ERROR");
  //       console.log(e);
  //       // default to idle anim
  //       layer.setCurrentStateByName("idle-main", true);
  //     }
  //   } else {
  //     console.warn("Cant find animation: ", cmd[1]);
  //   }
  // }

  //  if (markName.search("rotate:") > -1) {
  //   let cmd = markName.split(":");
  //   let entity = cmd[1].toLowerCase() === "host" ? ctx.host : ctx.avatar;
  //   let rotation = parseInt(cmd[2]);
  //   let rads = (rotation * Math.PI) / 180.0;
  //   // entity.transformComponent.setRotation(0, rads, 0);
  //   console.log("TODO - add rotate functionality");
  // } else if (markName.search("lookat:") > -1) {
  //   let cmd = markName.split(":");
  //   // sumerian.SystemBus.emit("lookat." + cmd[1].toLowerCase());
  //   console.log("TODO - add look at functionality");
  // }
  // if (markName.search("toggle:hidden:") > -1) {
  //   let cmd = markName.split(":");
  //   // get entity by 3rd parm and toggle it's visibility
  //   // let entity = ctx.world.entityManager.getEntityByName(cmd[2]);
  //   // entity && entity.isHidden ? entity.show() : entity.hide();
  //   console.log("TODO - add toggle:hidden functionality");
  // }
};
export const postMessage = (messageObject) => {
  // This function would previously send a message via postMessage to the iframe window which was hosting the sumerian environment.
  // We're not longer using sumerian so we need to stub out this function and handle all cases.
  const { type, details } = messageObject;
  const { command, action } = details;

  const ctx = {
    host: window.modelController.host,
    avatar: window.modelController.patient,
  };

  // Messages sent from the sumerian environment (stubs)
  if (action === "speech-finished") {
    AppController.onSumerianMessage(details);
  }

  // not sent from sumerian
  if (type === "a1control") {
    if (!command) {
      console.log("no command");
      return;
    }
    let seg = command.split(":");
    if (seg[0] === "rotate") {
      console.log("TODO - add rotate functionality");
      // let entity = seg[1] === "host" ? ctx.host : ctx.avatar;
      // let rads = (seg[2] * Math.PI) / 180.0;
      // entity.transformComponent.setRotation(0, rads, 0);
    }
    // show / hide any entity by name (case sensitive)
    else if (seg[0] === "show") {
      console.log("TODO - find entity and show it");

      // let entity = ctx.world.entityManager.getEntityByName(seg[1]);
      // entity.show();
      // 					entity.traverse((ent)=>{
      // 						ent.show();
      // 					});
    } else if (seg[0] === "hide") {
      // let entity = ctx.world.entityManager.getEntityByName(seg[1]);
      // entity.hide();
    } else if (seg[0] === "translate") {
      console.log("TODO - translate thing", seg[1]);
      // let entity = ctx.world.entityManager.getEntityByName(seg[1]);
      // 					let entity=seg[1]==='host'?ctx.host:ctx.avatar;
      // entity.transformComponent.setTranslation(seg[2], seg[3], seg[4]);
    } else if (seg[0] === "speak") {
      if (seg[1] === "stop") {
        window.modelController.stopAllSpeech();
        // ctx.host.getComponent("speechComponent").stop();
        // ctx.avatar.getComponent("speechComponent").stop();
        return;
      }

      // let entity = seg[1] === "host" ? ctx.host : ctx.avatar;
      let _isHost = seg[1] === "host";
      window.modelController.say(details.dialog, _isHost);
    } else if (seg[0] === "clock") {
      AppController.app.setState({
        time: seg[1] + ":" + seg[2],
      });
    }

    // toggle hidden property of any entity
    else if (seg[0] === "toggle") {
      if (seg[1] === "hidden") {
        if (seg[2].toLowerCase() === "pump") {
          if (
            ctx.pump.transformComponent.getTranslation().z !==
            ctx.pumpOriginTranslation.z
          ) {
            ctx.pump.transformComponent.setTranslation(
              ctx.pumpOriginTranslation
            );
          } else {
            ctx.pump.transformComponent.setTranslation(0, 0, 4000);
          }
        }
      }
    }
    //  else if (seg[0] === "cam") {
    //   console.log("TODO - add cam functionality", seg[1]);
    // sumerian.SystemBus.emit("cam-" + seg[1].toLowerCase());
    // } else if (seg[0] === "anim") {
    //   parseMarkName(isHost, command);
    // }
  }
};

export const getSumerianFrame = () => {
  return {
    contentWindow: { postMessage },
  };
};
