import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cgmSetLowGlucose,
  cgmSetLowGlucoseOn,
} from "../../utils/redux/features/cgm";
import Slider from "../common/Slider";
import Switch from "../common/Switch";

const styles = {
  container: {
    height: 700,
    width: 350,
    position: "absolute",
    zIndex: 0,
    right: 100,
    top: 100,
  },
  frame: { pointerEvents: "none" },
  circleMeter: {
    layer1: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 150,
      left: 99,
    },
    glucose: {
      zIndex: 1,
    },
    mesurment: {
      zIndex: 1,
    },
    innerCircle: {
      width: 79,
      height: 79,
      top: 35,
      position: "absolute",
      borderRadius: "50%",
    },
  },
};

export default () => {
  const cgm = useSelector((state) => state.cgm);
  const [glucose, setGlucose] = useState(cgm.alertLowGlucose);
  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            ...styles.circleMeter.glucose,
            color: "#404040",
            fontSize: "1em",
          }}
          variant="h6"
          onChange={() => {}}
        >
          Low Glucose Alert
        </Typography>
        <Switch
          isOn={cgm.alertLowGlucoseOn}
          onChange={(e) => {
            dispatch(cgmSetLowGlucoseOn(e.target.checked));
          }}
        />
      </div>
      <hr />
      <div style={{ cursor: "pointer" }}>
        <Typography
          style={{
            ...styles.circleMeter.glucose,
            color: "#404040",
            fontSize: "1em",
          }}
          variant="h6"
        >
          Notify Me Below {glucose} mg/dL
        </Typography>
        <Slider
          min={40}
          max={100}
          defaultValue={cgm.alertLowGlucose}
          onChange={(e) => {
            setGlucose(e);
          }}
          onCommit={(e) => {
            dispatch(cgmSetLowGlucose(glucose));
          }}
        />
        <hr />
      </div>
    </>
  );
};
