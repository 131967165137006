import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppController from "../../utils/AppController";
import { SCENE_TYPES } from "../../utils/constants";
import {
  sceneDeleteState,
  sceneDeleteUserAction,
  sceneEditingGet,
  sceneSaveMetadata,
  sceneUpdateGCLevelNextState,
} from "../../utils/redux/features/scenes";
import AlertDialog from "../AlertDialog";
import CreateNewStateDialog from "./CreateNewStateDialog";
import CreateUserActionDialog from "./CreateUserActionDialog";
import DialogEditor from "./DialogEditor";
// import { uiIsDialogEditorOpen } from "../../utils/redux/features/ui";

const StateGCLevelState = ({
  stateName,
  details,
  sceneStates,
  onCancelSceneEditor,
  setIsDialogEditorOpen,
}) => {
  // watch for updates to the scene object.
  const [isRemoveUserActionOpen, setIsRemoveUserActionOpen] = useState(false);
  const [nextState, setNextState] = useState(details.NextState);
  const dispatch = useDispatch();
  const onSetNextState = (e) => {
    const _nextState = e.target.value;
    setNextState(_nextState);

    // Save to backend
    dispatch(
      sceneUpdateGCLevelNextState({
        stateName,
        gcLevel: details["GC Level"],
        action: details["User Events"],
        nextState: _nextState,
      })
    );
  };
  useSelector((state) => state.scenes);
  const prettyDialog = AppController.sterilizeDialog(details.Dialog.trim());
  return (
    <Card className="m-2">
      <CardContent>
        <Box className="d-flex flex-column">
          <Box className="d-flex justify-content-between">
            <Typography variant="h5">{details["User Events"]}</Typography>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                next state
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={nextState}
                onChange={onSetNextState}
                label="Age"
              >
                {sceneStates.map((stateName, sceneStatesKey) => {
                  return (
                    <MenuItem key={sceneStatesKey} value={stateName}>
                      {stateName}
                    </MenuItem>
                  );
                })}
                <MenuItem key={"END"} value={"END"}>
                  END
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* <Typography variant="body2">{details["User Events"]}</Typography> */}
          {/* <TextField
            margin="dense"
            label="Next State"
            type="text"
            variant="standard"
            value={details["NextState"]}
            contentEditable={false}
          /> */}
          {/* <DialogEditor
            stateName={stateName}
            details={details}
            onCancelSceneEditor={onCancelSceneEditor}
          /> */}
          <p
            style={{ cursor: "pointer" }}
            onClick={() => setIsDialogEditorOpen({ stateName, details })}
          >
            {prettyDialog}
          </p>
        </Box>
        <CardActions>
          <Box className="mt-4 w-100 align-items-center justify-content-end d-flex">
            <Button
              className="mr-4 r-0"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsRemoveUserActionOpen({
                  stateName,
                  gcLevel: details["GC Level"],
                  action: details["User Events"],
                });
              }}
            >
              Delete
            </Button>
            <AlertDialog
              title="Remove User Action?"
              content="Selecting Yes will remove this user action from the glucose state"
              isOpen={!!isRemoveUserActionOpen}
              onResponse={(yes) => {
                if (yes) {
                  dispatch(sceneDeleteUserAction(isRemoveUserActionOpen));
                }
                setIsRemoveUserActionOpen(false);
              }}
            />
          </Box>
        </CardActions>
      </CardContent>
    </Card>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ScrollableTabs = ({
  stateName,
  gcLevels,
  sceneStates,
  onCancelSceneEditor,
  setIsDialogEditorOpen,
}) => {
  const [value, setValue] = React.useState(0);
  const [isAddUserActionDialogVisible, setIsAddUserActionDialogVisible] =
    useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const levels = {
    "Super High": [],
    "Very High": [],
    High: [],
    Nominal: [],
    Low: [],
    "Very Low": [],
    "Super Low": [],
  };

  Object.keys(gcLevels).map((key) => {
    levels[key] = gcLevels[key];
  });

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {Object.keys(levels).map((key) => {
            return <Tab key={key} label={key} />;
          })}
        </Tabs>
      </Box>

      {Object.values(levels).map((levelArray, j) => {
        return (
          <TabPanel key={j} value={value} index={j}>
            {levelArray.map((details, k) => {
              return (
                <StateGCLevelState
                  key={k}
                  details={details}
                  stateName={stateName}
                  sceneStates={sceneStates}
                  onCancelSceneEditor={onCancelSceneEditor}
                  setIsDialogEditorOpen={setIsDialogEditorOpen}
                />
              );
            })}

            <Box className="mt-4 w-100 align-items-center justify-content-end d-flex">
              <Button
                className="mr-4 r-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log(levelArray[j]);
                  setIsAddUserActionDialogVisible(levelArray[j]);
                }}
              >
                Add User Action
              </Button>
            </Box>
          </TabPanel>
        );
      })}
      <CreateUserActionDialog
        stateName={stateName}
        gcLevel={isAddUserActionDialogVisible["GC Level"]}
        isOpen={!!isAddUserActionDialogVisible}
        onClose={() => setIsAddUserActionDialogVisible(false)}
      />
    </>
  );
};

export const SceneEditor = ({ open, data, onCancelSceneEditor }) => {
  const dispatch = useDispatch();
  const [isCreateStateDialogOpen, setIsCreateStateDialogOpen] = useState(false);
  const [isDialogEditorOpen, setIsDialogEditorOpen] = useState(null);
  const [
    confirmDeleteStateDialogDetails,
    setIsConfirmDeleteStateDialogVisible,
  ] = useState(null);
  const editingScene = useSelector((state) => state.scenes.editingScene);
  const [form, setForm] = useState(
    data
      ? // editing state
        {
          "Scene Name": data.info["Scene Name"] || "",
          "Scene Title": data.info["Scene Title"] || "",
          "Scene Description": data.info["Scene Description"] || "",
          "Scene Starting BG": data.info["Scene Starting BG"] || "",
          "Scene Type": data.info["Scene Type"] || "",
          "Scene Starting Time": data.info["Scene Starting Time"] || "12:00",
          "Scene Difficulty": data.info["Scene Difficulty"] || "Introduction",
          "Scene Points": data.info["Scene Points"] || "3",
          published: data.info.published || false,
          active: data.info.active || true,
          states: data.info.states || [],
        }
      : {
          // create new
          "Scene Name": "",
          "Scene Title": "",
          "Scene Description": "",
          "Scene Starting BG": "",
          "Scene Type": "",
          "Scene Starting Time": "12:00",
          "Scene Difficulty": "Introduction",
          "Scene Points": "3",
          published: false,
          active: true,
          states: [],
        }
  );

  useEffect(() => {
    dispatch(sceneEditingGet(data.id));
  }, []);

  const onSaveMetadata = () => {
    if (!form["Scene Name"]) {
      toast.warn("Scene Title must not be empty");
      return;
    }

    dispatch(sceneSaveMetadata(form));
    toast.success("Scene Metadata saved!");
    // dispatch(scenePut({ id: data.id, payload: form }));
    // } else {
    // dispatch(sceneCreate(form));
    // }
    // onCancelSceneEditor();
  };

  const renderStates = () => {
    // render out states
    const { states = [] } = data.info;
    return states.map((state, accordionKey) => {
      // const gcLevelKeys = Object.keys(state.gcLevels || {});
      return (
        <Accordion key={accordionKey}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Box className="w-100 align-items-center justify-content-between d-flex">
              <Box className="d-flex">
                <TurnedInIcon />
                <Typography sx={{ marginLeft: 1, flexShrink: 0 }}>
                  {state["State Name"]}
                </Typography>
              </Box>
              <Button
                className="mr-4"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsConfirmDeleteStateDialogVisible(state);
                }}
              >
                Remove State
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Card className="m-2" title={state["State Name"]}> */}
            {/* <CardHeader title={state["State Name"]} /> */}
            <ScrollableTabs
              key={accordionKey}
              gcLevels={state.gcLevels}
              stateName={state["State Name"]}
              sceneStates={states.map((n) => n["State Name"])}
              onCancelSceneEditor={onCancelSceneEditor}
              setIsDialogEditorOpen={setIsDialogEditorOpen}
            />
            {/* </Card> */}
          </AccordionDetails>
        </Accordion>
      );
    });
  };
  return (
    <div className="joe-momma">
      {isDialogEditorOpen ? (
        <DialogEditor
          {...isDialogEditorOpen}
          // stateName={stateName}
          // details={details}
          onClose={() => setIsDialogEditorOpen(null)}
          onCancelSceneEditor={onCancelSceneEditor}
        />
      ) : (
        <Dialog maxWidth={false} open={open} onClose={onCancelSceneEditor}>
          <DialogTitle>
            <div className="d-flex w-100 justify-content-between">
              <Typography variant="h4">Create / Edit Scene</Typography>
              <Button
                onClick={() => {
                  // TODO: - open flow view
                  onCancelSceneEditor();
                  AppController.app.setState({
                    isFlowRendererOpen: editingScene,
                  });
                }}
              >
                Review Flow
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              label="Scene Title"
              type="text"
              fullWidth
              variant="standard"
              value={form["Scene Title"]}
              onChange={(e) =>
                setForm({
                  ...form,
                  ...{
                    "Scene Title": e.target.value,
                    "Scene Name": e.target.value,
                  },
                })
              }
            />
            <FormControl variant="standard" sx={{ m: 0, minWidth: 220 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Scene Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={form["Scene Type"]}
                onChange={(e) => {
                  setForm({ ...form, ...{ "Scene Type": e.target.value } });
                }}
                label="Scene Type"
              >
                {Object.values(SCENE_TYPES).map((sceneType, _key) => {
                  return (
                    <MenuItem key={_key + 100} value={sceneType}>
                      <Typography textTransform="capitalize">
                        {sceneType}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Scene Description"
              type="text"
              fullWidth
              variant="standard"
              value={form["Scene Description"]}
              onChange={(e) =>
                setForm({ ...form, ...{ "Scene Description": e.target.value } })
              }
            />
            <TextField
              margin="dense"
              label="Scene Starting BG"
              type="text"
              fullWidth
              variant="standard"
              value={form["Scene Starting BG"]}
              onChange={(e) =>
                setForm({ ...form, ...{ "Scene Starting BG": e.target.value } })
              }
            />
            <TextField
              margin="dense"
              label="Scene Starting Time"
              type="text"
              fullWidth
              variant="standard"
              value={form["Scene Starting Time"]}
              onChange={(e) =>
                setForm({
                  ...form,
                  ...{ "Scene Starting Time": e.target.value },
                })
              }
            />
            <TextField
              margin="dense"
              label="Scene Difficulty"
              type="text"
              fullWidth
              variant="standard"
              value={form["Scene Difficulty"]}
              onChange={(e) =>
                setForm({ ...form, ...{ "Scene Difficulty": e.target.value } })
              }
            />
            <div className="d-flex justify-content-end">
              <Button onClick={onSaveMetadata}>Save Metadata</Button>
            </div>
            <Stack
              className="mt-4"
              direction="column"
              // justifyContent="center"
              // alignItems="center"
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={form.published}
                    onChange={(e) => {
                      setForm({ ...form, ...{ published: e.target.checked } });
                    }}
                  />
                }
                label="Published"
              />
              {/* <FormControlLabel
            control={
              <Switch
                checked={form.active}
                onChange={(e) => {
                  setForm({ ...form, ...{ active: e.target.checked } });
                }}
              />
            }
            label="Active"
          /> */}
            </Stack>
            <Box className="d-flex justify-content-between">
              <DialogTitle>Scene States</DialogTitle>
              <Button onClick={() => setIsCreateStateDialogOpen(true)}>
                Create New State
              </Button>
            </Box>
            {renderStates()}
            <DialogActions>
              <div>
                <Button onClick={onCancelSceneEditor}>Ok</Button>
              </div>
            </DialogActions>
          </DialogContent>
          <CreateNewStateDialog
            isOpen={isCreateStateDialogOpen}
            onClose={() => setIsCreateStateDialogOpen(false)}
          />

          {/* Delete state dialog confirmation */}
          {confirmDeleteStateDialogDetails && (
            <AlertDialog
              isOpen={true}
              title={confirmDeleteStateDialogDetails["State Name"]}
              content={`Are you sure you want to delete ${confirmDeleteStateDialogDetails["State Name"]}?`}
              onResponse={(deleteItem) => {
                if (deleteItem) {
                  dispatch(
                    sceneDeleteState(
                      confirmDeleteStateDialogDetails["State Name"]
                    )
                  );
                }
                setIsConfirmDeleteStateDialogVisible(null);
              }}
            />
          )}
        </Dialog>
      )}
    </div>
  );
};
