import React from 'react';
import './Pump.css';
import AppController from './../../utils/AppController';
import EventManager from './../../utils/EventManager';
export default class PumpTempBasal extends React.Component{
    state={
        state:0,
        duration:30,//default to 30 min
        tempBasalPcent:100,
    };
    componentDidMount=()=>{
        EventManager.listen("#button.pump.act",this.onAct);
        EventManager.listen("#button.pump.up",this.onUp);
        EventManager.listen("#button.pump.down",this.onDown);
        AppController.pumpState = AppController.pumpState_12HourSetup; 
    }
    componentWillUnmount=()=>{
        EventManager.stopListening("#button.pump.act",this.onAct);
        EventManager.stopListening("#button.pump.up",this.onUp);
        EventManager.stopListening("#button.pump.down",this.onDown);
    }
    onAct=()=>{
        if(this.state.state===1){
            AppController.app.setState({
                tempBasalDuration:this.state.duration,
                tempBasalPcent:this.state.tempBasalPcent,
            });
            this.props.onDone(); 
            return;
        }
        this.setState((pState)=>{
            return {
                state:pState.state+1
            }
        });
    }
    onEsc=()=>{
        this.props.onDone();
    }
    onUp=()=>{
        if(this.state.state===0){
            if(this.state.duration/60===24){return;}
            this.setState((pState)=>{
                return {duration:pState.duration+30}
            });
        }else if(this.state.state===1){
            this.setState((pState)=>{
                return {tempBasalPcent:pState.tempBasalPcent+1};
            });
        }
    }
    onDown=()=>{
        if(this.state.state===0){
            if(this.state.duration===0)return;
            this.setState((pState)=>{
                return {duration:pState.duration-30}
            });
        }else if(this.state.state===1){
            this.setState((pState)=>{
                return {tempBasalPcent:pState.tempBasalPcent-1};
            });
        }
    }
    renderView=()=>{
        if(this.state.state===0){
            let hrs = this.state.duration/60;
            let mins = parseInt(hrs%1*60,10);
            mins=mins<10?'0'+mins.toString():mins.toString();
            return (<React.Fragment>
                <div>SET DURATION</div>
                <div className="blink pump-big-font">{parseInt(hrs,10)}:{mins}</div>
            </React.Fragment>)
        }
        else if(this.state.state===1){
            let hrs = this.state.duration/60;
            let mins = parseInt(hrs%1*60,10);
            mins=mins<10?'0'+mins.toString():mins.toString();
            return (<React.Fragment>
                <div>SET TEMP BASAL %</div> 
                <div>{parseInt(hrs,10)}:{mins}</div>
                <div style={{float:'right'}} className="blink pump-big-font">{this.state.tempBasalPcent}%</div>
            </React.Fragment>)
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        );
    }
}
