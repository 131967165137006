import React, { Component } from "react";
import ReactChartkick, { LineChart } from "react-chartkick";
import { Chart, registerables } from "chart.js";
ReactChartkick.addAdapter(Chart);
Chart.register(...registerables);
class A1LineChart extends Component {
  render() {
    return (
      <div>
        <LineChart
          legend={false}
          curve={false}
          colors={["#333333"]}
          height={80}
          min={20}
          max={400}
          width="280px"
          data={this.props.data}
          // data={{ "8:30": 121, "9:00": 150, "10:10": 300 }}
        />
      </div>
    );
  }
}
export default A1LineChart;
