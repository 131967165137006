import { DialogTitle } from "@material-ui/core";
import { Dialog, DialogContent, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/system";
import "draft-js/dist/Draft.css";
import * as React from "react";
import "./sceneEditor/editorStyles.css";

const _ImageList = ({ onSelectImage }) => {
  return (
    <ImageList>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            style={{ cursor: "pointer" }}
            // src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            src={item.img}
            // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
            onClick={() => {
              onSelectImage(item);
            }}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ({ onClose, onSelectImage }) => {
  return (
    <Box
      className="d-flex flex-column m-2 h-100"
      sx={{ maxHeight: "none", height: 10000 }}
    >
      <Typography variant="h6">Select Reference Image</Typography>
      {/* <div onClick={() => dispatch(uiIsDialogEditorOpen(true))}>
        <Typography variant="body2" style={{ cursor: "pointer" }}>
          {details["Dialog"]}
        </Typography>
      </div> */}

      <Dialog
        style={{ maxHeight: "none" }}
        className="h-100"
        maxWidth={false}
        fullWidth={true}
        open={true}
        onClose={onClose}
        sx={{ maxHeight: "none" }}
      >
        <DialogTitle>Select Reference Image</DialogTitle>
        <DialogContent sx={{ maxHeight: "none", height: "640px" }}>
          <_ImageList onSelectImage={onSelectImage} />
        </DialogContent>
        {/* <DialogActions>
          <div>
            <Button onClick={() => dispatch(uiIsDialogEditorOpen(null))}>
              Cancel
            </Button>
            <Button onClick={() => onSave()}>Save</Button>
          </div>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
};
const itemData = [
  // {
  //   img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
  //   title: "Breakfast",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
  //   title: "Breakfast",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
  //   title: "Burger",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
  //   title: "Camera",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
  //   title: "Coffee",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
  //   title: "Hats",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
  //   title: "Honey",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
  //   title: "Basketball",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
  //   title: "Fern",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
  //   title: "Mushrooms",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
  //   title: "Tomato basil",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
  //   title: "Sea star",
  // },
  // {
  //   img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
  //   title: "Bike",
  // },
];
for (let i = 0; i < 14; i++) {
  itemData.push({
    img: `https://a1controldb-dev.s3.us-east-2.amazonaws.com/public/images/image(${i}).png`,
    title: `image ${i}`,
  });
}
