import React, { useRef, useState } from "react";
import { Chip, DialogTitle, TextareaAutosize } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "draft-js/dist/Draft.css";
import "./editorStyles.css";
import { useDispatch, useSelector } from "react-redux";
import { sceneSaveDialog } from "../../utils/redux/features/scenes";
import AppController from "../../utils/AppController";
import HostAnimData from "../../../src/utils/HostAnimsData.json";
import EventManager from "../../utils/EventManager";
import { uiIsDialogEditorOpen } from "../../utils/redux/features/ui";
import ImageSelector from "../ImageSelector";

const categories = {
  "Add Dialog For...": [
    {
      label: "host",
      value: "#host",
    },
    {
      label: "avatar",
      value: "#avatar",
    },
  ],
  Image: [{ label: "Add Reference Image", value: "#image" }],
  Cam: [
    {
      label: "Cam Host",
      value: "#cam.host",
    },
    {
      label: "Cam Avatar",
      value: "#cam.avatar",
    },
    {
      label: "Cam Avatar Low",
      value: "#cam.avatar-low",
    },
    {
      label: "Wide View",
      value: "#cam.wide",
    },
  ],
  ["Wait Time"]: [
    {
      label: "1 Second",
      value: '<break time="1s"/>',
    },
    {
      label: "2 Second",
      value: '<break time="2s"/>',
    },
    {
      label: "3 Second",
      value: '<break time="3s"/>',
    },
    {
      label: "4 Second",
      value: '<break time="4s"/>',
    },
    {
      label: "5 Second",
      value: '<break time="5s"/>',
    },
  ],
  // Screen: [
  //   {
  //     label: "Scenarios",
  //     value: "#screen.scenes",
  //   },
  // ],
  Anim: [
    {
      label: "Burpee",
      value: "#anim.exercise-startburpee.exercise-burpee.exercise-stopburpee",
    },
    {
      label: "Sleep",
      value: "#anim.idle-sleeping",
    },
    {
      label: "Plank",
      value: "#anim.Exercise-plank",
    },
  ].concat([
    ...HostAnimData.map((n) => {
      return { label: n.name, value: `#anim.${n.name}` };
    }),
  ]),
  Rotate: [
    {
      label: "Rotate Host Forward",
      value: "#rotate.host.0",
    },
    {
      label: "Rotate Avatar Forward",
      value: "#rotate.avatar.0",
    },
  ],
  Utilities: [
    {
      label: "Blood Glucose Level",
      value: "#bg",
    },
  ],
};

export default ({ onClose, stateName, details, onCancelSceneEditor }) => {
  const dispatch = useDispatch();
  const [selectedTextAreaIndex, setSelectedTextAreaIndex] = useState(null);
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);
  const _dialog = details.Dialog.trim()
    .replaceAll("#host", "~~#host~~")
    .replaceAll("#avatar", "~~#avatar~~")
    .replaceAll("\n", "");
  const [content, setContent] = useState(
    _dialog.split("~~").filter((n) => n) // filter empty indexes
  );
  let ref = useRef(null);

  const onSave = () => {
    const _content = content
      .join(" ")
      .replaceAll("#avatar", "\n#avatar")
      .replaceAll("#host", "\n#host");
    dispatch(
      sceneSaveDialog({
        stateName,
        gcLevel: details["GC Level"],
        action: details["User Events"],
        dialog: _content,
      })
    );
    // setIsEditing(false);
    // dispatch(uiIsDialogEditorOpen(null));
    onClose();
  };

  const addStr = (str, index, stringToAdd) => {
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  };
  const getSelection = () => {
    const start = ref.current.selectionStart;
    const end = ref.current.selectionEnd;
    return { start, end };
  };

  const onAddCommand = (value) => {
    if (["#image.url"].includes(value)) {
    } else if (["#image"].includes(value)) {
      console.log("TODO: add function to grab s3 images");
      setImageSelectorOpen(true);
      return;
    }
    if (["#host", "#avatar"].includes(value)) {
      const newContent = content.slice();
      newContent.push(value, `#cam.${value.substring(1)}`);
      setContent(newContent);
      return;
    }
    const { start, end } = getSelection();
    const newContent = addStr(
      content[selectedTextAreaIndex],
      start,
      ` ${value} `
    );
    const _content = content.slice();
    _content[selectedTextAreaIndex] = newContent;
    setContent(_content);

    // return focus to textarea
    window.focus(ref.current);
    ref.current.focus();
  };
  const onRemoveDialog = (index) => {
    const newContent = content.slice();
    newContent.splice(index, 2);
    setContent(newContent);
  };
  const onPreviewDialog = (index) => {
    // setIsEditing(false);
    dispatch(uiIsDialogEditorOpen(null));
    onCancelSceneEditor();
    EventManager.fire("#screen.scenes");
    const isHost = content[index].includes("#host");
    const { dialog } = AppController.parseDialog(content[index + 1]);

    if (isHost) {
      window.modelController.host.TextToSpeechFeature.play(dialog);
    } else {
      window.modelController.patient.TextToSpeechFeature.play(dialog);
    }
  };

  const renderEditor = () => {
    return (
      <div>
        {content.map((chunk, index, _content) => {
          if (["#host", "#avatar"].includes(chunk.trim())) {
            return (
              <div key={index} className="d-flex justify-content-between">
                <Typography variant="overline">{chunk.substring(1)}</Typography>
                <div>
                  <Button onClick={() => onRemoveDialog(index)}>Remove</Button>
                  <Button
                    onClick={() => {
                      onPreviewDialog(index);
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="editor">
                <TextareaAutosize
                  value={_content[index]}
                  onChange={(e) => {
                    const newContent = content.slice();
                    newContent[index] = e.target.value;
                    setContent(newContent);
                  }}
                  className="border-0 w-100"
                  onFocus={(e) => {
                    setSelectedTextAreaIndex(index);
                    ref.current = e.target;
                  }}
                />
              </div>
            );
          }
        })}

        <div className="options">
          <div>
            {Object.entries(categories).map(([category, item], index) => {
              return (
                <div className="mb-2" key={index}>
                  <Typography variant="h6">{category}</Typography>
                  {item.map(({ label, value }, _index) => {
                    return (
                      <Chip
                        // variant="outline"
                        className="mr-2 mt-2"
                        style={{
                          background: (() => {
                            if (label.startsWith("Idle")) {
                              return "#9dffe2";
                            } else if (label.startsWith("Gesture")) {
                              return "#9ee2ff";
                            } else if (
                              label.startsWith("host") ||
                              label.startsWith("avatar")
                            ) {
                              return "rgb(255 210 249)";
                            }
                            return "#d2e5ff";
                          })(),
                        }}
                        key={_index}
                        label={label}
                        onClick={() => onAddCommand(value)}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      style={{ maxHeight: "none" }}
      className="h-100"
      maxWidth={false}
      fullWidth={true}
      open={true}
      onClose={() => dispatch(uiIsDialogEditorOpen(null))}
      sx={{ maxHeight: "none" }}
    >
      <DialogTitle>Edit Dialog</DialogTitle>
      <DialogContent>
        {/* <Box
          className="d-flex flex-column m-2 h-100"
          sx={{ maxHeight: "none", height: 10000 }}
        > */}
        {imageSelectorOpen && (
          <ImageSelector
            onSelectImage={(item) => {
              setImageSelectorOpen(false);
              onAddCommand(`#image.url.${item.img}`);
            }}
            onClose={() => setImageSelectorOpen(false)}
          />
        )}
        {/* <Typography variant="h6">Dialog</Typography> */}
        {/* <div
            onClick={() => {
              dispatch(uiIsDialogEditorOpen(true));
            }}
          >
            <Typography variant="body2" style={{ cursor: "pointer" }}>
              {details["Dialog"]}
            </Typography>
          </div> */}
        {/* </Box> */}
      </DialogContent>
      <DialogContent sx={{ maxHeight: "none", height: "640px" }}>
        {renderEditor()}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => onSave()}>Save</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
