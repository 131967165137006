import AppController from "./AppController";
const EVENT_CANCELED = 1001;
let EventManager = {
  fire(type, data) {
    [type, data] = this.parse(type, data);
    if (data === EVENT_CANCELED) {
      console.log("event canceled: ", type);
      return;
    }
    let e = new CustomEvent(type, { detail: { data: data } });
    // console.log("dispatching: "+type,data||'');
    if (data instanceof Object) {
      // console.log("dispatching: " + type);
    } else {
      // console.log("dispatching: " + type, data || "");
    }

    document.dispatchEvent(e);
  },
  listen(type, cb) {
    document.addEventListener(type, cb);
  },
  stopListening(type, cb) {
    document.removeEventListener(type, cb);
  },
  removeListener(type, cb) {
    document.removeEventListener(type, cb);
  },
  parse(type, data) {
    // speak API
    if (type.search(/#speak/) > -1) {
      // parse dialog and send to polly
      if (type.search(/^#speak$/) > -1) {
        AppController.speak(data);
      }

      // stop host and or avatar speech
      else if (type.search(/^#speak\.stop$/) > -1) {
        AppController.issueCommand("#speak.stop");
      }
    }
    //else if(type.search(/#action\.pump/)){
    //// toggle pump
    //// infinit loop
    ////AppController.app.setState({pumpOpen:!AppController.app.state.pumpOpen});
    //}
    else if (type.search(/#clock/) > -1) {
      AppController.issueCommand(type);
    } else if (type.search(/#screen\.scenes/gi) > -1) {
      const visible = !AppController.app.state.sceneSelect.visible;
      AppController.app.setState({ sceneSelect: { visible } });
    } else if (type.search(/#screen\.quiz/i) > -1) {
      // set data to be quiz-id
      data = parseInt(type.split(".")[2], 10);
      if (!data) data = -1;
      type = "#screen.quiz";
    } else if (type.search(/#timeinc/) > -1) {
      if (type.search(/#timeinc\.finished/) > -1) {
        // nop
      } else {
        const seconds = parseInt(type.split(".")[1], 10);
        data = seconds;
        type = "#timeinc";
      }
    }
    // Check if buttons type event
    else if (type.search(/#buttons./) > -1) {
      let buttons = type.split(".");
      buttons.shift(); // remove "buttons" from array
      buttons.push("meter");
      buttons.push("cgm");
      buttons.push("check-site");
      buttons.push("change-site");
      if (buttons[0] === "all") {
        buttons = [
          "ketones",
          "eat",
          "sleep",
          "wait",
          "exercise",
          "pump",
          "check-site",
          "change-site",
          "meter",
          "cgm",
        ];
      } else if (buttons[0] === "none") {
        buttons = [];
      }
      AppController.app.setState({ actionButtons: buttons });
    }
    // help view
    else if (type.search(/#screen\.help/i) > -1) {
      // check if app controller scene is not null
      // check if help is an option
      if (AppController.scene && AppController.scene.onHelp()) {
      } else {
        //load general help
        console.log("TODO: load general help");
      }
    }
    // restrict pump actions if host or avatar is speaking
    else if (type.search(/#button\.pump/gi) > -1) {
      if (AppController.isSpeaking) data = EVENT_CANCELED;
      // check button restrictions
      //let rule=AppController.app.state.pumpButtonsAllowed;
      //if(AppController.pumpState === AppController.pumpState_nav){
      //// TODO - check if data is === pumpButtonsAllowed
      //}
      if (type.search(/#buttons\.pump\.esc/) > -1) {
        if (AppController.scene && AppController.scene.isTutorial) {
          data = EVENT_CANCELED;
        }
      }
    }
    return [type, data];
  },
};
export default EventManager;
