import { Howl } from "howler";
import alertSound from "../audio/alert.wav";
import cheerSound from "../audio/cheer.wav";

export default {
  alert: new Howl({ src: [alertSound] }),
  cheer: new Howl({ src: cheerSound }),
  play(snd) {
    snd.play();
  },
};
