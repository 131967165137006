import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import EventManager from './EventManager';
class PositionedSnackbar extends React.Component {
    state = {
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message:''
    };
    componentDidMount=()=>{
        this.timer = null;
        EventManager.listen("#alert",this.handleOpen);
    }
    handleOpen=(e)=>{
        this.setState({
            open:true,
            vertical:'top',
            horizontal:'center',
            message:e.detail.data
        });
        if(this.timer) window.clearTimeout(this.timer);
        this.timer = window.setTimeout(()=>{
            if(this.state.open){
                this.handleClose();
            }
        },6000);
    };
    handleClose = () => {
        if(this.timer) window.clearTimeout(this.timer);
        this.setState({ open: false });
    };
    render() {
        const { vertical, horizontal, open ,message} = this.state;
        return (
            <div>
                <Snackbar
                    onClick={this.handleClose}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{message}</span>}
                />
            </div>
        );
    }
}
export default PositionedSnackbar;
