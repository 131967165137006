import React, { useState } from "react";
import { Button, CardActions, Dialog } from "@material-ui/core";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  PauseCircle,
  People,
  SkipPrevious,
  ExitToApp,
  SkipNext,
} from "@mui/icons-material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { sceneEditingGet } from "../utils/redux/features/scenes";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import AppController from "./../utils/AppController";
import AdminBreadcrumbsDialog from "./admin/AdminBreadcrumbsDialog";
import AdminUserList from "./admin/AdminUserList";

const AdminControls = () => {
  const dispatch = useDispatch();
  const scene = useSelector((state) => state.scenes.scene);
  const [breadcrumbsDialog, setBreadcrumbsDialog] = useState(null);
  const [isUserListOpen, setIsUserListOpen] = useState(null);

  return (
    <>
      <Card
        className="position-absolute w-100"
        style={{
          bottom: 0,
          borderRadius: 0,
          left: 0,
        }}
      >
        <CardActions className="justify-content-around">
          {/* <SkipPrevious
            sx={{
              cursor: "pointer",
            }}
            fontSize="large"
          /> */}
          {/* <PauseCircle
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              // window.modelController.stopAllSpeech();
            }}
            fontSize="large"
          /> */}
          {/* <SkipNextIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              window.modelController.stopAllSpeech();
            }}
            fontSize="large"
          /> */}
          <People
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsUserListOpen(true);
            }}
            fontSize="large"
          />
          <ExitToApp
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              AppController.exitSceneEarly("Ok, Exiting.");
            }}
            fontSize="large"
          />

          <SkipNext
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              window.modelController.stopAllSpeech();
              AppController.onSumerianMessage({
                action: "speech-finished",
              });
            }}
            fontSize="large"
          />
          {/* <Button
            onClick={() => {
              AppController.loadResultsView();
            }}
          >
            Load Score
          </Button> */}
          {/* <EditIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (scene) {
                dispatch(sceneEditingGet(scene.id));
              }
            }}
            fontSize="large"
          /> */}
          {/* Breadcrumbs Button */}
          {/* <BreakfastDiningIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              const { breadcrumbs } = AppController;
              setBreadcrumbsDialog(breadcrumbs);
            }}
            fontSize="large"
          /> */}
        </CardActions>

        {/* Breadcrumbs Dialog */}
        {breadcrumbsDialog && (
          <AdminBreadcrumbsDialog
            breadcrumbs={breadcrumbsDialog}
            onClose={() => setBreadcrumbsDialog(null)}
          />
        )}
        {isUserListOpen && (
          <AdminUserList onClose={() => setIsUserListOpen(false)} />
        )}
      </Card>
    </>
  );
};

export default AdminControls;
