import React from "react";

export const Clock = ({ time }) => {
  return (
    <div
      style={{
        color: "red",
        textAlign: "center",
        fontSize: "3em",
        fontFamily: "Orbitron, sans-serif",
        position: "fixed",
        background: "#333",
        borderRadius: 15,
        width: 167,
        top: 47,
        right: 10,
        border: "4px solid black",
      }}
    >
      {time}
    </div>
  );
};
