import { createAction } from "@reduxjs/toolkit";

const initState = {
  alertLowGlucose: 100,
  alertLowGlucoseOn: true,
  alertHighGlucose: 300,
  alertHighGlucoseOn: true,
};

export const cgmSetLowGlucose = createAction("cgm/set/low/glucose");
export const cgmSetLowGlucoseOn = createAction("cgm/set/low/glucose/on");
export const cgmSetHighGlucose = createAction("cgm/set/high/glucose");
export const cgmSetHighGlucoseOn = createAction("cgm/set/high/glucose/on");

export default function reducer(state = initState, action) {
  switch (action.type) {
    case cgmSetLowGlucose.type:
      return { ...state, alertLowGlucose: action.payload };
    case cgmSetLowGlucoseOn.type:
      return { ...state, alertLowGlucoseOn: action.payload };
    case cgmSetHighGlucose.type:
      return { ...state, alertHighGlucose: action.payload };
    case cgmSetHighGlucoseOn.type:
      return { ...state, alertHighGlucoseOn: action.payload };
  }
  return state;
}
