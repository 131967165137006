import scenes from "./features/scenes";
import users from "./features/users";
import ui from "./features/ui";
import cgm from "./features/cgm";
import glossary from "./features/glossary";
import userSlice from "./features/userSlice";

import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    scenes,
    users,
    ui,
    cgm,
    glossary,
    userSlice,
  },
});
