import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// export const API_URL = "https://weswp09nn9.execute-api.us-east-2.amazonaws.com/dev";
export const API_NAME = "scenerioApi";

export const COLOR_NOMINAL = "#C8C9C9";
export const COLOR_WARNING = "#FCC93A";
export const COLOR_FAILURE = "#ff0000";

export const GLUCOSE_LEVEL_SUPER_HIGH = 400;
export const GLUCOSE_LEVEL_VERY_HIGH = 251;
export const GLUCOSE_LEVEL_HIGH = 181;
export const GLUCOSE_LEVEL_LOW = 79;
export const GLUCOSE_LEVEL_VERY_LOW = 59;
export const GLUCOSE_LEVEL_SUPER_LOW = 30;

export const GLUCOSE_KEYS = [
  "Super High",
  "Very High",
  "High",
  "Nominal",
  "Low",
  "Very Low",
  "Super Low",
];

export const USER_ACTIONS = {
  Eat: "OnEat",
  Sleep: "OnSleep",
  Exercise: "OnExercise",
  Wait: "OnWait",
  "Check Site": "OnCheckSite",
  "Change Site": "OnChangeSite",
  Help: "OnHelp",
  "Check Keytones": "OnKeytones",
  Bolus: "OnBolus",
};

export const SCENE_TYPES = {
  TUTORIAL: "tutorial",
  GENERAL: "general",
  INTRODUCTION: "introduction",
  INTRODUCTION_RETURN: "introduction return",
};

export const playSilentAudio = () => {
  new Audio(
    "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV"
  ).autoplay = true;
};

export const is_iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const wait = async (duration) => {
  return await new Promise((res) => {
    setTimeout(res, duration);
  });
};

export const makePrettyDate = (t) => {
  return dayjs(t).fromNow();
};
