import React from "react";
import "./QuickFader.css";
import ReactDOM from "react-dom";
import TweenLite from "gsap";
import EventManager from "../utils/EventManager";
import AppController from "../utils/AppController";

class QuickFader extends React.Component {
  componentDidMount() {
    this.el = ReactDOM.findDOMNode(this);
    // subscribe('timeIncrement',this.onTimeIncrementFired);
    EventManager.listen("#fade", this.onFade);
  }
  onFade = () => {
    TweenLite.to(this.el, 0, {
      opacity: 1,
      onComplete: () => {
        TweenLite.to(this.el, 1, {
          delay: 0.5,
          opacity: 0,
          onComplete: () => {
            // Check glucose alerts
            AppController.checkGlucoseAlerts();
          },
        });
      },
    });
  };
  render() {
    return <div id="fader-comp"></div>;
  }
}
export default QuickFader;
