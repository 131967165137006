import React from "react";
import _ from "lodash";
import "./Pump.css";
import PumpDate12HourSetup from "./PumpDate12HourSetup";
import PumpBolus from "./PumpBolus";
import AppController from "./../../utils/AppController";
import LineChart from "./../A1LineChart";
import EventManager from "./../../utils/EventManager";
import PumpSetCarbRatio from "./PumpSetCarbRatio";
import PumpSetSensitivity from "./PumpSetSensitivity";
import PumpSetBGTarget from "./PumpSetBGTarget";
import PumpSetActiveInsTime from "./PumpSetActiveInsTime";
import PumpBasalSetup from "./PumpBasalSetup";
import PumpTempBasal from "./PumpTempBasal";
class PumpLCD extends React.Component {
  state = {
    selectedIndex: 0,
    stack: AppController.app.state.pumpViewPath, //opens view with pre defined path
    date: new Date(),
  };
  componentDidMount = () => {
    EventManager.listen("#button.pump.act", this.onAct);
    EventManager.listen("#button.pump.esc", this.onEcs);
    EventManager.listen("#button.pump.up", this.onUp);
    EventManager.listen("#button.pump.down", this.onDown);
  };
  componentWillUnmount = () => {
    EventManager.removeListener("#button.pump.act", this.onAct);
    EventManager.removeListener("#button.pump.esc", this.onEcs);
    EventManager.removeListener("#button.pump.up", this.onUp);
    EventManager.removeListener("#button.pump.down", this.onDown);
  };
  returnToResting = () => {
    this.setState(() => {
      return {
        stack: [],
        selectedIndex: 0,
      };
    });
    _.defer(() => {
      AppController.pumpState = AppController.pumpState_nav;
    });
  };
  onAct = () => {
    if (AppController.pumpState !== AppController.pumpState_nav) {
      // check if in tutorial mode, if so, do act action
      if (AppController.scene.isTutorial) {
        AppController.scene.onAct();
      }
      return;
    }
    let stack = this.state.stack.slice();
    if (!stack.length) {
      stack.push("Main Menu");
    }
    // get selected menu item
    // if selectedMenuItem is not null, the view is currently a menu.
    // There's 3 view types in the pump. Sensor, menu and utility
    let selectedMenuItem = document.querySelector(".pump-menu-item-selected");
    // Restrict pump buttons not allowed in scene state meta data
    if (AppController.scene.isTutorial) {
      if (AppController.app.state.pumpButtonsAllowed !== "all") {
        let allowed = AppController.app.state.pumpButtonsAllowed;
        if (
          allowed.toLowerCase() !== selectedMenuItem.innerHTML.toLowerCase()
        ) {
          console.warn("button not allowed");
          return;
        }
      }
    }
    if (selectedMenuItem) {
      stack.push(selectedMenuItem.innerHTML);
    }

    // update stack if needed
    if (this.state.stack.length !== stack.length) {
      this.setState((pState) => {
        return {
          stack: stack,
          selectedIndex: 0,
        };
      });
    }
    AppController.app.setState({ pumpViewPath: this.state.stack });

    if (AppController.scene.isTutorial) {
      AppController.scene.onAct();
    }
  };
  onEcs = () => {
    if (AppController.scene && AppController.scene.isTutorial) return;
    if (AppController.pumpState !== AppController.pumpState_nav) return;
    let stack = this.state.stack.slice();
    if (stack.length) {
      stack.pop();
      this.setState((pState) => {
        return {
          stack: stack,
          selectedIndex: 0,
        };
      });
    }
  };
  onDown = () => {
    if (AppController.pumpState !== AppController.pumpState_nav) return;
    let childCount = document.getElementById("pump-menu-items").children.length;
    if (childCount === 0) return;
    this.setState((prevState) => {
      let selectedIndex = prevState.selectedIndex;
      if (selectedIndex === childCount - 1) selectedIndex = 0;
      else selectedIndex++;
      return {
        selectedIndex: selectedIndex,
      };
    });
  };
  onUp = () => {
    if (AppController.pumpState !== AppController.pumpState_nav) return;
    let childCount = document.getElementById("pump-menu-items").children.length;
    if (childCount === 0) return;
    this.setState((prevState) => {
      let selectedIndex = prevState.selectedIndex;
      if (selectedIndex === 0) selectedIndex = childCount - 1;
      else selectedIndex--;
      return {
        selectedIndex: selectedIndex,
      };
    });
  };
  renderSensor = () => {
    //let level = 'Nominal';
    //let color = '#4a4a4a';
    //if(AppController.app.scene) level = AppController.app.scene.getStateFromGlucoseLevel();
    //if(['Very Low','Very High','Super High'].indexOf(level)>-1)color = 'red';
    //if(['Low','High','High'].indexOf(level)>-1)color = 'red';
    return (
      <div id="pump-sensor">
        <LineChart data={AppController.app.state.sensorData} />
        <div className="pump-resting-bg">
          {parseInt(Math.round(AppController.app.state.glucose), 10)}bg
        </div>
      </div>
    );
  };
  renderView = () => {
    const map = {
      "Main Menu": {
        _title: "MAIN MENU",
        Bolus: {
          _title: "BOLUS MENU",
          //children:["Set Bolus","Bolus History","Bolus Setup"],
          "Set Bolus": {
            _title: "SET BOLUS",
            _view: <PumpBolus onDone={this.returnToResting} />,
          },
        },
        "Edit Settings": {
          _title: "EDIT SETTINGS",
          "Carb Ratios": {
            _title: "SET CARB RATIO",
            _view: <PumpSetCarbRatio onDone={this.returnToResting} />,
          },
          Sensitivity: {
            _title: "INS SENSITIVITY",
            _view: <PumpSetSensitivity onDone={this.returnToResting} />,
          },
          "BG Target": {
            _title: "TARGET RANGE",
            _view: <PumpSetBGTarget onDone={this.returnToResting} />,
          },
          "Active Ins": {
            _title: "ACTIVE INS TIME",
            _view: <PumpSetActiveInsTime onDone={this.returnToResting} />,
          },
        },
        Utilities: {
          _title: "UTILITIES MENU",
          "Time/Date": {
            _title: "TIME/DATE SETUP",
            "12 Hour Setup": {
              // if state === _Date12HourSetup - forward all buttons to this controller
              _view: <PumpDate12HourSetup onDone={this.returnToResting} />,
            },
          },
        },
        Basal: {
          _title: "BASAL MENU",
          "Set/Edit Temp Basal": {
            _title: "",
            _view: <PumpTempBasal onDone={this.returnToResting} />,
          },
          "Basal Setup": {
            _title: "BASAL SETUP",
            "Set/Edit Basal": {
              _view: <PumpBasalSetup onDone={this.returnToResting} />,
            },
          },
        },
      },
    };
    // Get view from path
    let _stack = this.state.stack.slice();
    let view = map;
    while (_stack.length) view = view[_stack.shift()];

    // curate menu list items
    let menuItems = [];
    for (let key in view) {
      if (key[0] === "_") continue;
      menuItems.push(key);
    }

    // get view title if exists
    let title = view._title;

    return (
      <div className="pump-menu">
        {title && <div>{title}</div>}
        <div id="pump-menu-items">
          {menuItems.map((child, i) => {
            if (this.state.selectedIndex === i) {
              return (
                <div className={"pump-menu-item-selected"} key={child}>
                  {child}
                </div>
              );
            }
            return (
              <div className="pump-menu-item" key={child}>
                {child}
              </div>
            );
          })}
        </div>
        {view._view}
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.stack.length === 0
          ? this.renderSensor()
          : this.renderView()}
      </React.Fragment>
    );
  }
}
export default PumpLCD;
