//import _ from 'lodash';
import EventManager from "./EventManager";
import AppController from "./AppController";
import PumpController from "./PumpController";
import { toast } from "react-toastify";

const GLUCOSE_LEVEL_SUPER_HIGH = 400;
const GLUCOSE_LEVEL_VERY_HIGH = 251;
const GLUCOSE_LEVEL_HIGH = 181;
const GLUCOSE_LEVEL_LOW = 79;
const GLUCOSE_LEVEL_VERY_LOW = 59;
const GLUCOSE_LEVEL_SUPER_LOW = 30;

class SceneController {
  repromptTimer = null;
  preRepromptState = null;
  pump = null;
  forceTimeInc = false;
  isTutorial = false;
  chunk = null;
  sceneState = "Initial";
  title = null;
  data = null;
  date = null;
  checkedZeroScore = false;
  dialogOwner = false; // tracks if the scene controller is the owner of the dialog
  chunkStack = [];
  isEndState = false; // used to track if the scenario is ended.
  constructor(sceneData) {
    if (!sceneData) return;
    //constructor(carbRatio,sensitive,lowGC,highGC,active){
    this.data = sceneData.info || sceneData;
    this.isTutorial = this.data["Scene Type"].toLowerCase() === "tutorial";
    this.title = this.data["Scene Title"];
    let startingGlucose = parseInt(this.data["Scene Starting BG"], 10);
    this.glucose = startingGlucose;
    let startTime = this.data["Scene Starting Time"];

    if (startTime.indexOf("T") > -1) {
      startTime = startTime.split("T")[1];
    }
    let date = new Date();

    console.log("TIME: ", startTime);
    console.log("title: ", this.title);
    console.log("isTutorial: ", this.isTutorial);
    console.log(this.data);

    let hrs = startTime.split(":")[0];
    let min = startTime.split(":")[1];

    date.setHours(hrs);
    date.setMinutes(min);
    // console.log("TIME: ", hrs, ":", min)

    AppController.breadcrumbs = [];
    EventManager.listen("#action.bolus", this.onBolus);
    EventManager.listen("#action.change-site", this.onChangeSite);
    EventManager.listen("#action.eat", this.onEat);
    EventManager.listen("#action.sleep", this.onSleep);
    EventManager.listen("#action.exercise", this.onExercise);
    EventManager.listen("#action.pump", this.onPump);
    EventManager.listen("#action.wait", this.onWait);
    EventManager.listen("#action.ketones", this.onKetones);
    EventManager.listen("#action.check-site", this.onCheckSite);
    EventManager.listen("#action.meter", this.onMeter);
    EventManager.listen("#action.cgm", this.onCgm);
    EventManager.listen("#speak.finished", this.onSpeakFinished);
    AppController.app.setState(
      {
        pumpViewPath: [],
        date: date,
        score: 3,
        glucose: startingGlucose,
        scenesButtonVisible: false,
        titleBannerVisible: true,
      },
      () => {
        this.pump = new PumpController();
        if (this.data["Scene Type"].toLowerCase() === "occlusion")
          this.pump.isOcclusion = true;
        this.triggerAction("OnInit");
      }
    );
  }
  unload = () => {
    EventManager.stopListening("#action.bolus", this.onBolus);
    EventManager.stopListening("#action.change-site", this.onChangeSite);
    EventManager.stopListening("#action.eat", this.onEat);
    EventManager.stopListening("#action.sleep", this.onSleep);
    EventManager.stopListening("#action.exercise", this.onExercise);
    EventManager.stopListening("#action.pump", this.onPump);
    EventManager.stopListening("#action.wait", this.onWait);
    EventManager.stopListening("#action.ketones", this.onKetones);
    EventManager.stopListening("#action.check-site", this.onCheckSite);
    EventManager.stopListening("#action.meter", this.onMeter);
    EventManager.stopListening("#action.cgm", this.onCgm);
    EventManager.stopListening("#speak.finished", this.onSpeakFinished);
    window.clearTimeout(this.repromptTimer);
    this.chunk = null;
    delete this.chunk;
    this.pump = null;
    delete this.pump;
    this.repromptTimer = null;
    delete this.repromptTimer;
    this.preRepromptState = null;
    delete this.preRepromptState;
    this.pump = null;
    this.chunkStack = [];
    this.isEndState = false;
    this.forceTimeInc = false;
    this.sceneState = "Initial";
    this.isTutorial = false;
    this.checkedZeroScore = false;
    this.dialogOwner = false; // tracks if the scene controller is the owner of the dialog
  };
  onAct = () => {
    this.triggerAction("OnAct");
  };
  onReprompt = () => {
    //if(!AppController.isSpeaking)
    //this.triggerAction("OnReprompt");
  };
  preAction = () => {
    // close pump if it's open
    if (AppController.app.state.pumpOpen) {
      AppController.app.setState({ pumpOpen: false });
      //AppController.issueCommand("#hide.Pump");
      //AppController.issueCommand("#cam.wide");
    }
    if (this.repromptTimer) {
      window.clearTimeout(this.repromptTimer);
      this.repromptTimer = null;
      EventManager.fire("#speak.stop");
    }
    //EventManager.fire("#timeinc");
    EventManager.fire("#screen.none");
    EventManager.fire("#buttons.none");
  };
  onCheckSite = () => {
    this.preAction();
    this.triggerAction("OnCheckSite");
  };
  onMeter = () => {
    AppController.app.setState({
      meterOpen: !AppController.app.state.meterOpen,
    });
  };
  onCgm = () => {
    AppController.app.setState({
      cgmOpen: !AppController.app.state.cgmOpen,
    });
  };
  onChangeSite = () => {
    this.preAction();
    this.triggerAction("OnChangeSite");
  };
  onHelp = () => {
    if (AppController.isSpeaking) return;
    //this.preAction();
    let actions = this.getActions();
    if (actions.indexOf("OnHelp") > -1) {
      this.triggerAction("OnHelp");
      return true;
    }
    return false;
  };
  onEat = (e) => {
    let carbs = e.detail.data;
    EventManager.fire("#buttons.none");
    if (carbs) {
      // close nutrition view
      AppController.app.setState({
        nutritionFacts: Object.assign(
          {},
          AppController.app.state.nutritionFacts,
          {
            visible: false,
          }
        ),
      });
      this.preAction();
      this.triggerAction("OnEat");
      AppController.app.setState({ foodBarOpen: false });
      this.pump.eatFood(carbs);
      // default eat time set to 60 min
      this.pump.passTime(60);
    } else {
      AppController.app.setState({ foodBarOpen: true });
    }
  };
  onSleep = (e) => {
    let sleepTime = e.detail.data;
    EventManager.fire("#buttons.none");
    if (sleepTime) {
      this.preAction();
      this.triggerAction("OnSleep");
      AppController.app.setState({ sleepBarOpen: false });
      this.forceTimeInc = sleepTime;
      this.pump.passTime(sleepTime);
    } else {
      AppController.app.setState({ sleepBarOpen: true });
    }
  };
  onExercise = (e) => {
    let exerciseTime = e.detail.data;
    EventManager.fire("#buttons.none");
    if (exerciseTime) {
      this.preAction();
      this.triggerAction("OnExercise");
      AppController.app.setState({ exerciseBarOpen: false });
      // make sure and calc pump AFTER triggering action so the state remains in tact
      this.forceTimeInc = exerciseTime;
      this.pump.exercise();
      this.pump.passTime(exerciseTime);
    } else {
      AppController.app.setState({ exerciseBarOpen: true });
    }
  };
  onWait = (e) => {
    let waitTime = e.detail.data;
    EventManager.fire("#buttons.none");
    if (waitTime) {
      this.preAction();
      this.triggerAction("OnWait");
      AppController.app.setState({ waitBarOpen: false });
      this.forceTimeInc = waitTime;
      this.pump.passTime(waitTime);
    } else {
      AppController.app.setState({ waitBarOpen: true });
    }
  };
  onKetones = () => {
    this.preAction();
    this.triggerAction("OnKetones");
  };
  onPump = () => {
    // open or close pump
    let open = AppController.app.state.pumpOpen;
    if (!open && this.isTutorial) {
      this.triggerAction("OnBolus");
      EventManager.fire("#buttons.none");
    }
    AppController.app.setState({ pumpOpen: !open });
  };
  onBolus = () => {
    this.preAction();
    this.triggerAction("OnBolus");

    console.log(this.pump);

    //this.triggerAction('OnWait');
    // this.pump.setBolusUnits(150);
    this.pump.manualBolas();
    // check if pump is open, if so close it
    if (AppController.app.state.pumpOpen) {
      AppController.app.setState({ pumpOpen: false });
    }
  };
  getFailedState = () => {
    return this.data.states.filter((item) => {
      return item["State MetaData"].search(/failed/gi) > -1;
    })[0];
  };
  triggerAction = (action) => {
    const crumb = {
      time: new Date().toISOString(),
      bgLevel: this.getStateFromGlucoseLevel(),
      glucose: Math.round(AppController.app.state.glucose),
      state: this.sceneState,
      action: action,
    };
    AppController.breadcrumbs.push(crumb);
    console.log("added breadcrumb", crumb);

    let state = this.getSceneState();
    if (state && action === "OnInit") {
      // check meta data
      let _meta = state["State MetaData"];
      if (_meta) {
        try {
          let meta = JSON.parse(_meta);
          console.log("meta: ", meta);
          if (meta["pumpMenuPath"]) {
            let path = meta["pumpMenuPath"];
            path = path.split(".");
            AppController.app.state.pumpViewPath = path;
          }
          if (meta["allowedButton"]) {
            AppController.app.state.pumpButtonsAllowed = meta["allowedButton"];
          } else {
            AppController.app.state.pumpButtonsAllowed = "all";
          }
        } catch (e) {
          console.log(e);
          //console.log("triggerAction() no meta data");
        }
      } else {
        AppController.app.state.pumpButtonsAllowed = "all";
      }
      this.updateScore();
    }
    EventManager.fire("#speak.stop");
    // get scene chunk
    //console.log("##### Getting Scene Chunk");
    this.chunk = this.getSceneChunkForAction(action);
    //console.log("##### Scene State:", this.sceneState);
    //if(this.sceneState==='END'){
    //// TODO - figure out another end sequence
    //// close pump if open
    //if(AppController.app.state.pumpOpen){
    //AppController.app.setState({pumpOpen:false});
    //}
    //EventManager.fire("#scenario.finished");
    //return;
    //}
    if (!this.chunk) {
      let gclevel = this.getStateFromGlucoseLevel();
      let errMsg =
        "Can't find " +
        action +
        " for " +
        gclevel +
        " in " +
        this.sceneState +
        "\n(Please this exists and then check spelling, space and other characters are not in or after the tag or state names)";

      console.error("SCENERIOS missing action:", AppController.scenarios);

      EventManager.fire("#alert", errMsg);
      // alert(errMsg);
      return;
    }
    this.chunkStack.push(this.chunk);
    // console.log(JSON.stringify(this.chunk, null, 2));
    // check if pump lcd view needs to be opened to a particular view
    if (this.isTutorial) {
    } else {
      AppController.app.state.pumpViewPath = [];
    }
    // start listening for #speak finished ONLY here, and stop listening when fired
    //EventManager.listen("#speak.finished",_.bind(this.onSpeakFinished,this));

    // using app controller to parse dialog
    EventManager.fire("#speak", this.chunk.Dialog);
    this.dialogOwner = true;
  };
  onSpeakFinished = () => {
    if (!this.dialogOwner) return;
    this.dialogOwner = false;
    if (this.isEndState) {
      this.doOutro();
      return;
    }
    const nextState = this.chunk["NextState"];
    if (
      nextState !== "" &&
      nextState !== this.sceneState &&
      nextState !== "next state"
    ) {
      this.sceneState = this.chunk["NextState"];
      // check score
      if (!this.checkedZeroScore && AppController.app.state.score <= 0) {
        this.checkedZeroScore = true;
        this.sceneState = this.getFailedState()["State Name"];
      }
      // check for pending timeinc
      let timeinc = parseInt(this.chunk["TimeIncr"], 10);
      if (timeinc > 0) {
        let add = this.chunk["TimeIncr"];
        if (this.forceTimeInc) {
          add = this.forceTimeInc;
          this.forceTimeInc = false;
        }
        // add add to AppController.app.state.date
        if (typeof add === "number") {
          let date = new Date(
            AppController.app.state.date.getTime() + add * 60000
          );
          AppController.app.setState({ date: date });
        } else {
          console.warn(
            this.chunk["TimeIncr"],
            "this.chunk['TimeIncr'] is invalid"
          );
        }
        EventManager.fire("#timeinc", timeinc);
        setTimeout(() => {
          this.triggerAction("OnInit");
        }, 2000);
      } else {
        this.triggerAction("OnInit");
      }
    } else {
      let lastChunk = this.chunkStack[this.chunkStack.length - 1];
      if (lastChunk["User Events"] === "OnInit") {
        this.displayActionButtons();
      } else {
        // effectivly recalcs glucose
        this.triggerAction("OnInit");
      }

      //if(this.chunk['User Events'] !== "OnReprompt"){
      ////let actions = this.getActions();
      //if(actions.indexOf("OnReprompt")===-1){
      //console.warn("no reprompt for this state");
      //}else{
      //this.repromptTimer = window.setTimeout(this.onReprompt,10000);
      //}
      //}
    }
  };
  doOutro = () => {
    EventManager.fire("#scenario.finished");
  };
  displayActionButtons = () => {
    let actions = this.getActions();
    let buttons = [];
    actions.forEach((action) => {
      if (action === "OnExercise") buttons.push("exercise");
      else if (action === "OnBolus") buttons.push("pump");
      else if (action === "OnEat") buttons.push("eat");
      else if (action === "OnSleep") buttons.push("sleep");
      else if (action === "OnWait") buttons.push("wait");
      else if (action === "OnKetones") buttons.push("ketones");
      else if (action === "OnCheckSite") buttons.push("check-site");
      else if (action === "OnChangeSite") buttons.push("change-site");
    });
    if (buttons.length) {
      EventManager.fire("#buttons." + buttons.join("."));
    }
  };
  sterilizeDialog = (d) => {
    let clean = d.replace(/<\/?[^>]+(>|$)/g, "");
    return clean;
  };
  getStateFromGlucoseLevel = () => {
    if (AppController.app.state.glucose >= GLUCOSE_LEVEL_SUPER_HIGH)
      return "Super High";
    if (AppController.app.state.glucose >= GLUCOSE_LEVEL_VERY_HIGH)
      return "Very High";
    if (AppController.app.state.glucose >= GLUCOSE_LEVEL_HIGH) return "High";
    if (AppController.app.state.glucose <= GLUCOSE_LEVEL_VERY_LOW)
      return "Very Low";
    if (AppController.app.state.glucose <= GLUCOSE_LEVEL_LOW) return "Low";
    return "Nominal";
  };
  updateScore = () => {
    this.data.states.forEach((item) => {
      if (item["State Name"] === this.sceneState) {
        AppController.app.state.score +=
          parseInt(item["State Points"], 10) || 0;
        //AppController.app.state.resultsView.points += parseInt(item["State Points"],10) || 0;
      }
    });
    if (AppController.app.state.score > 3) AppController.app.state.score = 3;
    if (AppController.app.state.score < 0) {
      AppController.app.state.score = 0;
    }
    AppController.app.setState({ score: AppController.app.state.score });
  };
  // return actions list based on current sceneState and glucose level
  getActions = () => {
    let gcLevels = null;
    this.data.states.forEach((item) => {
      if (item["State Name"] === this.sceneState) {
        gcLevels = item.gcLevels;
      }
    });

    // get current scene actions for gcLevels
    let key = this.getStateFromGlucoseLevel();
    let userEvents = gcLevels[key];
    let actions = userEvents.map((item, index) => {
      return item["User Events"];
    });
    return actions;
  };
  getSceneState = () => {
    let state = null;
    this.data.states.forEach((item) => {
      if (item["State Name"] === this.sceneState) {
        state = item;
      }
    });
    return state;
  };
  getSceneChunkForAction = (action) => {
    const chunks = this.getSceneChunks();
    if (!chunks) {
      debugger;
      console.log(`Missing information from action: (${action})`);
      return null;
    }

    const chunk = chunks.filter((item) => {
      return item["User Events"] === action;
    });

    // check if next state of chunk is END
    if (!chunk[0]) {
      console.log("chunk", chunk);
      toast.error(`Missing user action (${action})`);
      AppController.exitSceneEarly(
        "We need to work out a few things. Thanks for your patients."
      );
    } else {
      this.isEndState = chunk[0]["NextState"] === "END";
      return chunk[0];
    }
  };
  getSceneChunks = () => {
    let gcLevels = null;
    //console.log("Check against=",this.sceneState)
    this.data.states.forEach((item) => {
      //console.log("       SN=",item["State Name"])
      if (item["State Name"] === this.sceneState) {
        gcLevels = item.gcLevels;
        //console.log("       gcLevels=",gcLevels)
      }
    });
    if (!gcLevels) {
      //console.log("  NULL gcLevels")
      return null;
    }

    // get current scene actions for gcLevels
    let key = this.getStateFromGlucoseLevel();
    console.log("glucose level: ", key);
    let chunks = gcLevels[key];
    if (!chunks) {
      // debugger;
      console.error(`{${key}} not found in {${this.sceneState}} state`);
    }
    return chunks;
  };
}

export default SceneController;
