import { Button } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import LoadingOverlay from "react-loading-overlay";
import AppController from "../utils/AppController";
import EventManager from "./../utils/EventManager";
import { playSilentAudio } from "../utils/constants";

LoadingOverlay.propTypes = undefined;

const styles = {
  loader: {
    marginTop: "5%",
    fontFamily: "Monserrat,sans-serif",
    fontSize: "4em",
    fontWeight: "900",
  },
  startButton: {
    position: "absolute",
    top: "37%",
    margin: "auto",
    width: 240,
    height: 70,
  },
};
class LoadingView extends React.Component {
  state = {
    active: true,
    startButtonVisible: false,
  };
  componentDidMount = () => {
    console.log("LoadingView:: listening for babylon.loaded");
    EventManager.listen("#babylon.loaded", this.onBabylonReady);
  };
  onBabylonReady = () => {
    // TODO - add start button
    this.setState({ active: false });
    EventManager.stopListening("#babylon.laoded", this.onBabylonReady);
    setTimeout(() => {
      this.setState({ startButtonVisible: true });
    }, 1000);
  };
  onStartClick = () => {
    AppController.onSumerianMessage({ action: "sumerian-ready" });
    let el = ReactDOM.findDOMNode(this);
    el.parentNode.removeChild(el);
  };
  render() {
    return (
      <div
        className="center"
        style={{
          width: "100%",
          height: "100%",
          background: "#001322",
        }}
      >
        <div style={styles.loader}>
          <LoadingOverlay
            active={this.state.active}
            spinner
            text="MiSugartime"
            spinnerSize="200px"
            background="#001322"
            color="#4a4a4a"
          />
        </div>
        {this.state.startButtonVisible && (
          <Button
            className="center"
            variant="contained"
            color="primary"
            style={styles.startButton}
            onClick={() => {
              playSilentAudio();

              this.onStartClick();
            }}
          >
            Click to Start
          </Button>
        )}
      </div>
    );
  }
}
export default LoadingView;
