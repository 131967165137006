import React from "react";
import "./TimeIncrementFader.css";
import ReactDOM from "react-dom";
// import TimelapseTwoTone from '@material-ui/icons/TimelapseTwoTone';
// import {subscribe} from 'redux-subscriber';
import TweenLite from "gsap";
import EventManager from "./../utils/EventManager";
import ClockImage from "./../images/clock.png";
import AppController from "../utils/AppController";

class TimeIncrementFader extends React.Component {
  componentDidMount() {
    this.el = ReactDOM.findDOMNode(this);
    // subscribe('timeIncrement',this.onTimeIncrementFired);
    EventManager.listen("#timeinc", this.onTimeIncrementFired);
  }
  onTimeIncrementFired = () => {
    // TODO clear text from bottom text bar
    TweenLite.to(this.el, 0, {
      opacity: 1,
      onComplete: () => {
        TweenLite.to(this.el, 1, {
          delay: 1,
          opacity: 0,
          onComplete: () => {
            // Check glucose alerts
            EventManager.fire("#timeinc.finished");
            AppController.checkGlucoseAlerts();
          },
        });
      },
    });
  };
  render() {
    return (
      <div id="timeinc-comp">
        <img alt="time laps" id="time-comp-timelapse" src={ClockImage} />
      </div>
    );
  }
}
export default TimeIncrementFader;
