import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makePrettyDate } from "../../utils/constants";
import { faCog, faMailBulk, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import {
  DialogActions,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import dayjs from "dayjs";
import LoadingOverlay from "react-loading-overlay";
import { getUsers } from "../../utils/redux/features/userSlice";
import MailToDialog from "./MailToDialog";
import ReplayView from "./ReplayView";
import UserConfig from "./UserConfig";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const AdminUserList = ({ onClose }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userSlice.users);
  const [selectedSession, setSelectedSession] = useState(0);
  const [isMailDialogOpen, setIsMailDialogOpen] = useState(false);
  const [isUserConfigOpen, setIsUserConfigOpen] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  if (!users.length) {
    return (
      <LoadingOverlay
        active
        spinner
        text="Getting Users"
        spinnerSize="200px"
        background="#001322"
        color="#4a4a4a"
      />
    );
  }

  const renderUsers = () => {
    const columns = [
      {
        field: "email",
        type: "actions",
        cellClassName: "actions",
        headerName: "Email",
        width: 200,
        getActions: ({ row: user }) => {
          return [<small>{user.email}</small>];
        },
      },
      {
        field: "sessions",
        type: "actions",
        cellClassName: "actions",
        headerName: "Sessions",
        width: 200,
        getActions: ({ row: user }) => {
          let sessions = user.sessions;
          if (!sessions.length) {
            return [<>No Sessions</>];
          }

          try {
            sessions = [...sessions].sort((a, b) => {
              const aa = new Date(a.updatedAt);
              const bb = new Date(b.updatedAt);
              return bb - aa;
            });
          } catch (err) {
            console.log("err", err);
          }

          return [
            // <Button onClick={() => setSelectedSession(session)}>Review</Button>,
            // <Button onClick={() => {}}>Review</Button>,
            <FormControl>
              {/* <InputLabel id="select-label">Review a Session</InputLabel> */}
              <Select
                labelId="select-label"
                value={selectedSession}
                label="Sessions"
                onChange={(e) => {
                  const session = { ...e.target.value };
                  session.user = { ...user };
                  setSelectedSession(session);
                }}
                variant="standard"
              >
                <MenuItem key={"user session"} value={0}>
                  Select One
                </MenuItem>
                {sessions.map((session, key) => {
                  const {
                    appState,
                    breadcrumbs,
                    createdAt,
                    id,
                    scene,
                    updatedAt,
                  } = session;
                  return (
                    <MenuItem key={key} value={session}>
                      {scene["Scene Name"]} from {dayjs(createdAt).fromNow()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>,
          ];
        },
      },
      {
        field: "last activity",
        type: "actions",
        cellClassName: "actions",
        headerName: "Last Activity",
        width: 200,
        getActions: ({ row: user }) => {
          return [<small>{makePrettyDate(user.lastActivity)}</small>];
        },
      },

      {
        field: "created at",
        type: "actions",
        cellClassName: "actions",
        headerName: "Created",
        width: 200,
        getActions: ({ row: user }) => {
          return [<small>{makePrettyDate(user.createdAt)}</small>];
        },
      },

      {
        field: "email user",
        type: "actions",
        cellClassName: "actions",
        headerName: "Email User",
        width: 200,
        getActions: ({ row: user }) => {
          return [
            <Button
              title="Email User"
              onClick={() => {
                setIsMailDialogOpen(user);
              }}
            >
              <FontAwesomeIcon icon={faMailBulk} />
            </Button>,
          ];
        },
      },
      {
        field: "user config",
        type: "actions",
        cellClassName: "actions",
        headerName: "Config",
        width: 200,
        getActions: ({ row: user }) => {
          return [
            <Button
              title="User Config"
              onClick={() => {
                setIsUserConfigOpen(user);
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>,
          ];
        },
      },
    ];

    return (
      <DataGrid
        slots={{
          toolbar: CustomToolbar,
        }}
        autoHeight
        columns={columns}
        rows={users}
      />
    );
  };

  return (
    <div>
      {selectedSession && (
        <ReplayView
          {...selectedSession}
          onClose={() => setSelectedSession(0)}
        />
      )}
      {isMailDialogOpen && (
        <MailToDialog
          {...isMailDialogOpen}
          onClose={() => setIsMailDialogOpen(false)}
        />
      )}
      {isUserConfigOpen && (
        <UserConfig
          {...isUserConfigOpen}
          onClose={() => setIsUserConfigOpen(false)}
        />
      )}
      <Dialog fullScreen open onClose={onClose} disableEnforceFocus>
        <DialogTitle>A1 Users List</DialogTitle>
        <DialogContent>{renderUsers()}</DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CardDisplay = ({ user, selectedSession, setSelectedSession }) => {
  let {
    createdAt,
    email,
    profile,
    email_verified,
    id,
    lastActivity,
    sessions,
    updatedAt,
  } = user;

  // console.log(sessions);
  // sessions.sort((a, b) => {
  //   console.log(a.updatedAt, b.updatedAt);
  //   return new Date(a.updatedAt) > new Date(b.updatedAt);
  // });

  // if (profile) {
  //   profile = JSON.parse(profile);
  // }
  const [isMailDialogOpen, setIsMailDialogOpen] = useState(false);
  const [isUserConfigOpen, setIsUserConfigOpen] = useState(null);

  const roles =
    profile && profile.roles && profile.roles.length ? profile.roles : null;
  return (
    <Card className="m-1">
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor:
                roles && roles.find((n) => n === "admin")
                  ? deepPurple[500]
                  : deepOrange[500],
            }}
          >
            {email.substring(0, 2)}
          </Avatar>
        }
        title={email}
      />
      <CardContent>
        {roles && (
          <div className="d-flex align-items-center" style={{ gap: 10 }}>
            <FontAwesomeIcon icon={faShield} size="1x" />
            {/* <Typography variant="body1">roles</Typography> */}
            <div className="d-flex align-items-center">
              {roles.map((role, _key) => {
                return (
                  <div className="d-flex align-items-center" key={_key}>
                    <small>{role}</small>
                    {_key < roles.length - 1 && (
                      <small className="mx-2">|</small>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {sessions.length > 0 && (
          <FormControl>
            <InputLabel id="select-label">Review a Session</InputLabel>
            <Select
              labelId="select-label"
              value={selectedSession}
              label="Sessions"
              onChange={(e) => {
                const session = { ...e.target.value };
                session.user = { ...user };
                setSelectedSession(session);
              }}
              variant="standard"
            >
              <MenuItem key={"user session"} value={0}>
                Select a user session
              </MenuItem>
              {sessions.map((session, key) => {
                const {
                  appState,
                  breadcrumbs,
                  createdAt,
                  id,
                  scene,
                  updatedAt,
                } = session;
                return (
                  <MenuItem key={key} value={session}>
                    {scene["Scene Name"]} from {dayjs(createdAt).fromNow()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <Button
          title="Email User"
          onClick={() => {
            setIsMailDialogOpen(user);
          }}
        >
          <FontAwesomeIcon icon={faMailBulk} />
        </Button>
        <Button
          title="User Config"
          onClick={() => {
            setIsUserConfigOpen(user);
          }}
        >
          <FontAwesomeIcon icon={faCog} />
        </Button>
      </CardActions>
      {isMailDialogOpen && (
        <MailToDialog
          {...isMailDialogOpen}
          onClose={() => setIsMailDialogOpen(false)}
        />
      )}
      {isUserConfigOpen && (
        <UserConfig
          {...isUserConfigOpen}
          onClose={() => setIsUserConfigOpen(false)}
        />
      )}
    </Card>
  );
};

export default AdminUserList;
