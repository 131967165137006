import React from "react";
import ReactDOM from "react-dom";
import EventManager from "./../utils/EventManager";
import AppController from "./../utils/AppController";
import "./ResultsView.css";
import starImgSrc from "./../images/big-star.png";
import starEmptyImgSrc from "./../images/big-empty-star.png";
import scenariosImgSrc from "./../images/scenarios-button.svg";
import replayImgSrc from "./../images/replay-button.svg";
import nextImgSrc from "./../images/next-button.svg";
import TweenMax, { Expo } from "gsap";

class ResultsView extends React.Component {
  componentDidMount = () => {
    this.el = ReactDOM.findDOMNode(this);
    TweenMax.to(this.el, 0, {
      right: -this.el.offsetWidth,
      ease: Expo.easeOut,
    });
    TweenMax.to(this.el, 1, { right: 90, ease: Expo.easeOut });

    //EventManager.listen("#screen.results",this.onResults);
  };
  componentWillUnmount = () => {};
  close = () => {
    TweenMax.to(this.el, 1, {
      right: -this.el.offsetWidth,
      ease: Expo.easeOut,
      onComplete: () => {
        AppController.app.state.resultsView.visible = false;
        AppController.app.forceUpdate();
      },
    });
  };
  handleClose = () => {
    AppController.app.setState({
      scenesButtonVisible: true,
    });
    this.close();
  };
  onScenarios = () => {
    AppController.app.setState({ scenesButtonVisible: true });
    EventManager.fire("#screen.scenes");
    this.close();
  };
  onReplay = () => {
    // const sceneData = AppController.lastScene;
    // EventManager.fire("#load-scene", sceneData);
    AppController.replayScene();
    this.close();
  };
  onNext = () => {
    this.close();
    this.props.data.onNext();
  };
  renderStars = () => {
    let classes = { ...this.props.data };
    let items = [];
    for (let i = 0; i < 3; i++) {
      let pass = classes.points > i;
      let failedStyle = {};
      if (!pass) {
        failedStyle = {
          marginBottom: 16,
          marginLeft: 28,
        };
      }
      items.push(
        <img
          key={i}
          style={failedStyle}
          src={pass ? starImgSrc : starEmptyImgSrc}
          alt="*"
        />
      );
    }
    return items;
  };
  render() {
    let classes = { ...this.props.data };
    return (
      <div id="results-view">
        <div id="results-view-title">{classes.title}</div>
        <section className="results-view-stars">{this.renderStars()}</section>
        <section className="results-button-group">
          <img
            className="results-button"
            onClick={this.onScenarios}
            src={scenariosImgSrc}
            alt="*"
          />
          <img
            className="results-button"
            onClick={this.onReplay}
            src={replayImgSrc}
            alt="*"
          />
          {classes.onNext && (
            <img
              className="results-button"
              onClick={this.onNext}
              src={nextImgSrc}
              alt="*"
            />
          )}
        </section>
      </div>
    );
  }
}
export default ResultsView;
