import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./utils/redux/configureStore";
// import reportWebVitals from "./reportWebVitals";

// if (module.hot) {
//   module.hot.accept();
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

if (!window._root) {
  window._root = ReactDOM.createRoot(document.getElementById("root"));
}
window._root.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>
);
