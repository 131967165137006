import React from 'react';
import './Pump.css';
import AppController from './../../utils/AppController';
import EventManager from './../../utils/EventManager';

class PumpSetBGTarget extends React.Component{
    state={
        state:0,
        low:AppController.app.state.targetBg.low,
        high:AppController.app.state.targetBg.high,
    };
    componentDidMount=()=>{
        EventManager.listen("#button.pump.act",this.onAct);
        EventManager.listen("#button.pump.esc",this.onEcs);
        EventManager.listen("#button.pump.up",this.onUp);
        EventManager.listen("#button.pump.down",this.onDown);
        AppController.pumpState = AppController.pumpState_12HourSetup; 
    }
    componentWillUnmount=()=>{
        EventManager.stopListening("#button.pump.act",this.onAct);
        EventManager.stopListening("#button.pump.esc",this.onEcs);
        EventManager.stopListening("#button.pump.up",this.onUp);
        EventManager.stopListening("#button.pump.down",this.onDown);
    }
    onAct=()=>{
        if(this.state.state===1){
            AppController.app.state.targetBg.low = this.state.low;
            AppController.app.state.targetBg.high = this.state.high;
            AppController.app.forceUpdate();
            this.props.onDone(); 
            return;
        }
        this.setState((pState)=>{
            return {
                state:pState.state+1
            }
        });
    }
    onEsc=()=>{
        this.props.onDone();
    }
    onUp=()=>{
        if(this.state.state===0){
            this.setState((pState)=>{
                return {low:pState.low+1}
            });
        }else if(this.state.state===1){
            this.setState((pState)=>{
                return {high:pState.high+1}
            });
        }
    }
    onDown=()=>{
        if(this.state.state===0){
            this.setState((pState)=>{
                return {low:pState.low-1}
            });
        }else if(this.state.state===1){
            this.setState((pState)=>{
                return {high:pState.high-1}
            });
        }
    }
    renderView=()=>{
        if(this.state.state===0){
            return (<React.Fragment>
                <div><sub>mg/dL</sub></div>
                <span className="blink pump-big-font">{this.state.low}</span>-<span className="pump-big-font">{this.state.high}</span>
            </React.Fragment>)
        }
        else if(this.state.state===1){
            return (<React.Fragment>
                <div><sub>mg/dL</sub></div>
                <span className="pump-big-font">{this.state.low}</span>-<span className="blink pump-big-font">{this.state.high}</span>
            </React.Fragment>)
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.renderView()}
            </React.Fragment>
        );
    }
}
export default PumpSetBGTarget;
