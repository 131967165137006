import React from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import closeButtonSrc from "../images/close-button.svg";
import { uiRemoveImage } from "../utils/redux/features/ui";

const _Image = ({ url }) => {
  const dispatch = useDispatch();
  return (
    <Draggable
      defaultPosition={{
        x: Math.random() * 200 + 200,
        y: Math.random() * 200 + 200,
      }}
    >
      <div style={{ width: 500, pointerEvents: "all" }}>
        <img
          alt="close"
          onClick={() => {
            dispatch(uiRemoveImage(url));
          }}
          style={{
            position: "absolute",
            top: 10,
            left: 456,
            cursor: "pointer",
          }}
          src={closeButtonSrc}
        />
        <img
          onClick={(e) => e.preventDefault()}
          style={{
            width: "inherit",
            borderRadius: 10,
            border: "3px solid #e8e7e7",
          }}
          className="not-selectable"
          src={url}
        />
      </div>
    </Draggable>
  );
};

export default ({ images }) => {
  return (
    <div
      style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none" }}
    >
      {images.map((url, key) => {
        console.log(url);
        return <_Image key={key} url={url} />;
      })}
    </div>
  );
};
