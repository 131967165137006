import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { API_NAME } from "../../constants";
import terms from "../../../views/terms.json";
import { toast } from "react-toastify";

const initState = {
  glossary: terms,
};

export const glossaryGet = createAsyncThunk(
  "glossary/get",
  async (_, thunkApi) => {
    let res = null;
    try {
      res = await API.get(API_NAME, "/terms");
    } catch (err) {
      console.error(err);
    }

    const glossary = Object.entries(res.body).map((n) => n[1]);
    return glossary;
  }
);

export const glossaryPut = createAsyncThunk(
  "glossary/put",
  async ({ originalTitle, title, Definition }, thunkApi) => {
    let res = null;
    const glossary = [...thunkApi.getState().glossary.glossary];
    try {
      // Check if use changed title
      if (originalTitle && originalTitle !== title) {
        glossary.forEach(({ title: _title, Definition }, i, arr) => {
          if (_title === originalTitle) {
            arr[i].title = title;
            arr[i].Definition = Definition;
          }
        });
      }
      // This is a new entry
      else if (!originalTitle) {
        glossary.push({ title, Definition });
      } else {
        // Title didn't change, just update Definition
        // Check if term title exists
        glossary.forEach(({ title: _title }, i, arr) => {
          if (_title === title) {
            arr[i].Definition = Definition;
          }
        });
      }

      res = await API.put(API_NAME, "/terms", {
        body: {
          id: "Terms",
          info: glossary,
        },
      });
    } catch (err) {
      console.error(err);
    }
    thunkApi.dispatch(glossaryGet());
    return glossary;
  }
);

export const glossaryDelete = createAsyncThunk(
  "glossary/delete",
  async (title, thunkApi) => {
    let res = null;
    const glossary = [...thunkApi.getState().glossary.glossary];
    try {
      const newGlossary = glossary.filter((n) => {
        return n.title !== title;
      });

      res = await API.put(API_NAME, "/terms", {
        body: {
          id: "Terms",
          info: newGlossary,
        },
      });
      toast.success(`${title} removed from terms`);
      thunkApi.dispatch(glossaryGet());
      return newGlossary;
    } catch (err) {
      console.error(err);
    }
    return null;
  }
);

export default function reducer(state = initState, action) {
  switch (action.type) {
    case glossaryGet.fulfilled.type:
      return { ...state, glossary: action.payload };
    case glossaryPut.fulfilled.type:
      return { ...state, glossary: action.payload };
    case glossaryDelete.fulfilled.type:
      return { ...state, glossary: action.payload };
  }
  return state;
}
